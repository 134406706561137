export default class Page {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.imgUrl = rawData.imgUrl;
    this.imgAlt = rawData.imgAlt;
    this.name = rawData.name;
    this.indexing = rawData.indexing;
    this.pageUrl = rawData.pageUrl;
    this.localizations = rawData.localizations;
    this.pageCategory = rawData.pageCategory;
    this.pageCategoryId = rawData.pageCategoryId;
  }
}
