<template>
  <div class="main-container ma-4">
    <h2 class="ma-2">Applicant Data</h2>

    <div>
      <v-container fluid class="ma-2">
        <v-row>
          <v-col cols="12" md="5">
            <v-row>
              <v-col cols="12">
                <div class="subheaders--text  text--darken-2 sub-header">
                  Applicant Personal Information
                </div>
              </v-col>
            </v-row>
            <v-row v-if="careerItem.name">
              <v-col cols="12">
                <div class="sub-title">Full Name</div>
                <span>
                  {{ careerItem.name }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="careerItem.email">
              <v-col cols="12">
                <div class="sub-title">Email</div>
                <span>
                  {{ careerItem.email }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="careerItem.phone">
              <v-col cols="12" md="4">
                <div class="sub-title">Phone Number</div>
                <span>
                  {{ careerItem.phone }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="careerItem.address">
              <v-col cols="12" md="4">
                <div class="sub-title">Address</div>
                <span v-if="careerItem.address">
                  {{ careerItem.address }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="d-none d-md-flex justify-center" md="1">
            <v-divider vertical></v-divider>
          </v-col>
          <v-col cols="12" md="5">
            <v-row>
              <v-col cols="12">
                <div class="subheaders--text  text--darken-2 sub-header">
                  Applicant Qualifications
                </div>
              </v-col>
            </v-row>
            <v-row v-if="careerItem.position">
              <v-col cols="12">
                <div class="sub-title">Position</div>
                <span>
                  {{ careerItem.position }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="careerItem.education">
              <v-col cols="12">
                <div class="sub-title">Education</div>
                <span>
                  {{ careerItem.eduaction }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="careerItem.qualification">
              <v-col cols="12">
                <div class="sub-title">Qualification</div>
                <span>
                  {{ careerItem.qualification }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="careerItem.additionalInformation">
              <v-col cols="12">
                <div class="sub-title">Additional Information</div>
                <span>
                  {{ careerItem.additionalInformation }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="sub-title">CV</div>
                <v-btn class="ma-2" :href="careerItem.careerCv" download>
                  <v-icon small>mdi-download</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("careers", ["careerItem"]),
  },
  components: {},
  data() {
    return {
      toBeDeleteCareer: null,
      snackbarText: ``,
      confirmationDialogDelete: false,
      confirmationMessage: "",
      snackbar: false,
      loading: false,
      pageSize: 10,
      pageNumber: 1,
      pageCategorySelected: null,
      pageSizeOptions: [5, 10, 15, 20],
      careersHeaders: [],
    };
  },
  async mounted() {
    await this.$store.dispatch("careers/getCareerById", this.$route.params.id);
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  padding-top: 2rem;
  padding-left: 1rem;
  border-radius: 1%;
  box-shadow: 0 0 10px 3px rgb(0 0 0 / 0.2);
}

.sub-header {
  font-size: 1.2rem;
  font-weight: normal;
  color: gray;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.sub-title {
  font-size: 1rem;
  font-weight: bold;
}
</style>
