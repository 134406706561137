import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      childrenPagesNumber: [],
      localizedPostFileCaption: [],
      currentlyAddedPostFileCaption: null,
      posts: [],
      pageNumberForPost: null,
      editedPostFileImageTypes: [],
      currentRoute: null,
      parentPost: null,
      localizedPosts: [],
      parentPostContentCategory: null,
      selectedPost: null,
      postFiles: [],
      imageTypes: [],
      currentlyAddedFile: null,
      selectContentType: null,
      localizedPostsPaginated: {
        result: [],
        totalNumberOfElements: 1,
        totalNumberOfPages: 1,
      },
      sizeConfiguration: null,
      sizeConfigurationSelected : null
    };

  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
