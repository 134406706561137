import Subscribe from "../subscribe/views/pages/subscribeList.vue"


export const subscribeRouters = [
  {
    path: "/subscribe",
    name: "Subscribe",
    component: Subscribe,
  },
];
