<template>
  <v-app>
    <v-navigation-drawer
      class="custom-scrollbar"
      app
      v-model="drawer"
      v-bind:width="300"
    >
      <nav-bar v-if="loading"></nav-bar>
    </v-navigation-drawer>
    <v-app-bar app color="primary">
      <v-app-bar-nav-icon
        color="headerText"
        @click="drawer = !drawer"
      />

      <v-toolbar-title class="headerText--text">
        Content Management System</v-toolbar-title
      >

      <v-spacer />
      <!-- :class="isCGCAdmin ? 'white--text' : 'primary--text'" -->
      <v-btn
        link
        @click="logout"
        justify-end
        color="transparent"
        class="headerText--text custom-button"
        outlined
      >
        Logout
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="secondary">
      <!-- Provides the application the proper gutter -->
      <!-- If using vue-router -->
      <router-view v-if="loading"></router-view>
    </v-main>
    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";
// import updateToken from '@/middlewares/update-token';
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  components: { NavBar },
  name: "App",
  async mounted() {
    this.setDataUI();
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    async setDataUI() {
      try {
        if (localStorage.getItem("tenantCode")) {
          await this.loadLanguages();
          await this.loadContentTypes();
        }
      } catch (error) {
        console.log("error");
      } finally {
        this.loading = true;
      }
    },
    async loadLanguages() {
      await this.$store.dispatch("contentManagement/loadLanguages");
    },
    async loadContentTypes() {
      await this.$store.dispatch("contentManagement/loadContentTypes");
    },
  },
  computed: {
    ...mapGetters("contentManagement", ["contentTypes", "contentCategories"]),
  },

  data: () => ({
    drawer: null,
    loading: false,
  }),
  watch: {
    $route() {
      // updateToken();
    },
  },
});
</script>

<style scoped lang="scss">
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}
.navbar {
  width: 300px;
  background-color: red;
}
.custom-button {
  border-radius: 3.125rem;
}

.custom-scrollbar {
  ::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 15px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
