import { render, staticRenderFns } from "./SizeConfiguration.vue?vue&type=template&id=624cc51c&scoped=true"
import script from "./SizeConfiguration.vue?vue&type=script&lang=js"
export * from "./SizeConfiguration.vue?vue&type=script&lang=js"
import style0 from "./SizeConfiguration.vue?vue&type=style&index=0&id=624cc51c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "624cc51c",
  null
  
)

export default component.exports