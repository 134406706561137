<template>
  <v-list-group v-if="group.items && group.items.length" no-action sub-group>
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title> {{ group.text }} </v-list-item-title>
      </v-list-item-content>
    </template>

    <Fragment v-for="(groupItem, i) in group.items" :key="i">
      <NavbarGroup
        class="ml-4 sub-group"
        v-if="groupItem.items && groupItem.items.length"
        :group="groupItem"
        :prepend-icon="groupItem.icon"
      />

      <v-list-item v-if="groupItem.to" link :to="groupItem.to">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-list-item-title v-on="on" class="capitalize">{{
              groupItem.text
            }}</v-list-item-title>
          </template>

          <span>{{ groupItem.text }}</span>
        </v-tooltip>
      </v-list-item>
    </Fragment>
  </v-list-group>
</template>

<script>
import { Fragment } from "vue-fragment";

export default {
  name: "NavbarGroup",
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  components: { Fragment },
  methods: {
    filterAndSearch(list) {
      if (this.search == null) return list;
      let outerResult = list.filter((item) => {
        return item.text.toLowerCase().match(this.search);
      });
      let innerResult = [];
      list.forEach((item) => {
        let result = item.items?.filter((element) =>
          element.text.toLowerCase().match(this.search.toLowerCase())
        );
        if (result && result.length) innerResult.push(...result);
      });
      return [...innerResult, ...outerResult];
    },
  },
};
</script>
<style>
.sub-group .v-icon {
  transform: rotate(0deg) !important;
}
.sub-group.v-list-group--active .v-icon {
  transform: rotate(180deg) !important;
}
</style>
