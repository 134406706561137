<template>
  <v-container fluid>
    <v-row justify="end">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-col>
        <v-btn
          color="primary "
          dark
          class="mb-2 custom-button"
          @click="addTicket"
        >
          <v-icon dark>mdi-plus</v-icon>
          New Ticket
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-2 mr-2">
      <v-spacer></v-spacer>
      <v-dialog
        v-model="viewCommentsDialog"
        width="850px"
        class="view-comments-dialog"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">View Comments</span>
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-expansion-panels popout>
                <v-expansion-panel
                  class="margin"
                  v-for="(comment, i) in comments"
                  :key="i"
                  hide-actions
                >
                  <comments
                    :commentsForTicket="comment"
                    :editedComment="editedComment"
                  />
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card-text>
          <div>
            <div class="text-area-and-input-file">
              <v-textarea
                class="margin"
                filled
                rows="4"
                v-model="editedComment.text"
                label="write comment"
              ></v-textarea>
              <v-file-input
                label="upload image"
                accept="image/*"
                class="input-file"
                v-model="files"
                multiple
              />
            </div>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                color="red darken-1"
                class="custom-button"
                @click="closeViewCommentsDialog"
              >
                Cancel
              </v-btn>

              <v-btn
                @click="addComment(editedComment)"
                color="blue darken-1 white--expansiontext"
                class="custom-button"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog v-model="addTicketDialog" max-width="850px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Add Ticket</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="contentTypeForm">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field label="title" v-model="editedTicket.title" />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-textarea
                      label="description"
                      v-model="editedTicket.description"
                    />
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-file-input
                      v-model="ticketFile"
                      accept="image/*"
                      placeholder="upload image"
                      prepend-icon="mdi-camera"
                      label="upload image"
                      multiple
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeAddTicketDialog"
              class="custom-button"
            >
              Cancel
            </v-btn>

            <v-btn
              color="blue darken-1 white--text"
              class="custom-button"
              @click="submit"
              :disabled="!valid"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="ticketHeaders" sort-by="name" :items="tickets">
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="viewComments(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>view comments </span>
            </v-tooltip>

            <router-link :to="/ticket-details/+ item.id " custom v-slot="{ navigate }" class="ml-4">
              <button @click="navigate" role="link">
                View Details
              </button>
            </router-link>
          </template>
          <template v-slot:item.client="{ item }">
            {{ item.client.name }}
          </template>
          <template v-slot:item.state="{ item }">
            <span v-if="item.state">{{ item.state.name }}</span>
          </template>
          <!-- <template v-slot:item.application="{ item }">
            {{ item.application.name }}
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Comments from "../components/Comments.vue";
import { Comment, Ticket } from "../models";

export default {
  components: { Comments },
async mounted() {
    await this.loadTicket();
  },
  computed: {
    ...mapGetters("auth", ["clientName"]),
    ...mapGetters("ticket", ["tickets", "comments", "currentlyAddedComment"]),
  },
  data() {
    return {
      files: null,
      ticketFile: null,
      valid: false,
      required: [(v) => !!v || "this field is required"],
      addTicketDialog: false,
      viewCommentsDialog: false,
      ticketId: null,
      clientSelected: null,
      validForm: false,
      editedComment: new Comment(),
      editedTicket: new Ticket(),
      ticketHeaders: [
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
          width: "40%",
        },
        // {
        //   text: "application",
        //   align: "start",
        //   sortable: false,
        //   value: "application",
        // },
        {
          text: "state",
          align: "start",
          sortable: false,
          value: "state",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    addTicket() {
      this.addTicketDialog = true;
    },
    async submit() {
      this.editedTicket.client = { name: this.clientName };
      this.editedTicket.application = { name: "cms" };
      let response = await this.$store.dispatch(
        "ticket/createTicket",
        this.editedTicket
      );

      if (this.ticketFile) {
        this.uploadTicketFile(response);
      }
      this.addTicketDialog = false;
    },
    async addComment(comment) {
      let payload = {
        text: comment.text,
        username: this.clientName,
        ticket: { id: this.ticketId },
        ticketId: this.ticketId,
      };
      let response = await this.$store.dispatch("ticket/addComment", payload);
      if (this.files) {
        this.uploadCommentFile(response);
      }
      comment.text = null;
      this.files = null;
    },

    async loadTicket() {
      let payload = {
        pageNumber: 1,
        pageSize: 10,
        criteria: { client: { name: this.clientName} ,application:{name:"cms"} },
      };
      await this.$store.dispatch("ticket/loadTicket", payload);
    },

    async viewComments(item) {
      this.ticketId = item.id;
      this.viewCommentsDialog = true;
      await this.$store.dispatch("ticket/loadCommentsByTicketId", item.id);
    },
    closeViewCommentsDialog() {
      this.ticketId = null;
      this.viewCommentsDialog = false;
    },
    closeAddTicketDialog() {
      this.addTicketDialog = false;
    },
    uploadCommentFile(comment) {
      for (let item in this.files) {
        const formData = new FormData();
        formData.append("file", this.files[item]);
        formData.append("commentId", comment.id);
        this.$store.dispatch("ticket/uploadImage", formData);
      }
    },
    uploadTicketFile(ticket) {
      for (let item in this.ticketFile) {
        let formData = new FormData();
        formData.append("file", this.ticketFile[item]);
        formData.append("ticketId", ticket.id);
        this.$store.dispatch("ticket/uploadTicketImage", formData);
      }
    },
  },
  
};
</script>

<style scoped lang="scss">
.v-data-table {
  font-size: large;
  color: black;
}
.custom-button {
  border-radius: 3.125rem;
  color: white;
}

.custom-action {
  width: max-content;
  min-height: fit-content;
  display: inline-flex;
  padding: 0;
}
.margin-comment {
  margin-bottom: 10px;
  margin-left: auto;
}
.view-comments-dialog {
  height: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}
.margin {
  margin: 1rem;
}
.text-area-and-input-file {
  position: relative;
}
.input-file {
  position: absolute;
  bottom: 5%;
  right: 15px;
  border: none;
  padding: 0;
  margin: 0;
}
</style>
