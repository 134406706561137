import { Language } from "./index";
export default class LocalizedPostFileCaption {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.language = rawData.language ? new Language(rawData.language) : null;
    this.locale = rawData.locale;
    this.postFileCaptionId = rawData.postFileCaptionId;
    this.caption = rawData.caption;
  }
}
