<template>
  <v-container fluid class="overflow-hidden">
    <v-row>
      <v-col class="content-menu-container" v-if="contentType" cols="2">
        <ContentMenu :contentType="contentType" />
      </v-col>
      <v-col class="content-container" cols="10">
        <router-view :key="$route.fullPath"></router-view>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContentMenu from "../components/ContentMenu.vue";
import { mapGetters } from "vuex";
import urlHelper from "../../../mixins/url-helper";

export default {
  name: "Content",
  mixins: [urlHelper],
  components: {
    ContentMenu,
  },
  data() {
    return {
      contentType: null,
    };
  },
  async mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("contentManagement", ["contentTypes"]),
  },
  methods: {
    init() {
      const contentTypeId = this.rootContentTypeId;
      if (contentTypeId)
        this.contentType = this.contentTypes.find((x) => x.id == contentTypeId);
    },
  },
  watch: {
    contentTypes() {
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.content-menu-container {
  box-shadow: none;
  background: #f7f7f7;
  height: calc(100vh - 76px);
  overflow-y: auto;
  border-right: 1px solid #000000;
  z-index: 1;
  flex-basis: 260px;
  padding: 24px 16px 16px 16px;
}

.content-container {
  height: calc(100vh - 76px);
  max-height: calc(100vh - 76px);
  overflow: auto;
  flex-grow: 1;
}
</style>
