<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" v-if="!loading" class="save-btn">
        <v-btn
          color="black darken-1 white--text "
          class="custom-button"
          @click="saveChangeStatus"
        >
          Save
        </v-btn>
      </v-col>

      <v-col>
        <v-data-table
          hide-default-footer
          item-key="id"
          :items-per-page="-1"
          :headers="registerFormColumnsHeaders"
          :loading="loading"
          :items="registerFormColumnsNames"
          class="capitalize"
        >
          <template v-slot:[`item.select`]="{ item }">
            <v-checkbox v-model="selectItem" @change="select" :value="item" />
          </template>

       

      
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Pagination -->

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import RegisterFormColumns from "../../models/RegisterFormColumnsNames";

export default {
  data() {
    return {
      snackbar: false,
      snackbarText: ``,
      selectItem: [],
      selectAll: false,
      loading: false,
      editedContactUsColumn: new RegisterFormColumns(),
      editeExtraColumns: false,
      registerFormColumnsHeaders: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "select",
        },
        {
          text: "name",
          align: "start",
          sortable: false,
          value: "displayName",
        },

      ],
    };
  },
  async mounted() {
    await this.loadRegisterFormColumns();
  await this.loadRegisterFormColumnsSelected();
  },

  computed: {
    ...mapGetters("registerForm", [
      "registerFormColumnsNames",
      "cotactUsConfigObject",
    ]),
  },
  methods: {
    async loadRegisterFormColumns() {
      await this.$store.dispatch("registerForm/loadRegistrFormColumnsNames");
    },
    loadRegisterFormColumnsSelected() {
      let activeRegisterFormColumns= this.registerFormColumnsNames.filter(
        (a) => a.isActive
      );
      this.selectItem = activeRegisterFormColumns;
      this.selectAll =
        this.registerFormColumnsNames.length != activeRegisterFormColumns.length
          ? false
          : true;
    },

    select() {
      this.selectAll =
        this.selectItem.length != this.registerFormColumnsNames.length
          ? false
          : true;
    },
    selectAllColumns() {
      this.selectItem = [];
      this.selectAll
        ? (this.selectItem = this.registerFormColumnsNames)
        : false;
    },

    async saveChangeStatus() {
      this.snackbar = true;
      try {
        await this.$store.dispatch(
          "registerForm/registerFormColumnsChangeStatus",
          this.selectItem
        );
        this.snackbarText = "Data updated Successfully";
      } catch (error) {
        this.snackbarText = error;
      }
    },
    closeContactUsColumnDialog() {
      this.editContactUsColumnDialog = false;
      this.editeExtraColumns = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.save-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.page-size-selector {
  width: 75px;
}
.v-data-table {
  font-size: large;
  color: black;
}
.disabled-name {
  pointer-events: none;
  opacity: 0.8;
}
.v-input input {
  text-transform: capitalize;
}
</style>
