import CareersListing from "../careers/views/pages/careersListing.vue";
import CareersDetails from "../careers/views/pages/careersDetails.vue";
export const careersRouters = [
	{
		path: "/careers",
		name: "CareersListing",
		component: CareersListing
	},
	{
		path: "/career-datails/:id",
		name: "CareersDetails",
		component: CareersDetails
	}
];
