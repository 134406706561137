export default class Tenant {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.userName = rawData.userName;
    this.password = rawData.password;
    this.dbUrl = rawData.dbUrl;
    this.tenantName = rawData.tenantName;
    this.databaseName = rawData.databaseName;
    this.enabled = rawData.enabled;
    this.tenantCode = rawData.tenantCode;
    this.websiteLogo = rawData.websiteLogo
  }
}
