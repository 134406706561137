<template>
  <v-container fluid>
    <div class="select-parent-category">
      <v-select
        label="Select Content Category Parent"
        persistent-hint
        return-object
        single-line
        dense
        outlined
        :items="getRootNames"
        v-model="contentCategorySelected"
        @change="children"
      />
    </div>
    <v-data-table
      :headers="contetCategoriesHeaders"
      hide-default-footer
      class="elevation-1 capitalize"
    >
      <!--eslint-disable -->

      <template v-slot:body>
        <draggable
          :list="contentCategoryChildren"
          tag="tbody"
          pointer-events:
          none;
          @end="endOrderingContentCategory"
        >
          <tr
            v-for="(item, index) in contentCategoryChildren"
            :key="item + index"
          >
            <td>
              <v-icon small class="page__grab-icon"> mdi-arrow-all </v-icon>
            </td>

            <td>
              <span v-if="item && item.localizations['EN']">{{
                item.localizations["EN"].categoryName
              }}</span>
            </td>

            <td>
              <span
                v-if="
                  item &&
                  item.localizations['EN'] &&
                  item.localizations['EN'].description
                "
              >
                {{ item.localizations["EN"].description }}
              </span>
            </td>

            <td>
              <v-icon color="green" v-if="item.isActive">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon color="red" v-else> mdi-close-circle </v-icon>
            </td>
            <td>
              <v-icon color="green" v-if="item.isFeatured">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon color="red" v-else> mdi-close-circle </v-icon>
            </td>
            <td>
              <v-icon small @click="editContentCategory(item)" class="ml-1">
                mdi-pencil
              </v-icon>
            </td>
          </tr>
        </draggable>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="contentCategoryDialog" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Edit Category</span>
              </v-card-title>

              <v-tabs v-model="tab" align-with-title>
                <v-tabs-slider color="black"></v-tabs-slider>

                <v-tab v-for="(tab, index) in tabs" :key="tab + index">
                  {{ tab }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab" align-with-title>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-form v-model="valid" ref="form">
                        <!-- <div class="row">
                          <div class="col-9">
                            <v-file-input
                              v-model="contentCategoryImg"
                              :rules="maxSizeRule"
                              accept="image/*"
                              prepend-icon="mdi-camera"
                              label="upload img"
                            />
                          </div>
                          <div class="col-3 img-category">
                            <v-img height="70" max-width="70" :src="imgUrl" />
                          </div>
                        </div>  -->
                        <v-tabs
                          class="zero-padding"
                          v-model="contentCategoryTab"
                          background-color="grey darken-3"
                          dark
                        >
                          <v-tabs-slider color="blue darken-6" />

                          <v-tab
                            v-for="(item, i) in formLocalizedCategories"
                            :key="item + i"
                          >
                            <v-icon x-small>mdi-file-document-box</v-icon>
                            {{ getLanguageByLocale(item.locale).description }}
                          </v-tab>
                        </v-tabs>
                        <v-tabs-items
                          class="zero-padding"
                          v-model="contentCategoryTab"
                        >
                          <v-tab-item
                            v-for="(item, i) in formLocalizedCategories"
                            :key="item + i"
                          >
                            <v-card flat>
                              <v-card-text>
                                <v-form v-model="valid" ref="form">
                                  <v-row>
                                    <v-col cols="6" sm="6">
                                      <v-text-field
                                        label="Category Name"
                                        v-model="item.categoryName"
                                      />
                                    </v-col>
                                    <v-col cols="6" sm="6">
                                      <v-text-field
                                        label="Description"
                                        v-model="item.description"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item class="images-style">
                  <div class="col-9">
                    <v-col cols="col-12">
                      <v-select
                        v-model="types"
                        :items="imageTypes"
                        item-text="name"
                        class="list"
                        label="Select Image Type"
                        multiple
                        return-object
                        chips
                      />
                      <span v-if="types.length == 0" class="red--text">
                        You must select at least one
                      </span>
                    </v-col>

                    <v-file-input
                      v-model="contentCategoryImage"
                      :rules="maxSizeRule"
                      accept="image/*"
                      prepend-icon="mdi-camera"
                      label="upload img"
                    />
                  </div>

                  <v-row v-if="editedItem">
                    <v-col
                      class="content-category-images"
                      cols="3"
                      v-for="(img, index) in getContentCategoriesFiles(
                        editedItem
                      )"
                      :key="img + index"
                    >

                        <v-img height="70" max-width="70" :src="img.url" />
                        <span v-if="img.types"
                          >{{ getImageTypesNames(img.types) }}
                        </span>

                        <div>
                        <v-icon small @click="editTypes(img)" class="mr-2">
                          mdi-pencil
                        </v-icon>
                      <v-btn
                        color="danger"
                        dark
                        class="custom-button"
                        @click="deleteImage(img.id)"
                      >
                        Delete
                      </v-btn>
                    </div>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="red darken-1"
                  class="custom-button"
                  text
                  @click="closeContentCategoryDialog"
                  :disabled="loadingImage"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1 white--text"
                  class="custom-button"
                  @click="submitData"
                  :loading="loadingImage"
                  :disabled="
                    loadingImage || (contentCategoryImage && types.length == 0)
                  "
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-dialog v-model="confirmationDialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">
          {{ confirmationMessage }}
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            class="custom-button"
            text
            :disabled="loading"
            @click="closeImageDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="loading"
            color="blue darken-1 white--text"
            class="custom-button"
            @click="deleteImageConfirmed"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editImageTypesDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"> edit image types </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-select
            v-model="imageTypesEdited"
            :items="imageTypes"
            item-text="name"
            class="list"
            label="Select Image Type"
            multiple
            return-object
            chips
          />
          <v-btn
            color="red darken-1"
            class="custom-button"
            text
            :disabled="loading"
            @click="clearImageDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="loading || imageTypesEdited.length==0"
            color="blue darken-1 white--text"
            class="custom-button"
            @click="editTypesConfirmed"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import settingsMixin from "../../mixins/settings";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import {
  LocalizedContentCategory,
  ContentCategory,
  Language,
} from "../../models";
export default {
  mixins: [settingsMixin],
  data() {
    return {
      tab: null,
      editedFile: null,
      contentCategoryImage: null,
      imageTypesEdited: [],
      editedItem: null,
      editImageTypesDialog: null,
      contentCategoryTab: null,
      fileId: null,
      types: [],
      contentCategoriesFiles: [],
      loading: false,
      contentCategoryTypes: [],
      tabs: ["Data", "Images"],
      confirmationDialogDelete: false,
      confirmationMessage: "",
      contentCategorySelected: null,
      loadingImage: false,
      imageName: null,
      editedContentCategory: new LocalizedContentCategory(),
      formItem: new ContentCategory(),
      defaultContentCategory: new LocalizedContentCategory(),
      contentCategoryChildren: [],
      formLocalizedCategories: [],
      contentCategoryDialog: false,
      imgUrl: null,
      showSnackbar: false,
      contentCategoryImg: null,
      snackbarText: ``,
      valid: false,
      maxSizeRule: [
        (value) =>
          value === null ||
          (value && value.size <= 31457280) ||
          "Image size should be less than 30 MB!",
      ],
      orderLoading: false,
      contetCategoriesHeaders: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "selected",
          width: "150px",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          width: "250px",
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
          width: "250px",
        },

        {
          text: "Active",
          align: "center",
          sortable: false,
          value: "isActive",
        },
        {
          text: "Featured",
          align: "center",
          sortable: false,
          value: "isFeatured",
        },
        {
          text: "Action",
          align: "start",
          sortable: false,
          value: "action",
          width: "250px",
        },
      ],
    };
  },
  async mounted() {
    await this.$store.dispatch("post/loadImageTypes");
  },
  components: { draggable },
  computed: {
    ...mapGetters("post", ["imageTypes"]),
    ...mapGetters("contentManagement", [
      "contentCategoryFile",
      "contentCategoryFiles",
    ]),
    getRootContentCategories() {
      return this.contentCategories.filter(
        (x) => (x.parentId === null || x.parentId === undefined) && x.isActive
      );
    },
    getRootNames() {
      return this.getRootContentCategories.map((item) => {
        return {
          ...item,
          text: item?.localizations[this.getLocale()]?.categoryName,
        };
      });
    },
    getContentCategoryChildren() {
      return this.contentCategories.filter(
        (a) => a.parentId == this.contentCategorySelected.id && a.isActive
      );
    },
  },
  methods: {
    getImageTypesNames(types) {
      return types.map((a) => a.name).join('-'); 
       },
    editTypes(img) {
      this.editImageTypesDialog = true;
      this.imageTypesEdited = img.types;
      this.editedFile = img;
    },

    async editTypesConfirmed() {
      await this.addImageType(this.editedFile, "updateImageType");
      this.clearImageDialog();
    },

    async loadLocalizedContentCategoriesByParentId(parentId) {
      this.formLocalizedCategories = await this.$store.dispatch(
        "contentManagement/findCategoryById",
        parentId
      );
      const addedLanguages = [
        ...new Map(
          this.formLocalizedCategories.map((item) => [
            item["locale"],
            item.locale,
          ])
        ).values(),
      ];
      const missedLanguages = this.languages.filter(
        (item) => !addedLanguages.find((element) => element === item.locale)
      );
      if (missedLanguages?.length)
        this.constructLocalizedCategoryWithLanguages(missedLanguages);
    },
    constructLocalizedCategoryWithLanguages(languages) {
      for (let i = 0; i < languages?.length; i++) {
        let newLocalizedCategory = new LocalizedContentCategory({
          id: null,
          language: new Language(languages[i]),
          locale: languages[i].locale,
          categoryName: "",
        });
        this.formLocalizedCategories.push(newLocalizedCategory);
      }
    },
    async endOrderingContentCategory(event) {
      try {
        if (this.orderLoading) return;
        this.orderLoading = true;
        const oldIndex = event?.oldIndex;
        const newIndex = event?.newIndex;
        if (oldIndex !== newIndex) {
          const payload = {
            contentCategoryId: this.contentCategoryChildren[newIndex]?.id,
            orderNumber: newIndex + 1,
          };

          await this.$store.dispatch(
            "contentManagement/updateContentCategoryOrder",
            payload
          );
          this.snackbarText = "content category order updated successfully";
        }
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.orderLoading = false;
        this.snackbar = true;
      }
    },
    async children() {
      this.contentCategoryChildren = this.contentCategorySelected
        ? this.getContentCategoryChildren
        : null;
      let childrenWithNullOrder = this.contentCategoryChildren.filter(
        (a) => !a.orderNumber
      );
      if (childrenWithNullOrder.length) {
        await this.$store.dispatch(
          "contentManagement/updateContentCategoryOrderFirstTime",
          childrenWithNullOrder
        );
        await this.loadContentCategories();
        this.contentCategoryChildren = this.getContentCategoryChildren;
      }
      return this.contentTypeChildren;
    },
    async loadContentCategories() {
      await this.$store.dispatch("contentManagement/loadContentCategories");
    },
    getContentCategoriesFiles(item) {
      return item.contentCategoryFiles?.map((image) => {
        return {
          id: image.id,
          url: image.fileUrl,
          types: image?.contentCategoryFileImageTypes?.map((a) => a.imageType),
        };
      });
    },

    async editContentCategory(item) {
      this.editedItem = item;
      this.imgUrl = item.imgUrl;
      this.imageName = item.imgUrl ? item.imgUrl.split("/") : null;
      this.contentCategoryImg = this.imageName
        ? new File([""], this.imageName[this.imageName.length - 1])
        : null;
      this.formItem = item;
      this.contentCategoryDialog = true;
      await this.loadLocalizedContentCategoriesByParentId(item.id);
    },
    async updateLocalizedCategories(contentCategory) {
      this.formLocalizedCategories = this.formLocalizedCategories.filter(
        (x) => x.categoryName !== ""
      );
      for (let i = 0; i < this.formLocalizedCategories.length; i++) {
        this.formLocalizedCategories[i].contentCategory = contentCategory;
        this.formLocalizedCategories[i].contentCategoryId = contentCategory.id;
        this.formLocalizedCategories[i]?.id
          ? await this.$store.dispatch(
              "contentManagement/updateLocalizedContentCategory",
              this.formLocalizedCategories[i]
            )
          : await this.$store.dispatch(
              "contentManagement/addLocalizedContentCategory",
              this.formLocalizedCategories[i]
            );
      }
    },
    async addContentCategoryImage(contentCategoryImage, id) {
      const formData = new FormData();
      formData.append("file", contentCategoryImage);
      formData.append("contentCategoryId", id);
      await this.$store.dispatch(
        "contentManagement/addContentCategoryImage",
        formData
      );
    },
    async addImageType(contentCategoryFile, mutationType) {
     await this.$store.dispatch(
        "contentManagement/updateContentCategoryImagesTypes",
        {
          contentCategoryFileId: contentCategoryFile.id,
          imagesTypes: this.imageTypesEdited.length>0
            ? this.imageTypesEdited
            : this.types,
          mutation: mutationType,
        }
      );
    },
    deleteImage(id) {
      this.fileId = id;
      this.confirmationDialogDelete = true;
      this.confirmationMessage =
        "Are you sure you want to delete this picture?";
    },
    closeImageDialog() {
      this.confirmationDialogDelete = false;
    },
    async deleteImageConfirmed() {
      await this.$store.dispatch(
        "contentManagement/removeContentCategoryFile",
        this.fileId
      );
      this.snackbar = true;
      this.loadingImage = false;
      this.closeImageDialog();
    },
    closeContentCategoryDialog() {
      this.contentCategoryDialog = false;
      this.clearData();
      this.clearImageDialog();
    },
    clearImageDialog() {
      this.contentCategoryImage = null;
      this.types = [];
      this.imageTypesEdited = [];
      this.editImageTypesDialog = false;
    },
    clearData() {
      this.contentCategoryImg = null;
      this.fileId = null;
      this.contentCategoriesFiles = null;
      this.imgUrl = null;
      this.imageName = null;
      this.$nextTick(() => {
        this.editedContentCategory = Object.assign(
          {},
          this.defaultContentCategory
        );
        this.contentCategoryTab = null;
        this.valid = false;
        this.formItem = Object.assign({}, new ContentCategory());
      });
    },
    async submitData() {
      try {
        this.loadingImage = true;
        await this.updateLocalizedCategories(this.formItem);
        if (
          (this.contentCategoryImg &&
            this.imageName &&
            this.imageName[4] != this.contentCategoryImg.name) ||
          (!this.imageName && this.contentCategoryImg)
        )
          await this.updateImgUrl(this.formItem);

        if (this.contentCategoryImage) {
          await this.addContentCategoryImage(
            this.contentCategoryImage,
            this.formItem.id
          );
          await this.addImageType(
            this.contentCategoryFile,
            "addContentCategoryImagesTypes"
          );
        }
        this.snackbarText = "Data saved successfully";
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.snackbar = true;
        this.loadingImage = false;
        if (!this.contentCategoryImage) this.closeContentCategoryDialog();
        this.clearImageDialog();
      }
    },
    async updateImgUrl(contentCategory) {
      const formData = new FormData();
      formData.append("contentCategoryImg", this.contentCategoryImg);
      let payloadUpdateImage = {
        contentCategoryId: contentCategory.id,
        formData: formData,
      };
      await this.$store.dispatch(
        "contentManagement/updateContentCategoryImage",
        payloadUpdateImage
      );
    },
  },
  watch: {
    async contentCategories(newVal) {
      this.$store.commit("contentManagement/setContentCategories", newVal);
      this.contentCategoryChildren = this.getContentCategoryChildren;
    },
   
  },
};
</script>
<style scoped>
.content-category-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.images-style {
  margin: 1rem;
}
.select-parent-category {
  width: 25%;
}
</style>
