export default class ContentCategory {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.code = rawData.code;
    this.description = rawData.description;
    this.localizations = rawData.localizations;
    this.contentCategoryFiles = rawData.contentCategoryFiles;
    // this.defaultContentCategory = rawData.defaultContentCategory;
    this.contentCategory = rawData.contentCategory
      ? new ContentCategory(rawData.contentCategory)
      : null;
    this.parentId = rawData.parentId;
    this.hasRelation = rawData.hasRelation,
    this.relatedTo = rawData.relatedTo,
    this.isActive = rawData.isActive;
    this.imgUrl = rawData.imgUrl
  }
}
