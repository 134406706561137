import NetworkManager from "../../../network/network-manager";
import Parser from "../network/parser.js";
export default {
  // START LOCALIZED POST
  async findLocalizedPostByPostId(context, payload) {
    await NetworkManager.get(
      context,
      `localized-post/post/${payload}`,
      "LocalizedPost",
      "setLocalizedPosts",
      Parser
    );
  },
  async loadLocalizedPostsByRootPostAndContentTypeIdAndLanguage(
    context,
    payload
  ) {
    const locale =
      context.rootGetters["contentManagement/defaultLanguage"].locale;
    await NetworkManager.post(
      context,
      `localized-post/post/${payload.rootPostId}/content-type/${payload.contentTypeId}/language/${locale}`,
      payload,
      "PaginationResponse",
      "setLocalizedPostsPaginated",
      Parser
    );
  },
  async loadLocalizedPostsByContentTypeIdAndLanguage(context, payload) {
    const locale =
      context.rootGetters["contentManagement/defaultLanguage"].locale;
    await NetworkManager.post(
      context,
      `localized-post/content-type/${payload.id}/language/${locale}`,
      payload,
      "PaginationResponse",
      "setLocalizedPostsPaginated",
      Parser
    );
  },
  async loadLocalizedPostsByContentTypeIdAndLanguageAndSearch(
    context,
    payload
  ) {
    const locale =
      context.rootGetters["contentManagement/defaultLanguage"].locale;
    await NetworkManager.post(
      context,
      `localized-post/content-type/${payload.id}/language/${locale}/search`,
      payload,
      "LocalizedPost",
      "setLocalizedPostsPaginated",
      Parser
    );
  },
  async filterPosts(context, payload) {
    const locale =
      context.rootGetters["contentManagement/defaultLanguage"].locale;
    await NetworkManager.post(
      context,
      `localized-post/content-type/${payload.contentTypeId}/content-category/${payload.contentCategoryId}/language/${locale}`,
      payload,
      "PaginationResponse",
      "setLocalizedPostsPaginated",
      Parser
    );
  },
  async addLocalizedPost(context, payload) {
    await NetworkManager.post(
      context,
      `localized-post`,
      payload,
      "LocalizedPost",
      "addLocalizedPost",
      Parser
    );
  },
  async updateLocalizedPost(context, payload) {
    await NetworkManager.put(
      context,
      `localized-post/${payload.id}`,
      payload,
      "LocalizedPost",
      "updateLocalizedPost",
      Parser
    );
  },
  // END LOCALIZED POST

  // START POST
  async findPostById(context, payload) {
    await NetworkManager.get(
      context,
      `post/${payload}`,
      "Post",
      "setSelectedPost",
      Parser
    );
  },
  async findPost(context, payload) {
    return await NetworkManager.get(
      context,
      `post/${payload}`,
      "Post",
      "setParentPost",
      Parser
    );
  },
  async findParentPageNumber(context, payload) {
    return await NetworkManager.get(
      context,
      `post/order-number/${payload.orderNumber}/content-type/${payload.contentTypeId}/page-size/${payload.pageSize}`,
      "PageNumber",
      "setPageNumber",
      Parser
    );
  },
  async findChildPageNumber(context, payload) {
    context.rootState.post.currentRoute = payload.currentRoute;
    return await NetworkManager.get(
      context,
      `post/order-number/${payload.orderNumber}/content-type/${payload.contentTypeId}/page-size/${payload.pageSize}?parentId=${payload.parentId}`,
      "PageNumber",
      "setChildPageNumber",
      Parser
    );
  },
  async findPostByContentTypeId(context, payload) {
    await NetworkManager.get(
      context,
      `post/content-type/${payload}`,
      "Post",
      "setSelectedPost",
      Parser
    );
  },
  async findPostByRootParentIdAndContentTypeId(context, payload) {
    await NetworkManager.get(
      context,
      `post/${payload.rootParentId}/content-type/${payload.contentTypeId}`,
      "Post",
      "setSelectedPost",
      Parser
    );
  },
  async addPost(context, payload) {
    return await NetworkManager.post(
      context,
      `post`,
      payload,
      "Post",
      null,
      Parser
    );
  },
  async updatePost(context, payload) {
    return await NetworkManager.put(
      context,
      `post/${payload.id}`,
      payload,
      "Post",
      null,
      Parser
    );
  },
  async updateOrder(context, payload) {
    return await NetworkManager.put(
      context,
      `post/${payload.postId}/order?orderNumber=${payload.orderNumber}`,
      null,
      "Post",
      "updatePostsOrder",
      Parser
    );
  },
  async updatePostActiveStatus(context, payload) {
    return await NetworkManager.put(
      context,
      `post/active-status`,
      payload,
      "Post",
      "updatePostStatus",
      Parser
    );
  },
  async deletedPost(context, payload) {
    await NetworkManager.put(
      context,
      `post/marked-as-deleted/${payload.post.id}`,
      payload,
      "Post",
      "deletePost",
      Parser
    );
  },
  // END POST

  // START POST CATEGORY
  async updatePostCategories(context, payload) {
    return await NetworkManager.put(
      context,
      `post-category/post/${payload.postId}`,
      payload.postCategories,
      "PostCategory",
      "setSelectedPostCategories",
      Parser
    );
  },
  // END POST CATEGORY

  // START POST FILES
  async loadPostFilesByPostId(context, payload) {
    return await NetworkManager.get(
      context,
      `post-file/post-id/${payload}/active/true`,
      "PostFile",
      "setPostFiles",
      Parser
    );
  },
  async addFileUrl(context, payload) {
    return await NetworkManager.put(
      context,
      `post-file/post-file-url`,
      payload,
      "PostFile",
      "addPostFile",
      Parser
    );
  },
  async addFile(context, payload) {
    return await NetworkManager.post(
      context,
      `post-file`,
      payload,
      "PostFile",
      "addPostFile",
      Parser,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  async updateFile(context, payload) {
    return await NetworkManager.put(
      context,
      `post-file/image-type`,
      payload,
      "PostFile",
      null,
      Parser
    );
  },
  async updatePostFile(context, payload) {
    return await NetworkManager.put(
      context,
      `post-file`,
      payload,
      "PostFile",
      "setUpdatePostFile",
      Parser,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  async updatePostFileOrder(context, payload) {
    return await NetworkManager.put(
      context,
      `post-file/order`,
      payload,
      "PostFile",
      null,
      Parser
    );
  },
  async updateOrderFile(context, payload) {
    return await NetworkManager.put(
      context,
      `post-file/${payload.postFileId}/order?orderNumber=${payload.fileOrder}`,
      null,
      "PostFile",
      null,
      Parser
    );
  },
  async deletePostFile(context, payload) {
    return await NetworkManager.put(
      context,
      `post-file/marked-as-deleted/${payload.id}`,
      payload,
      "PostFile",
      "updateFileStatus",
      Parser
    );
  },
  // END POST FILES

  // START POST IMAGE TYPES
  async loadImageTypeByPostId(context, payload) {
    return await NetworkManager.get(
      context,
      `post-file-image-type/post-file/${payload}`,
      "PostFileImageType",
      "setEditedPostFileImageTypes",
      Parser
    );
  },
  async addSelectImageType(context, payload) {
    return await NetworkManager.put(
      context,
      `post-file-image-type/post-file/${payload.postFileId}`,
      payload.imageTypeArray,
      "PostFile",
      "addSelectImageType",
      Parser
    );
  },
  // END POST IMAGE TYPES

  // START IMAGE TYPES
  async loadImageTypes(context) {
    await NetworkManager.get(
      context,
      `image-type`,
      "ImageType",
      "setImageTypes",
      Parser
    );
  },
  async addImageType(context, payload) {
    await NetworkManager.post(
      context,
      `image-type`,
      payload,
      "ImageType",
      "addImageType",
      Parser
    );
  },
  async updateImageType(context, payload) {
    await NetworkManager.put(
      context,
      `image-type/${payload.id}`,
      payload,
      "ImageType",
      null,
      Parser
    );
  },
  // END IMAGE TYPES

  //IMAGE CAPTION
  async getLocalizedPostFileCaption(context, payload) {
    await NetworkManager.get(
      context,
      `localized-post-file-caption/caption/${payload}`,
      "LocalizedPostFileCaption",
      "setLocalizedPostFileCaption",
      Parser
    );
  },
  async createPostFileCaption(context, payload) {
    await NetworkManager.post(
      context,
      "post-file-caption",
      payload,
      "PostFileCaption",
      "setPostFileCaption",
      Parser
    );
  },
  async addLocalizedPostFileCaption(context, payload) {
    await NetworkManager.post(
      context,
      "localized-post-file-caption",
      payload,
      "LocalizedPostFileCaption",
      null,
      Parser
    );
  },
  async updateLocalizedPostFileCaption(context, payload) {
    await NetworkManager.put(
      context,
      `localized-post-file-caption/${payload.id}`,
      payload,
      "LocalizedPostFileCaption",
      null,
      Parser
    );
  },

  //sizeConfiguration
  async updateSizeConfiguration(context, payload) {
    await NetworkManager.put(
      context,
      `size-configuration/${payload.id}`,
      payload.item,
      "SizeConfiguration",
      null,
      Parser
    );
  },

  async loadSizeConfiguration(context) {
    // console.log("context" , context.rootGetters["tenant/tenantCode"])
    let tenantCode = context.rootGetters["tenant/tenantCode"];
    // console.log("tennattt" , tenantCode);
    context.rootState.auth.user.group = tenantCode;
    await NetworkManager.get(
      context,
      `size-configuration`,
      "SizeConfiguration",
      "setSizeConfiguration",
      Parser
    );
  },

  async loadSizeConfigurationById(context, payload){
    await NetworkManager.get(
      context,
      `size-configuration/${payload}`,
      "SizeConfiguration",
      "setSizeConfigurationSelected",
      Parser
    );
  }

};
