<template>
  <v-container fluid>
    <v-toolbar flat>
      <template>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          class="mb-2 custom-button"
          v-if="contactUs.length"
          @click="downloadExcelsheet"
        >
          export
        </v-btn>
      </template>
    </v-toolbar>
    <!-- DATATABLE -->
    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          :headers="contactUsColumnsHeader"
          :loading="loading"
          :items-per-page="pageSize"
          :items="contactUs"
          class="capitalize"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="red darken-4" small @click="deleteContentUs(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`item.contactUsFiles`]="{ item }">
            <div v-if="item.contactUsFiles && item.contactUsFiles.length > 0">
              <div
                v-for="(file, index) in getFilesUrl(item.contactUsFiles)"
                :key="index"
              >
                <a :href="file" class="contact-us-file">{{ getName(file) }}</a>
                <span>-</span>
              </div>
            </div>
          </template>

          <template v-slot:top>
            <v-dialog v-model="confirmationDialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">
                  {{ confirmationMessage }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red darken-1"
                    class="custom-button"
                    text
                    @click="closeConfirmationDialog"
                    :disabled="loading"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="loading"
                    color="blue darken-1 white--text"
                    class="custom-button"
                    @click="deleteContactUsConfirmed"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.commentConfig1`]="{ item }">
            {{ item.comment1 }}
          </template>

          <template v-slot:[`item.commentConfig2`]="{ item }">
            {{ item.comment2 }}
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            <span v-if="item.startDate">{{ getDate(item.startDate) }} </span>
          </template>

          <template v-slot:[`item.commentConfig3`]="{ item }">
            {{ item.comment3 }}
          </template>
          <template v-slot:[`item.commentConfig4`]="{ item }">
            {{ item.comment4 }}
          </template>

          <template
            v-for="type in getLookupTypes"
            v-slot:[`item.${type}`]="{ item }"
          >
            {{ getLookupData(type, item.contactUsLookupData) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Pagination -->
    <v-row
      class="justify-end mt-6 align-center"
      v-if="contactUsPaginated.totalNumberOfPages > 1"
    >
      <div class="mr-2">
        <v-select
          class="page-size-selector"
          label="page size"
          outlined
          dense
          hide-details
          :items="pageSizeOptions"
          :value="10"
          @change="pageSize = parseInt($event, 10)"
        />
      </div>
      <div class="mr-2">
        <span>{{ pageNumber }}</span>
        <span class="mx-1">From</span>
        <span>{{ contactUsPaginated.totalNumberOfPages }}</span>
      </div>
      <v-pagination
        class="align-self-start py-2"
        v-model="pageNumber"
        :length="contactUsPaginated.totalNumberOfPages"
      />
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      snackbar: false,
      loading: false,
      toBeDeleteContactUs: null,
      snackbarText: ``,
      confirmationDialogDelete: false,
      confirmationMessage: "",
      pageSize: 10,
      pageNumber: 1,
      pageCategorySelected: null,
      pageSizeOptions: [5, 10, 15, 20],
    };
  },
  async mounted() {
    this.loading = true;
    await this.loadContactUsConfig();
    await this.loadContactUsColumns();
    await this.loadContactUs();
    this.getLookupTypes;
    this.loading = false;
  },

  watch: {
    async pageNumber() {
      await this.loadContactUs();
      await this.loadContactUsColumns();
      await this.loadContactUsConfig();
      this.getLookupTypes;
    },
    async pageSize() {
      if (this.pageSize < 1 || this.pageSize > 20) {
        this.pageSize = 10;
      }
      await this.loadContactUs();
      await this.loadContactUsColumns();
      await this.loadContactUsConfig();
    },
  },

  computed: {
    ...mapGetters("contactUs", [
      "contactUsPaginated",
      "contactUsColumns",
      "cotactUsConfigObject",
    ]),
    contactUs: {
      get() {
        return this.contactUsPaginated.result;
      },
    },

    getLookupTypes() {
      let contactUsLookupData = this.contactUsPaginated.result
        .map((a) => a.contactUsLookupData)
        .flat();
      let LookupTypes = contactUsLookupData.map(
        (a) => a.lookupData.lookupType.code
      );
      let uniqueVlaues = [...new Set(LookupTypes)];
      return uniqueVlaues;
    },
    contactUsColumnsHeader() {
      let headers = this.contactUsColumns
        .filter((contactusColumn) => contactusColumn.isActive)
        ?.map((a) => {
          return {
            value: a.name,
            text: a.displayName,
          };
        });
      this.setContactUsConfigHeader(headers);
      if (this.getLookupTypes.length > 0) {
        this.getLookupTypes.forEach((item) => {
          headers.push({
            text: item,
            value: item,
          });
        });
      }
      headers.push({
        text: "Actions",
        value: "actions",
      });
      return headers;
    },
  },
  methods: {
    getLookupData(lookupType, item) {
      let lookupData = item
        .filter((a) => a.lookupData.lookupType.code == lookupType)
        .map((a) => a.lookupData.name)
        .join(",");
      return lookupData;
    },
    getDate(startDate) {
      let date = new Date(startDate);
      return moment(date)
        .utc()
        .format("YYYY-MM-DD");
    },
    setContactUsConfigHeader(headers) {
      if (this.cotactUsConfigObject?.commentConfig1) {
        headers.push({
          text: this.cotactUsConfigObject.commentConfig1,
          value: "commentConfig1",
        });
      }
      if (this.cotactUsConfigObject?.commentConfig2) {
        headers.push({
          text: this.cotactUsConfigObject.commentConfig2,
          value: "commentConfig2",
        });
      }
      if (this.cotactUsConfigObject?.commentConfig3) {
        headers.push({
          text: this.cotactUsConfigObject.commentConfig3,
          value: "commentConfig3",
        });
      }
      if (this.cotactUsConfigObject?.commentConfig4) {
        headers.push({
          text: this.cotactUsConfigObject.commentConfig4,
          value: "commentConfig4",
        });
      }
    },
    deleteContentUs(item) {
      this.toBeDeleteContactUs = item;
      this.confirmationDialogDelete = true;
      this.confirmationMessage =
        "Are you sure you want to delete this contact?";
    },
    deleteContactUsConfirmed() {
      try {
        this.snackbar = true;
        this.$store.dispatch(
          "contactUs/deleteContactUs",
          this.toBeDeleteContactUs
        );
        this.closeConfirmationDialog();
        this.snackbarText = "Deleted contact us successfully";
      } catch (error) {
        this.snackbar = true;
        this.snackbarText = error;
      } finally {
        this.closeConfirmationDialog();
        this.snackbar = true;
        this.loading = false;
        this.toBeDeleteContactUs = null;
      }
    },
    closeConfirmationDialog() {
      this.confirmationDialogDelete = false;
    },
    async loadContactUs() {
      let payload = {
        sortingByList: [
          {
            fieldName: "id",
            direction: "DESC",
          },
        ],
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      await this.$store.dispatch("contactUs/loadContactUsPaginated", payload);
    },
    async loadContactUsColumns() {
      await this.$store.dispatch("contactUs/loadContactUsColumns");
    },
    async loadContactUsConfig() {
      await this.$store.dispatch("contactUs/loadContactUsConfig");
    },
    getFilesUrl(files) {
      return files.map((item) => item.fileUrl);
    },
    getName(file) {
      let fileNameSplit = file.split("/");
      return fileNameSplit[fileNameSplit.length - 1];
    },
    async downloadExcelsheet() {
      this.export = true;
      const res = await this.$store.dispatch(
        "contactUs/downloadContactUsExcel"
      );
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "contactUs.xlsx");
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss">
.contact-us-file {
  text-decoration: underline;
}
.text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.page-size-selector {
  width: 75px;
}
.v-data-table {
  font-size: large;
  color: black;
}
</style>
