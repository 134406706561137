import PostsListing from "./views/PostsListing.vue";
import PostForm from "../posts/components/PostForm";
import Content from "../posts/views/Content";

export const SEGMENTS = {
  ROOT_TYPE: "content-type",
  ROOT_POST: "root",
  TYPE: "type",
  POST: "post",
};

export const postRouters = [
  {
    path: "/content-type/:id",
    name: "ContentTypePosts",
    component: PostsListing,
  },

  {
    path: "/content-type/:id/root/:postId?",
    name: "PostEntry",
    component: Content,
    children: [
      {
        path: "type/:childTypeId",
        name: "ChildContentTypePosts",
        component: PostsListing,
      },
      {
        path: "type/:childTypeId/post/:childPostId?",
        name: "SubPostEntry",
        component: PostForm,
      },
    ],
  },
];
