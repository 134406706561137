// import { Post } from "../models";

export default {
  setSubscribes(state, payload) {
    state.subscribes = payload;
  },
  deleteItem(state, payload) {
    let index = state.subscribes.findIndex(x => x.id == payload.id);
    state.subscribes.splice(index, 1);
  },
}