// export const KEYKLOACK_URL = {
//   STAGING: "https://websites.ntsal.dev/auth/",
//   PRODUCTION: "https://websites.ntsal.dev/auth/",
// };
// export const APIs_URL = {
//   STAGING: "https://websites.ntsal.dev/api",
//   PRODUCTION: "https://websites.ntsal.dev/api",
// };

export const KEYKLOACK_URL = {
	STAGING: "https://keycloak-staging.ntsal.dev/auth/",
	PRODUCTION: "https://websites.ntsal.dev/auth/"
};
export const APIs_URL = {
	STAGING: "http://localhost:8018/api",
	PRODUCTION: "https://websites.ntsal.dev/api"
};

export const TICKET_API_URLS = {
  STAGING: "http://localhost:8009/ticket/api",
  PRODUCTION: "https://tickets.ntsal.dev/ticket/api",
};
