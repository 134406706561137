export default class Comment {
    constructor(rawData = {}) {
      this.id = rawData.id;
      this.text = rawData.text;
      this.ticketId = rawData.ticketId;
      this.creationDate = rawData.creationDate;
      this.lastModificationDate = rawData.lastModificationDate;
      this.markedAsDeleted = rawData.markedAsDeleted;
      this.createdBy = rawData.createdBy;
      this.children = rawData.children != null ? new Comment(rawData.children): [];
  }
  }