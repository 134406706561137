import NetworkManager from "../../../network/network-manager";
import Parser from "../network/parser.js";
export default {
  async loadPages(context) {
    await NetworkManager.get(context, `page`, "Page", "setPages", Parser);
  },
  async loadSeoPaginated(context, payload) {
    await NetworkManager.post(
      context,
      `page/find-all-paginated/${payload.locale}`,
      payload.page,
      "PaginationResponse",
      "setPaginatedSeo",
      Parser
    );
  },
  async loadLocalizedPageById(context, payload) {
    await NetworkManager.get(
      context,
      `localized-page/${payload}`,
      "LocalizedPage",
      "setLocalizedPageById",
      Parser
    );
  },
  async loadPageById(context, payload) {
    await NetworkManager.get(
      context,
      `page/${payload}`,
      "Page",
      "setPageById",
      Parser
    );
  },
  async editPage(context, payload) {
    await NetworkManager.put(
      context,
      `page`,
      payload,
      "Page",
      "editPage",
      Parser
    );
  },
  async updateOrderNumber(context, payload) {
    return await NetworkManager.put(
      context,
      `page/order`,
      payload,
      "Page",
      null,
      Parser
    );
  },

  async addPage(context, payload) {
    return await NetworkManager.post(
      context,
      `page`,
      payload,
      "Page",
      "editPage",
      Parser
    );
  },

  async uploadImg(context, payload) {
    return await NetworkManager.put(
      context,
      `/page/upload-img/${payload.pageId}`,
      payload.formData,
      "Page",
      "setImageUrl",
      Parser,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  // /////////////

  async addLocalizedPage(context, payload) {
    await NetworkManager.post(
      context,
      `localized-page`,
      payload,
      "LocalizedPage",
      "setLocalizedPage",
      Parser
    );
  },

  async editLocalizedPage(context, payload) {
    await NetworkManager.put(
      context,
      `localized-page/${payload.id}`,
      payload.data,
      "LocalizedPage",
      "setLocalizedPage",
      Parser
    );
  },

  async loadPageCategory(context) {
    return await NetworkManager.get(
      context,
      `page-category`,
      "PageCategory",
      "setPageCategory",
      Parser
    );
  },

  async addPageCategory(context, payload) {
    await NetworkManager.post(
      context,
      `page-category`,
      payload,
      "PageCategory",
      "addPageCategory",
      Parser
    );
  },
  async deletePageCategoryItem(context, payload) {
    await NetworkManager.put(
      context,
      `page-category/delete-item`,
      payload,
      "PageCategory",
      "deletePageCategory",
      Parser
    );
  },

  async addPageToPageCategory(context, payload) {
    const res = await NetworkManager.put(
      context,
      `page-category/assign-seo-to-page-category/${payload.pageCategoryId}`,
      payload.selectedPages,
      "PageCategory",
      "setPagesToPageCategory",
      Parser
    );
    context.state.chnageCategory = res;
  },
  async findAllByPageCategoryId(context, payload) {
    await NetworkManager.post(
      context,
      `page/find-all-by-page-category-id/${payload.pageCategory}`,
      payload.page,
      "PaginationResponse",
      "setPaginatedSeo",
      Parser
    );
  },
  async loadPageCategoryById(context, payload) {
    await NetworkManager.get(
      context,
      `page-category/${payload.id}`,
      "PageCategory",
      "setPageCategoryById",
      Parser
    );
  },
};
