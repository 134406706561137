// import { Post } from "../models";

export default {
  setPaginatedCareers(state, payload) {
    state.careersPaginated = payload;
  },
  deleteCareers(state, payload) {
    let index = state.careersPaginated.result.findIndex(
      (x) => x.id == payload.id
    );
    state.careersPaginated.result.splice(index, 1);
  },
  setCareerItem(state, payload) {
    state.careerItem = payload;
  },
};
