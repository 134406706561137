import RegisterForm from "../register-form/views/pages/registrationFormListing.vue"
import RegisterFormColumnsNames from "./views/pages/registerFormColumnsNames.vue";


export const registerFormRouters = [
  {
    path: "/register-form",
    name: "RegisterFormListing",
    component: RegisterForm,
  },
  {
    path: "/settings/register-form-columns-names",
    name: "RegisterFormColumnsNames",
    component: RegisterFormColumnsNames,
  },

];
