<template>
  <Fragment v-if="contentType && formLocalizedPosts">
    <v-container>
      <v-row class="btns-row">
        <v-col class="btns">
          <v-checkbox
            v-model="formItem.isActive"
            label="Published"
            hide-details
          />
          <v-text-field
            v-if="contentType.postCodeConfig"
            class="mt-2"
            outlined
            name="postCode"
            :label="contentType.postCodeConfig"
            v-model="formItem.code"
            :rules="required"
          />
        </v-col>
        <v-col class="btns">
          <v-checkbox
            v-if="contentType.postCodeConfig != null"
            :disabled="formItem.isSeo && seo"
            v-model="formItem.isSeo"
            label="add to seo"
            hide-details
          />
        </v-col>
        <v-col class="btns" v-if="featureActiveStatus">
          <v-checkbox
            v-model="formItem.isFeatured"
            label="Featured"
            hide-details
          />
        </v-col>

        <v-col class="btns" v-if="heroActiveStatus">
          <v-checkbox v-model="formItem.isHero" label="Hero" hide-details />
        </v-col>
        <v-col sm="2" class="btns">
          <v-btn
            class="custom-button mr-1 mb-1"
            color="black darken-1 white--text"
            @click="submitData"
            :loading="loading"
            :disabled="
              !valid ||
                loading ||
                (contentType.postCodeConfig != '' &&
                  contentType.postCodeConfig != null &&
                  !formItem.code)
            "
          >
            Save
          </v-btn>
          <!-- <v-btn
              v-if="contentType.postCodeConfig != null"
              class="custom-button mb-1"
              color="black darken-1 white--text"
              :disabled="!formItem.code || !formItem.id"
              @click="addSeo"
              >Add to seo</v-btn
            > -->
        </v-col>
      </v-row>

      <!-- MAIN POST DATA -->
      <v-row
        v-if="
          contentType.parentId === null || contentType.noOfAllowedPosts !== 1
        "
      >
        <!-- <v-col cols="6" sm="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formItem.publishDate"
                label="Publish Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="formItem.publishDate"
              no-title
              scrollable
              @input="menu = false"
            />
          </v-menu>
        </v-col> -->

        <v-col cols="12" sm="6" md="4" v-if="contentType.postDateConfig">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formItem.postDate"
                :label="contentType.postDateConfig"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="formItem.postDate"
              no-title
              scrollable
              @input="menu2 = false"
            />
          </v-menu>
        </v-col>
      </v-row>

      <!-- LOCALIZED POSTS & RELASHIONSHIPS -->
      <v-row>
        <v-col sm="12">
          <v-tabs
            class="zero-padding main-tabs"
            v-model="tab"
            background-color="black"
            dark
            cols="6"
            sm="12"
            color="white"
          >
            <v-tabs-slider color="white darken-6" />

            <v-tab v-for="(item, i) in formLocalizedPosts" :key="i">
              <v-icon x-small>mdi-file-document-box</v-icon>
              {{ getLanguageByLocale(item.locale).description }}
            </v-tab>
            <v-tab
              v-if="isRelashioshipTabShown"
              :key="formLocalizedPosts.length"
            >
              Relationships
            </v-tab>
            <v-tab
              v-if="
                postId &&
                  (contentType.maxUploadFiles || contentType.maxUploadImages)
              "
              :key="
                isRelashioshipTabShown
                  ? formLocalizedPosts.length + 1
                  : formLocalizedPosts.length
              "
            >
              Files
            </v-tab>
          </v-tabs>
          <v-tabs-items class="zero-padding" v-model="tab">
            <v-form v-model="valid" ref="form">
              <!-- CONTENT TABS -->
              <v-tab-item
                v-for="(localizedPost, i) in formLocalizedPosts"
                :key="i"
              >
                <v-card flat>
                  <v-card-text class="forms-boxes">
                    <v-col
                      class="zero-padding"
                      v-if="contentType.titleConfig"
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        outlined
                        name="title"
                        :label="contentType.titleConfig"
                        v-model="localizedPost.title"
                        :class="{'change-direction':localizedPost.locale == 'AR'}"

                      />
                    </v-col>

                    <v-col
                      class="zero-padding"
                      v-if="contentType.customTitleConfig1"
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        outlined
                        name="customTitle1"
                        :label="contentType.customTitleConfig1"
                        v-model="localizedPost.customTitle1"
                        :class="{'change-direction':localizedPost.locale == 'AR'}"

                      />
                    </v-col>

                    <v-col
                      class="zero-padding"
                      v-if="contentType.customTitleConfig2"
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        outlined
                        name="customTitle1"
                        :label="contentType.customTitleConfig2"
                        v-model="localizedPost.customTitle2"
                        :class="{'change-direction':localizedPost.locale == 'AR'}"

                      />
                    </v-col>

                    <v-col
                      class="zero-padding"
                      v-if="contentType.shortDescriptionConfig"
                      cols="12"
                      sm="6"
                    >
                      <v-textarea
                        outlined
                        name="shortDescription"
                        :label="contentType.shortDescriptionConfig"
                        v-model="localizedPost.shortDescription"
                        :class="{'change-direction':localizedPost.locale == 'AR'}"

                      />
                    </v-col>
                    <v-col
                      class="zero-padding"
                      v-if="contentType.fullDescriptionConfig"
                      cols="12"
                      sm="6"
                    >
                      <!-- <label> {{ contentType.fullDescriptionConfig }}</label> -->
                      <v-textarea
                        :label="contentType.fullDescriptionConfig"
                        outlined
                        name="fullDescription"
                        v-model="localizedPost.fullDescription"
                        :class="{'change-direction':localizedPost.locale == 'AR'}"

                      />
                    </v-col>

                    <v-col
                      class="zero-padding"
                      v-if="contentType.customDescriptionConfig"
                      cols="12"
                      sm="6"
                    >
                      <v-textarea
                        outlined
                        name="shortDescription"
                        :label="contentType.customDescriptionConfig"
                        v-model="localizedPost.customDescription"
                        :class="{'change-direction':localizedPost.locale == 'AR'}"

                      />
                    </v-col>

                    <v-col
                      class="zero-padding"
                      v-if="contentType.htmlDescriptionConfig"
                      cols="12"
                      sm="6"
                    >
                      <vue-editor
                        :editorToolbar="customToolbar"
                        v-model="localizedPost.htmlDescription"
                      />
                    </v-col>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <!-- RELASHIONSHIPS TAB -->
              <v-tab-item
                v-if="isRelashioshipTabShown"
                :key="formLocalizedPosts.length"
              >
                <PostRelationships
                  ref="PostRelationships"
                  :contentType="contentType"
                  :post="currentPost"
                  :parentContentCategory="parentPostContentCategory"
                />
              </v-tab-item>

              <!-- FILES TAB -->
              <v-tab-item
                v-if="
                  postId &&
                    (contentType.maxUploadFiles || contentType.maxUploadImages)
                "
                :key="
                  isRelashioshipTabShown
                    ? formLocalizedPosts.length + 1
                    : formLocalizedPosts.length
                "
              >
                <PostFiles ref="postFiles" :contentType="contentType" />
              </v-tab-item>
            </v-form>
          </v-tabs-items>
        </v-col>
        <v-row v-if="selectedPost && !selectedPost.parentId">
          <v-col cols="6">
            <v-btn
              v-if="selectedPost.nextId"
              color="black darken-1 white--text"
              :to="{
                name: 'SubPostEntry',
                params: {
                  postId: selectedPost.nextId,
                  childTypeId: contentType.id,
                  childPostId: selectedPost.nextId,
                },
              }"
              link
            >
              Previous
            </v-btn>
          </v-col>
          <v-col cols="6" class="next-btn">
            <v-btn
              v-if="selectedPost.previousId"
              :to="{
                name: 'SubPostEntry',
                params: {
                  postId: selectedPost.previousId,
                  childTypeId: contentType.id,
                  childPostId: selectedPost.previousId,
                },
              }"
              link
              color="black darken-1 white--text"
            >
              NEXT
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar">
      {{ snakbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </Fragment>
</template>

<script>
import { mapGetters } from "vuex";
import { Language, LocalizedPost } from "../models/index";
import { Post } from "../models/index";
import { Fragment } from "vue-fragment";
import PostRelationships from "./PostRelationships.vue";
import urlHelper from "../../../mixins/url-helper";
import settingsMixin from "../../settings/mixins/settings";
import PostFiles from "./PostFiles.vue";
import moment from "moment";
import { VueEditor } from "vue2-editor";

export default {
  name: "PostForm",
  components: {
    Fragment,
    PostRelationships,
    PostFiles,
    VueEditor,
  },
  mixins: [settingsMixin, urlHelper],
  computed: {
    ...mapGetters("contentManagement", [
      "contentTypes",
      "contentCategories",
      "optionalFeatures",
    ]),
    ...mapGetters("post", [
      "localizedPosts",
      "selectedPost",
      "posts",
      "parentPost",
      "parentPostContentCategory",
      "pageNumberForPost",
    ]),
    contentType() {
      return this.contentTypes.find((x) => x.id == this.contentTypeId);
    },
    computedModel: function() {
      return Object.assign({}, this.formItem);
    },
    computedLocalizedFormModel: function() {
      return JSON.parse(JSON.stringify(this.formLocalizedPosts));
    },
    isRelashioshipTabShown() {
      return (
        this.contentType.contentTypeCategories?.length ||
        this.contentType.contentType?.parentId
      );
    },
    currentPost() {
      return this.postId ? this.selectedPost : null;
    },
  },
  data() {
    return {
      featureActiveStatus: false,
      seo: false,
      orderActiveStatus: false,
      heroActiveStatus: false,
      route: this.$route.fullPath,
      parentContentCategory: [],
      modal: false,
      valid: false,
      loading: false,
      menu2: false,
      tab: null,
      postCode: false,
      menu: false,
      formItem: new Post(),
      formLocalizedPosts: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      required: [(v) => !!v || "this field is required"],
      snackbar: false,
      snakbarText: ``,
      isDelete: false,
      dialogDelete: false,
      form_dirty: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons

        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [
          {
            color: [
              "#EA8B6E",
              "rgb(102, 185, 102)",
              "red",
              "green",
              "blue",
              "black",
              "#888",
              "white",
              "orange",
              "yellow",
              "#9933ff",
              "#CCE0F5",
              "#663d00",
              "#B2B200",
              "rgb(255, 235, 204)",
              "rgb(0, 55, 0)",
              "rgb(255, 255, 204)",
              "rgb(0, 71, 178)",
              "#E0C19A",
              "#FFE849",
            ],
          },
          { background: [] },
        ],

        ["link"],
        ["clean"],
        [{ size: ["small", "large", false, "huge"] }],
        // This is what I have added
        // [{ 'font': fonts.whitelist }],
      ],
    };
  },
  async mounted() {
    await this.setDataToUI();
    this.currentPost?.parentId
      ? await this.findChildPageNumber()
      : await this.findParentPageNumber();
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },

  methods: {
    async findParentPageNumber() {
      let payload = {
        contentTypeId: this.currentPost?.contentType.id,
        orderNumber: this.currentPost?.orderNumber,
        pageSize: 10,
      };
      this.currentPost
        ? await this.$store.dispatch("post/findParentPageNumber", payload)
        : null;
    },
    async findChildPageNumber() {
      let payload = {
        contentTypeId: this.currentPost?.contentType.id,
        parentId: this.currentPost?.parentId,
        orderNumber: this.currentPost?.orderNumber,
        currentRoute: this.$route,
        pageSize: 10,
      };
      await this.$store.dispatch("post/findChildPageNumber", payload);
    },

    async addSeo(pageName) {
      try {
        let pageForm = { name: pageName };
        await this.$store.dispatch("page/addPage", pageForm);
        this.snakbarText = "Add To SEO Successfully";
      } catch (error) {
        this.snakbarText = error;
      } finally {
        this.snackbar = true;
      }
    },
    routeLoaded() {
      // console.log("reload", this.$route.fullPath);
    },
    changePost(oldVal, val) {
      this.form_dirty = false;
      let oldDate = undefined;
      let newDate = undefined;
      oldDate = oldVal.postDate;
      newDate = moment(val.postDate)
        .utc()
        .format("YYYY-MM-DD");

      if (oldDate !== newDate && newDate != undefined) {
        this.form_dirty = true;
      }
    },
    changeLocalizedPost(oldVal, newVal) {
      this.form_dirty = false;
      if (newVal.length > 0) {
        for (let item = 0; item <= oldVal.length; item++) {
          if (newVal[item]?.id != null && oldVal[item]?.id != null) {
            if (
              newVal[item]?.title != oldVal[item]?.title ||
              newVal[item]?.shortDescription !=
                oldVal[item]?.shortDescription ||
              newVal[item]?.fullDescription != oldVal[item]?.fullDescription
            ) {
              this.form_dirty = true;
            }
          }
        }
      }
    },
    confirmLeave() {
      return window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      );
    },
    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();

        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },
    confirmStayInDirtyForm() {
      return this.form_dirty && !this.confirmLeave();
    },
    async setDataToUI() {
      this.formLocalizedPosts = [];
      this.constructLocalizedPostsWithLanguages(this.languages);
      if (this.postId) {
        await this.loadLocalizedPostPerPostId();
      }
      this.optionalFeatureStatus(this.contentType);
      await this.getPost();
      this.seo = this.formItem.isSeo ? true : false;
    },
    async getPost() {
      if (this.$route.name == "SubPostEntry" && this.$route.params.postId)
        await this.$store.dispatch("post/findPost", this.$route.params.postId);
    },
    async submitData() {
      try {
        this.form_dirty = false;
        this.loading = true;
        let addedPost = this.postId ? this.selectedPost : await this.addPost();
        if (this.postId) await this.updatePost();
        await this.addUpdateLocalizedPost(addedPost);
        if (this.$refs["PostRelationships"])
          await this.$refs.PostRelationships.updatePostCategories(addedPost);
        this.snakbarText = "Data saved successfully";
        if (this.formItem.isSeo && addedPost.id) {
          this.seo = true;
          await this.addSeo(addedPost.code);
        }
        this.reRoutePage(addedPost);
      } catch (error) {
        this.snakbarText = error;
      } finally {
        this.snackbar = true;
        this.loading = false;

        this.getPost();
      }
    },
    async addPost() {
      const localizedPostByDefaultLanguage = this.formLocalizedPosts.find(
        (a) => a.locale === this.defaultLanguage.locale
      );
      this.formItem.contentType = { id: this.contentTypeId };
      this.formItem.rootParentId = this.rootPostId;
      // TODO: TO BE MODIFIED FOR MULTIPLE LEVELS
      this.formItem.parentId = this.rootPostId;

      // TODO: TO BE DONE FROM THE BE
      if (
        this.contentType.parentId !== null &&
        this.contentType.noOfAllowedPosts === 1
      ) {
        this.formItem.isActive = true;
      }
      this.formItem.folderName = localizedPostByDefaultLanguage.title;

      return await this.$store.dispatch("post/addPost", this.formItem);
    },
    async updatePost() {
      this.selectedPost.isActive = this.formItem.isActive;
      this.selectedPost.isFeatured = this.formItem.isFeatured;
      this.selectedPost.isSeo = this.formItem.isSeo;
      this.seo = this.selectedPost.isSeo ? true : false;
      this.selectedPost.code = this.formItem.code;
      this.selectedPost.isHero = this.formItem.isHero;
      this.selectedPost.postDate = this.formItem.postDate;
      this.selectedPost.orderNumber = this.formItem.orderNumber;
      await this.$store.dispatch("post/updatePost", this.selectedPost);
    },
    optionalFeatureStatus(contentType) {
      this.featureActiveStatus = this.heroActiveStatus = this.orderActiveStatus = false;
      if (
        contentType.optionalFeatureContentTypes.find(
          (a) => a.optionalFeature.name == "isFeatured"
        )
      ) {
        this.featureActiveStatus = true;
      }
      if (
        contentType.optionalFeatureContentTypes.find(
          (a) => a.optionalFeature.name == "isHero"
        )
      ) {
        this.heroActiveStatus = true;
      }
      if (
        contentType.optionalFeatureContentTypes.find(
          (a) => a.optionalFeature.name == "Order"
        )
      ) {
        this.orderActiveStatus = true;
      }
    },

    async addUpdateLocalizedPost(addedPost) {
      for (let i = 0; i < this.formLocalizedPosts.length; i++) {
        this.formLocalizedPosts[i].post = addedPost;
        if (
          this.formLocalizedPosts[i].id === 0 ||
          this.formLocalizedPosts[i].id === null
        ) {
          await this.$store.dispatch(
            "post/addLocalizedPost",
            this.formLocalizedPosts[i]
          );
        } else {
          await this.$store.dispatch(
            "post/updateLocalizedPost",
            this.formLocalizedPosts[i]
          );
        }
      }
    },
    async loadLocalizedPostPerPostId() {
      await this.$store.dispatch("post/findPostById", this.postId);
      this.formItem = { ...this.selectedPost };
      await this.$store.dispatch("post/findLocalizedPostByPostId", this.postId);

      this.formLocalizedPosts = this.localizedPosts.map((item) => {
        return { ...item, language: this.getLanguageByLocale(item.locale) };
      });
      this.formLocalizedPosts = this.formLocalizedPosts.filter(
        (a) => a.language != undefined
      );
      const addedLanguages = [
        ...new Map(
          this.formLocalizedPosts.map((item) => [item["locale"], item.locale])
        ).values(),
      ];
      var missedLanguages = this.languages.filter(
        (item) => !addedLanguages.find((element) => element === item.locale)
      );
      if (missedLanguages?.length)
        this.constructLocalizedPostsWithLanguages(missedLanguages);
    },
    constructLocalizedPostsWithLanguages(languages) {
      for (let i = 0; i < languages?.length; i++) {
        let newLocalizedPost = new LocalizedPost({
          id: null,
          language: new Language(languages[i]),
          locale: languages[i].locale,
          title: "",
          shortDescription: "",
          fullDescription: "",
        });
        this.formLocalizedPosts.push(newLocalizedPost);
      }
    },
    reRoutePage(addedPost) {
      if (!this.postId) {
        this.$router.push({
          name: "SubPostEntry",
          params: {
            postId: this.rootPostId ?? addedPost.id,
            childTypeId: this.contentTypeId,
            childPostId: addedPost.id,
          },
        });
      }
    },
  },
  beforeRouteLeave(_to, _from, next) {
    // console.log("to, from", to, from);
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  beforeEnter() {
    // console.log("before enter", to, from, next);
  },

  watch: {
    $route: {
      handler: function() {
        this.$nextTick(this.routeLoaded);

        // this.beforeWindowUnload();
        // const fullPathOfChild = this.$router.history.current.fullPath;
        // console.log("previes path", fullPathOfChild);
        // if (this.confirmStayInDirtyForm()) {
        // 	this.form_dirty = false;
        // } else {
        // 	// Navigate to next view
        // 	this.form_dirty = true;
        // 	this.$router.fullPath = this.$router.history.current.fullPath;
      },
      deep: true,
      immediate: true,
    },
  },

  computedModel: {
    deep: true,
    handler: function(oldVal, val) {
      this.changePost(oldVal, val);
    },
  },
  computedLocalizedFormModel: {
    handler: function(oldVal, newVal) {
      this.changeLocalizedPost(oldVal, newVal);
    },
  },

  postId() {
    this.setDataToUI();
    // this.getPost();
  },
  contentTypeId() {
    this.setDataToUI();
  },
};
</script>

<style lang="scss" scoped>
.change-direction{
  direction: rtl;
}
.next-btn {
  display: flex;
  justify-content: flex-end;
}

.zero-padding {
  padding: 0;
}
.upload-file-error {
  color: #ff5252;
  line-height: 12px;
  font-size: 14px;
}
.max-upload-file-lable {
  opacity: 1.6;
  font-size: 13px;
  color: gray;
}
.btns-row {
  text-align: center;
  align-items: center;
}
.btns {
  display: inline-block;
}
.custom-button {
  border-radius: 3.125rem;
}
.v-card__text {
  padding-left: 0 !important;
}
</style>
