import ContentType from "./content-type";

export default class MenuCategory {
	constructor(rawData = {}) {
		this.id = rawData.id;
		this.name = rawData.name;
		this.contentTypeList = new ContentType(rawData.contentTypeList);
		this.menuCategory = rawData.menuCategory;
	}
}
