import ImageType from "./image-type";
import PostFileCaption from "./post-file-caption";

export default class PostFile {
  constructor(rawData = {}) {
    this.id = rawData.id;
    // this.post=new Post(rawData.post);
    this.postId = rawData.postId;
    this.fileName = rawData.fileName;
    this.fileOrder = rawData.fileOrder;
    this.fileUrl = rawData.fileUrl;
    // this.file = rawData.file;
    this.fileType = rawData.fileType;
    this.isActive = rawData.isActive;
    this.parentId = rawData.parentId;
    this.imageDeviceSize = rawData.imageDeviceSize;
    this.children = rawData.children;
    this.captionId = rawData.captionId;

    this.postFileCaption = rawData.postFileCaption? new PostFileCaption(rawData.postFileCaption):null;
    this.imageType = rawData.imageType
      ? new ImageType(rawData.imageType)
      : null;
    this.postFileImageType = rawData.postFileImageType;
  }
}
