import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("contentManagement", [
      "contentCategories",
      "contentTypes",
      "languages",
      "defaultLanguage",
    ]),
  },
  methods: {
    getContentTypeById(id) {
      return this.contentTypes.find((x) => x.id === id);
    },
    getContentCategoryById(id) {
      return this.contentCategories.find((x) => x.id === id);
    },
    getContentCategoryNameById(id) {
      return this.getContentCategoryById(id)?.localizations[this.getLocale()]
        ?.categoryName;
    },
    getLanguageByLocale(locale) {
      return this.languages.find((x) => x.locale === locale);
    },
    getContentCategoriesByIds(ids) {
      return this.contentCategories.filter((item) => !ids.includes(item.id));
    },
    getLocale() {
      return this.defaultLanguage.locale;
    },
  },
};
