import { ContentType } from "./index";

export default class Post {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.publishDate = rawData.publishDate;
    this.contentType = rawData.contentType
      ? new ContentType(rawData.contentType)
      : null;
    this.folderName = rawData.folderName;
    this.localizations = rawData.localizations;
    // this.localizedPosts = rawData.localizedPosts;
    this.postFiles = rawData.postFiles;
    this.postCategories = rawData.postCategories;
    this.parentId = rawData.parentId;
    this.rootParentId = rawData.rootParentId;
    this.isActive = rawData.isActive;
    this.creationDate = rawData.creationDate;
    this.postDate = rawData.postDate;
    this.isFeatured = rawData.isFeatured;
    this.orderNumber = rawData.orderNumber;
    this.isHero = rawData.isHero;
    this.isSeo = rawData.isSeo;
    this.code = rawData.code;
    this.nextId = rawData.nextId;
    this.previousId = rawData.previousId;
  }
}
