<template>
  <v-container>
    <v-expansion-panel-header>
      <v-row align="center" class="spacer" no-gutters>
        <v-col cols="4" sm="2" md="1">
          <v-avatar size="36px">
            <img
              alt="Avatar"
              src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
            />
          </v-avatar>
        </v-col>

        <v-col class="hidden-xs-only" sm="5" md="3">
          <strong v-html="commentsForTicket.username"></strong>
          <span
            class="grey--text"
            v-if="
              commentsForTicket.children &&
              commentsForTicket.children.length > 0
            "
          >
            &nbsp;({{ commentsForTicket.children.length }})
          </span>
        </v-col>
        <v-col class="grey--text text-truncate hidden-sm-and-down">
          &mdash;
          {{
            commentsForTicket.creationDate.substring(0, 16).replace("T", " ")
          }}
        </v-col>
        <v-card-text v-text="commentsForTicket.text"></v-card-text>
        <v-row>
          <v-col
            cols="3"
            v-for="(item, index) in commentsForTicket.commentFiles"
            :key="index"
          >
            <v-img max-height="100" max-width="250" :src="item.fileUrl" />
          </v-col>
        </v-row>
      </v-row>
    </v-expansion-panel-header>

    <v-expansion-panel-content
      class="replay"
      v-for="(item, index) in commentsForTicket.children"
      :key="index"
    >
      <v-divider></v-divider>
      <v-row align="center" class="spacer pt-3" no-gutters>
        <v-col cols="4" sm="2" md="1">
          <v-avatar size="36px">
            <img
              alt="Avatar"
              src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
            />
          </v-avatar>
        </v-col>
        <v-col class="hidden-xs-only" sm="5" md="3">
          <strong v-html="item.username"></strong>
        </v-col>
        <v-col class="grey--text text-truncate hidden-sm-and-down">
          &mdash;
          {{ item.creationDate.substring(0, 16).replace("T", " ") }}
        </v-col>
        <v-card-text>{{ item.text }}</v-card-text></v-row
      >
      <v-row>
        <v-col
          cols="3"
          v-for="(replay, index) in item.commentFiles"
          :key="index"
        >
          <v-img max-height="100" max-width="250" :src="replay.fileUrl" />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
    <div class="text-area-and-input-file">
      <v-textarea
        class="margin"
        filled
        rows="4"
        label="replay"
        v-model="replay"
      ></v-textarea>
      <v-file-input
        accept="image/*"
        class="input-file"
        v-model="files"
        multiple
      />
    </div>

    <div class="replay-div">
      <v-btn
        color="blue darken-1 white--text"
        class="custom-button"
        @click="addComment(commentsForTicket)"
      >
        Submit
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
   computed: {
    ...mapGetters("auth", ["clientName"]),
    ...mapGetters("ticket", ["tickets", "comments", "currentlyAddedComment"]),
  },
  data() {
    return {
      comment: "",
      replay: "",
      files: null,
      clientSelected: null,
      viewCommentsDialog: false,
      validForm: false,
    };
  },
  props: {
    commentsForTicket: [],
    editedComment: Object,
  },

  methods: {
    async addComment(comment) {
      let payload = {
        text: this.replay,
        username : this.clientName,
        ticket: { id: comment.ticketId },
        ticketId: comment.ticketId,
        comment: {
          id: comment.id,
        },
        parentId: comment.id,
      };
      // STORE -> CURRENT
      let response = await this.$store.dispatch("ticket/addComment", payload);
      if (this.files) {
        this.uploadFile(response);
      }
      this.replay = "";
    },
    uploadFile(replay) {
      for (let item in this.files) {
        const formData = new FormData();
        formData.append("file", this.files[item]);
        formData.append("commentId", replay.id);
        this.$store.dispatch("ticket/uploadImage",  formData);
      }
    },
  },
};
</script>
<style scoped>
.replay {
  margin-left: 5rem;
}
.margin {
  margin: 1rem;
}
.replay-div {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}
.custom-button {
  border-radius: 3.125rem;
}
.text-area-and-input-file {
  position: relative;
}
.input-file {
  position: absolute;
  bottom: 5%;
  right: 15px;
  border: none;
  padding: 0;
  margin: 0;
}
</style>
