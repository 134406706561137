export default {
  // Applications
  setTicket(state, payload) {
    state.tickets = payload.result;
  },
  setComment(state, payload) {
    state.comments = payload;
  },
  addComment(state, payload) {
    state.setCurrentlyAddedComment =payload;
    if (!payload.parentId) {
      state.comments.push(payload);
    } else {
      let parentCommentId = payload.parentId;
      let parentComment = state.comments.find((a) => a.id == parentCommentId);
      if (parentComment.children == null) {
        parentComment.children = [];
        parentComment.children.push(payload);
      } else {
        parentComment.children.push(payload);
      }
    }
    state.currentlyAddedComment = {...payload};
  },
  setCommentImage(state, payload) {
    if(!state.currentlyAddedComment.parentId)
    {
    let comment = state.comments.find((x) => x.id == payload.commentId);
    if (comment.commentFiles != null) {
      comment.commentFiles.push(payload);
    } else {
      comment.commentFiles = [];
      comment.commentFiles.push(payload);
    }
  }else{
    let comment = state.comments.find((x) => x.id == state.currentlyAddedComment.parentId);
    let childComment = comment.children.find(a => a.id == state.currentlyAddedComment.id);
    if (childComment.commentFiles != null) {
      childComment.commentFiles.push(payload);
    } else {
      childComment.commentFiles = [];
      childComment.commentFiles.push(payload);
    }

  }
  },
  addTicket(state,payload){
    state.tickets.push(payload);
  },
  setTicketById(state,payload){
    state.currentTicket = payload;
  }
};
