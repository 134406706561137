import Vue from "vue";
import router from "../../router";
import NetworkManager from "../../network/network-manager.js";
import Parser from "../auth/network/parser";

export default {
  login(context, payload) {
    context.commit("setUser", {
      username: payload.username,
      clientName: payload.clientName,
      roles: payload.roles,
      idToken: payload.idToken,
      accessToken: payload.accessToken,
      group: payload.group,
    });
  },
  logout(context) {
    router.push("/");
    document.cookie.split(";").forEach(function(c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    Vue.$keycloak.logout();
    localStorage.removeItem("tenantCode");
    context.commit("setUser", {
      username: null,
      clientName: null,
      roles: null,
      idToken: null,
      accessToken: null,
      group: null,
    });
  },
  async registerUser(context, payload) {
    return await NetworkManager.post(
      context,
      `/user`,
      payload,
      null,
      null,
      Parser
    );
  },
  async addGroupToUser(context, payload) {
    await NetworkManager.post(
      context,
      `/user/${payload.id}/group/${payload.groupName}`,
      null,
      null,
      null,
      Parser
    );
  },

  async addRolesToUser(context, payload) {
    await NetworkManager.put(
      context,
      `user/${payload.id}/roles`,
      payload.realmRoles,
      null,
      null,
      Parser
    );
  },
  async getAllUsersByGroupName(context, payload) {
    await NetworkManager.get(
      context,
      `security/users/groups?groupName=${payload}`,
      null,
      "setUsers",
      Parser
    );
  },
  async getUserByName(context, payload) {
    await NetworkManager.get(
      context,
      `security/users/${payload}`,
      null,
      "setUsers",
      Parser
    );
  },
  async getAllRoles(context) {
    await NetworkManager.get(context, `user/roles`, null, "setRoles", Parser);
  },
  async updateUserStatus(context , payload){
    await NetworkManager.put(
      context,
      `user/${payload.keycloakId}/status/${payload.enabled}`,
      null,
      null,
      null,
      Parser
    );
  }
};
