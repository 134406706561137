export default {
  setUser(state, payload) {
    state.user.username = payload.username;
    state.user.clientName = payload.clientName;
    state.user.roles = payload.roles;
    state.user.idToken = payload.idToken;
    state.user.accessToken = payload.accessToken;
    state.user.group = payload.group;
  },
  setUsers(state, payload) {
    state.users = payload;
  },
  setRoles(state, payload) {
    state.roles = payload;
  },
};
