import ContactUsListing from "../contactUs/views/pages/contactUsListing.vue"
import ContactUsColumns from "../contactUs/views/pages/ContactUsColumns.vue"


export const contactUsRouters = [
  {
    path: "/contact-us",
    name: "ContactUsListing",
    component: ContactUsListing,
  },
  {
    path: "/settings/contact-us-columns",
    name: "ContactUsColumns",
    component: ContactUsColumns,
  },
];
