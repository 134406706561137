<template>
	<v-card>
		<v-select
			class="selectBox"
			v-model="tenantCodeSelected"
			:items="tenants"
			item-text="tenantName"
			:item-value="tenants.tenantCode"
			@change="getWebsiteS3Data"
			label="Select"
			persistent-hint
			return-object
			single-line
		></v-select>
				<v-btn
					v-if="tenantCodeSelected && websiteS3.length == 0"
					color="primary"
					class="create-websiteS3 custom-button"
					to="/create-S3"
				>
					Create S3
				</v-btn>
		<v-data-table
			:headers="headers"
			:items="websiteS3"
			sort-by="name"
			class="elevation-1"
			v-if="websiteS3.length > 0"
			
		>
			
			<!--eslint-disable -->
			<template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<router-link
							:to="{ name: 'editWebsiteS3', params: { id: item.id } }"
						>
							<v-icon
								small
								class="mr-2"
								v-bind="attrs"
								v-on="on"
								@click="getItem(item)"
							>
								mdi-pencil
							</v-icon>
						</router-link>
					</template>
					<span>Edit</span>
				</v-tooltip>
			</template>
		</v-data-table>

		<v-snackbar>
			{{ snackbarText }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="pink"
					text
					v-bind="attrs"
					@click="snackbar = false"
					class="custom-button"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	title: "Tenants",
	data() {
		return {
			tenantCodeSelected: null,
			isLoading: true,
			snackbar: false,
			snackbarText: ``,
			confirmMSG: "",
			dialogDelete: false,
			isManageActiveStatus: false,
			headers: [
				{
					text: "Access Key",
					align: "start",
					value: "accessKey"
				},
				{ text: "Secret Key", value: "secretKey" },
				{ text: "S3 URL", value: "s3URL" },
				{ text: "Cdn URL", value: "cdnURL" },
				{ text: "Bucket Name", value: "bucketName" },
				{ text: "Region", value: "region" },
				{ text: "Actions", value: "actions", sortable: false }
			]
		};
	},
	computed: {
		...mapGetters("tenant", ["tenants", "tenantCode", "websiteS3"])
	},
	async created() {
		await this.loadTenants();
	},
	methods: {
		getItem(item) {
			this.$store.dispatch("tenant/editWebsiteS3", item);
		},
		async loadTenants() {
			await this.$store.dispatch("tenant/loadTenants");
		},
		async getWebsiteS3Data() {
			await this.loadWebsiteS3();
		},
		async loadWebsiteS3() {
			this.$store.commit(
				"tenant/setTenantCodeSelected",
				this.tenantCodeSelected.tenantCode
			);
			await this.$store.dispatch("tenant/loadWebsiteS3");
		}
	}
};
</script>
<style scoped>
.selectBox {
	padding: 2rem;
}
.create-websiteS3 {
	color: white;
	margin: 2rem;
}
.custom-button {
	border-radius: 3.125rem;
}
</style>
