// import { Post } from "../models";

export default {
  setPosts(state, payload) {
    state.posts = payload;
  },
  setLocalizedPosts(state, payload) {
    state.localizedPosts = payload;
  },
  setPageNumber(state, payload) {
    state.pageNumberForPost = payload.pageNumber;
  },
  setChildPageNumber(state, payload) {
    let index = state.childrenPagesNumber?.findIndex(
      (a) => a.contentTypeId == state.currentRoute.params.childTypeId
    );
    state.childrenPagesNumber.splice(index, 1);
    state.childrenPagesNumber.push({
      contentTypeId: state.currentRoute.params.childTypeId,
      pageNumber: payload.pageNumber,
    });
  },
  addLocalizedPost(state, payload) {
    if (state.localizedPosts?.length)
      state.localizedPosts = [...state.localizedPosts, payload];
    else state.localizedPosts = [payload];
  },
  updateLocalizedPost(state, payload) {
    state.localizedPosts = state.localizedPosts.map((localizedPost) => {
      if (localizedPost.id === payload.id) {
        return payload;
      }
      return localizedPost;
    });
  },
  setSelectedPost(state, payload) {
    state.selectedPost = payload;
    state.postFiles = payload?.postFiles;
  },
  setSelectedPostCategories(state, payload) {
    if (state.selectedPost) {
      state.selectedPost.postCategories = [...payload];
    }
  },
  setPostFiles(state, payload) {
    state.postFiles = payload;
  },
  addPostFile(state, payload) {
    if (state.postFiles?.length) {
      state.postFiles.push(payload);
    } else state.postFiles = [payload];
    state.currentlyAddedFile = payload;
  },
  updateFileStatus(state, payload) {
    let index = state.postFiles.findIndex((x) => x.id == payload.id);
    state.postFiles.splice(index, 1);
    state.postFiles = state.postFiles.map((postFile) => {
      if (postFile.id === payload.id) {
        return payload;
      }
      return postFile;
    });
  },
  setSelectContentType(state, payload) {
    state.selectContentType = payload;
  },
  setOrders(state, payload) {
    state.localizedPostsPaginated = payload;
  },
  setLocalizedPostsPaginated(state, payload) {
    state.localizedPostsPaginated = payload;
  },
  updatePostStatus(state, payload) {
    state.localizedPostsPaginated = {
      ...state.localizedPostsPaginated,
      result: state.localizedPostsPaginated.result.map((localizedPost) => {
        if (localizedPost.postId === payload.id) {
          return { ...localizedPost, post: { ...payload } };
        }
        return localizedPost;
      }),
    };
  },
  setParentPost(state, payload) {
    if (!payload.parentId) {
      state.parentPost = payload;
      payload;
      state.parentPostContentCategory = payload.postCategories?.map(
        (a) => a.contentCategoryId
      );
    }
  },
  updatePostsOrder(state, payload) {
    const tmpLocalizedPosts = state.localizedPostsPaginated.result.map(
      (localizedPost) => {
        const findPostById = payload.find((p) => p.id === localizedPost.postId);
        if (findPostById)
          return {
            ...localizedPost,
            post: {
              ...localizedPost.post,
              orderNumber: findPostById.orderNumber,
            },
          };
        return localizedPost;
      }
    );
    state.localizedPostsPaginated = {
      ...state.localizedPostsPaginated,
      result: [
        ...tmpLocalizedPosts.sort((a, b) =>
          a.post?.orderNumber && b.post?.orderNumber
            ? a.post.orderNumber - b.post.orderNumber
            : 0
        ),
      ],
    };
  },
  updateList(_, payload) {
    this.localizedPostsPaginated = payload;
  },
  deletePost(state, payload) {
    let index = state.localizedPostsPaginated.result.findIndex(
      (x) => x.postId === payload.id
    );
    if (index >= 0) {
      state.localizedPostsPaginated.result.splice(index, 1);
      state.localizedPostsPaginated = {
        ...state.localizedPostsPaginated,
      };
    }
  },
  setImageTypes(state, payload) {
    state.imageTypes = payload;
  },
  setEditedPostFileImageTypes(state, payload) {
    state.editedPostFileImageTypes = payload;
  },
  setUpdatePostFile(state, payload) {
    let object = state.postFiles.find((a) => a.id == payload.id);
    if (payload.fileUrl) object.fileUrl = payload.fileUrl;
    if (payload.fileName) object.fileName = payload.fileName;
  },
  addSelectImageType(state, payload) {
    let object = state.postFiles.find((a) => a.id == payload.id);
    if (payload.postFileImageType)
      object.postFileImageType = [...payload.postFileImageType];
  },
  addImageType(state, payload) {
    state.imageTypes.push(payload);
  },
  setLocalizedPostFileCaption(state, payload) {
    state.localizedPostFileCaption = payload;
  },
  setPostFileCaption(state, payload) {
    state.currentlyAddedPostFileCaption = payload;
  },

  setSizeConfiguration(state , payload){
    state.sizeConfiguration = payload;
  },
  setSizeConfigurationSelected(state,payload){
    state.sizeConfigurationSelected = payload;
  }
};
