import Application from "./application.js";
import Client from "./client.js"

export default class Ticket {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.title = rawData.title;
    this.description = rawData.description;
    this.application = rawData.clientApplication? new Application(rawData.clientApplication):null;
    this.client = rawData.client? new Client(rawData.client): null;
    this.state = rawData.state;
    this.creationDate = rawData.creationDate;
    this.lastModificationDate = rawData.lastModificationDate;
    this.markedAsDeleted = rawData.markedAsDeleted;
}
}