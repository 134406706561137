<template>
	<v-card>
		<v-card-title>
			<span class="text-h5">Add Language</span>
		</v-card-title>
		<v-card-text>
			<v-form v-model="valid" ref="form">
				<v-container>
					<v-row>
						<v-col cols="6" sm="6">
							<v-select
								:items="availableLanguages"
								label="Supported Languages"
								:rules="required"
								persistent-hint
								return-object
								single-line
								dense
								outlined
								:value="supportedLanguages"
								v-model="selectedLanguage"
							/>
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-spacer />
			<v-btn
				color="red darken-1"
				text
				@click="close"
				:disabled="loading"
				class="custom-button"
			>
				Cancel
			</v-btn>
			<v-btn
				color="blue darken-1"
				class="custom-button"
				text
				@click="addLanguage"
				:loading="loading"
				:disabled="!valid || loading"
			>
				Save
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "LanguageForm",
	data() {
		return {
			loading: false,
			selectedLanguage: null,
			valid: false,
			required: [(v) => !!v || "this field is required"]
		};
	},

	computed: {
		...mapGetters("contentManagement", ["supportedLanguages", "languages"]),
		availableLanguages() {
			return this.supportedLanguages
				.filter(
					(item) =>
						!this.languages.find((language) => language.locale === item.locale)
				)
				.map((item) => {
					return {
						...item,
						text: item.description
					};
				});
		}
	},
	async mounted() {
		await this.loadSupportedLanguages();
	},

	methods: {
		async loadSupportedLanguages() {
			await this.$store.dispatch("contentManagement/loadSupportedLanguages");
		},
		close() {
			this.error = this.selectedLanguage = null;
			this.$refs.form.resetValidation();
			this.$emit("close");
		},
		async addLanguage() {
			this.loading = true;
			await this.$store.dispatch(
				"contentManagement/addLanguage",
				this.selectedLanguage
			);
			await this.$store.dispatch("contentManagement/loadLanguages");
			this.loading = false;
			this.close();
		}
	}
};
</script>
<style scoped>
.custom-button {
	border-radius: 3.125rem;
}
</style>
