<template>
  <div class="container">
    <v-row>
      <v-col class="d-flex justify-center">
        <h1 class="secondary--text">Add S3</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-form v-model="WebsiteS3FormValid" ref="tenantForm">
          <h2>Website S3</h2>

          <v-text-field
            v-model="websiteS3.accessKey"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              Access Key
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="websiteS3.secretKey"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              Secret Key
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field v-model="websiteS3.s3URL" :rules="required" type="text">
            <template v-slot:label>
              S3URL
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="websiteS3.cdnURL"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              cdnURL
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="websiteS3.bucketName"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              Bucket Name
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="websiteS3.region"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              Region
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!isSaving" class="d-flex flex-row-reverse" cols="8">
        <v-btn
          color="primary"
          @click="createWebsiteS3"
          :disabled="!WebsiteS3FormValid"
        >
          Save
        </v-btn>
      </v-col>
      <v-col v-else class="d-flex flex-row-reverse" cols="8">
        <v-progress-circular
          indeterminate
          color="deep-orange lighten-2"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-snackbar v-model="showSnackbar" absolute>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay :value="isSaving"></v-overlay>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import WebsiteS3 from "../../models/WebsiteS3";
export default {
  title: "Add Tenant",
  data() {
    return {
      tenantCodeSelected: null,
      websiteS3: new WebsiteS3(),
      valid: false,
      isSaving: false,
      WebsiteS3FormValid: true,
      required: [(v) => !!v || "This field is required"],
      showSnackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    ...mapGetters("tenant", ["tenants", "tenantCode", "selectedWebsiteS3"]),
    ...mapGetters("auth", ["groups"]),
  },
  methods: {
    async createWebsiteS3() {
      try {
        if (!this.selectedWebsiteS3) {
          this.$store.commit("tenant/setTenantCodeSelected", this.groups);
          await this.$store.dispatch("tenant/addWebsiteS3", this.websiteS3);
          this.showSnackbar = true;
          this.snackbarText = "Data Added Successfully";
        } else {
          await this.$store.dispatch("tenant/editWebsiteS3", this.websiteS3);

          this.showSnackbar = true;
          this.snackbarText = "Data Updated Successfully";
        }
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      }
    },
  },
  watch: {
    selectedWebsiteS3() {
      this.websiteS3 = { ...this.selectedWebsiteS3 };
    },
  },
};
</script>
<style scoped>
.container {
  padding-left: 1rem;
}
</style>