import Vue from "vue";
import Vuex from "vuex";
import contentManagementModule from "../modules/settings/store/index.js";
import postModule from "../modules/posts/store/index.js";
import authModule from "../modules/auth/index.js";
import tenantModule from "../modules/tenant/store/index.js";
import pageModule from "../modules/page/store/index.js";
import contactUsModel from "../modules/contactUs/store/index.js";
import careersModel from "../modules/careers/store/index.js";
import ticketModule from "../modules/tickets/store/index.js";
import registerForm from "../modules/register-form/store/index.js"
import subscribe from "../modules/subscribe/store/index.js";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		contentManagement: contentManagementModule,
		post: postModule,
		auth: authModule,
		tenant: tenantModule,
		page: pageModule,
		contactUs: contactUsModel,
		careers: careersModel,
		ticket:ticketModule,
		registerForm:registerForm,
		subscribe:subscribe
	}
});
