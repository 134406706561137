<template>
  <Fragment>
    <div class="text-center" v-if="reRouteLoading">
      <v-progress-circular :size="70" :width="7" indeterminate />
    </div>
    <v-container fluid v-else>
      <!-- FILTERS -->
      <v-row justify-sm="end" align="center">
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-spacer />
        <v-col
          v-if="contentCategoryNames.length > 0 && !contentType.parentId"
          sm="4"
        >
          <v-select
            :items="contentCategoryNames"
            label="Select Category"
            persistent-hint
            return-object
            single-line
            dense
            outlined
            v-model="contentCategorySelected"
            @change="onCategoryFilterChange"
            :disabled="loading"
          />
        </v-col>
        <v-col sm="2" class="order-btn-position" v-if="featureActiveStatus">
          <v-checkbox
            @change="onIsFeaturedFilterChange"
            v-model="featured"
            label="Featured"
            color="primary"
            :disabled="loading"
          />
        </v-col>
        <v-col sm="3" class="order-btn-position" v-if="searchActiveStatus">
          <v-text-field
            v-model="searchText"
            @keyup.enter="SearchPosts"
            placeholder="search"
          ></v-text-field>
        </v-col>
        <v-col @click="clearSelectedPost">
          <!-- //hereeeeeeeeeee -->
          <!-- TODO: SHOW/HIDE DEPENDING ON CONTENT TYPE OR ROUTE -->
          <v-btn
            class="Uppercase custom-button"
            :to="getPostFormRoute(rootPostId, activeContentTypeId, postId)"
            :disabled="loading"
            color="primary"
            dark
          >
            <v-icon dark>mdi-plus</v-icon>
            New {{ contentType.menuTitle }}
          </v-btn>
        </v-col>
      </v-row>

      <!-- DATATABLE -->
      <v-row v-if="!loading">
        <v-col sm="12">
          <v-data-table
            hide-default-footer
            :headers="headers"
            :search="search"
            class="elevation-1 capitalize "
            :loading="loading"
            :items-per-page="pageSize"
            loading-text="Loading... Please wait"
            :class="{ ordered: orderActiveStatus == false }"
          >
            <!--eslint-disable -->
            <!-- Table Body -->
            <template v-slot:body>
              <draggable
                :list="localizedPosts"
                tag="tbody"
                @end="endOrderingPost"
                pointer-events:
                none;
              >
                <tr v-for="(item, index) in localizedPosts" :key="index">
                  <td>
                    <v-icon
                      v-if="orderActiveStatus"
                      small
                      class="page__grab-icon"
                    >
                      mdi-arrow-all
                    </v-icon>
                  </td>

                  <td v-if="contentType.titleConfig" class="text-cell">
                    {{ item.title }}
                  </td>
                  <td v-if="contentType.customTitleConfig1" class="text-cell">
                    {{ item.customTitle1 }}
                  </td>

                  <td v-if="contentType.customTitleConfig2" class="text-cell">
                    {{ item.customTitle2 }}
                  </td>

                  <td
                    v-if="contentType.shortDescriptionConfig"
                    class="text-cell"
                  >
                    {{ item.shortDescription }}
                  </td>
                  <td
                    v-if="contentType.fullDescriptionConfig"
                    class="text-cell"
                  >
                    {{ item.fullDescription }}
                  </td>

                  <td
                    v-if="contentType.customDescriptionConfig"
                    class="text-cell"
                  >
                    {{ item.customDescription }}
                  </td>

                  <td v-if="item.post.creationDate">
                    <template v-slote:item.post.creationDate="{ item }">
                      {{
                        item.post.creationDate
                          .substring(0, 16)
                          .replace("T", " ")
                      }}
                    </template>
                  </td>
                  <td v-else></td>
                  <td v-if="item.post.lastModificationDate">
                    <template v-slote:item.post.lastModificationDate="{ item }">
                      {{
                        item.post.lastModificationDate
                          .substring(0, 16)
                          .replace("T", " ")
                      }}
                    </template>
                  </td>
                  <td v-else></td>

                  <td class="center-icon">
                    <v-icon color="green" v-if="item.post.isActive">
                      mdi-cloud-check
                    </v-icon>
                    <v-icon color="red" v-else> mdi-cloud-off-outline </v-icon>
                  </td>
                  <td class="actions-wrapper not-ordered">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          small
                          v-on="on"
                          v-bind="attrs"
                          @click="deletePost(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          small
                          @click="editPost(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>

                    <v-tooltip v-if="item.post.isActive" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          @click="updatePostStatus(item, true)"
                          v-bind="attrs"
                          v-on="on"
                          color="red darken-4"
                        >
                          mdi-cancel
                        </v-icon>
                      </template>
                      <span>Deactivate</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          @click="updatePostStatus(item, false)"
                          v-bind="attrs"
                          v-on="on"
                          color="green darken-4"
                        >
                          mdi-cloud-upload
                        </v-icon>
                      </template>
                      <span>Publish</span>
                    </v-tooltip>
                  </td>
                </tr>
              </draggable>
            </template>

            <!-- TABLE ACTIONS -->
            <template v-slot:top>
              <v-dialog v-model="confirmationDialog" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    {{ confirmationMessage }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="red darken-1"
                      class="custom-button"
                      text
                      @click="closeConfirmationDialog"
                      :disabled="loading"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :disabled="loading"
                      color="blue darken-1 white--text"
                      class="custom-button"
                      @click="updatePostStatusConfirmed"
                    >
                      OK
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="confirmationDialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    {{ confirmationMessage }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="red darken-1"
                      class="custom-button"
                      text
                      @click="closeConfirmationDialog"
                      :disabled="loading"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :disabled="loading"
                      class="custom-button"
                      color="blue darken-1 white--text"
                      @click="deletePostConfirmed"
                    >
                      OK
                    </v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- Pagination -->
      <v-row
        class="justify-end mt-6 align-center"
        v-if="
          localizedPostsPaginated.totalNumberOfPages &&
            localizedPostsPaginated.totalNumberOfPages > 1
        "
      >
        <div class="mr-2">
          <v-select
            class="page-size-selector"
            label="page size"
            outlined
            dense
            hide-details
            :items="pageSizeOptions"
            :value="10"
            @change="pageSize = parseInt($event, 10)"
          />
        </div>
        <div class="mr-2" v-if="localizedPostsPaginated.totalNumberOfPages">
          <span>{{ pageNumber }}</span>
          <span class="mx-1">From</span>
          <span>{{ localizedPostsPaginated.totalNumberOfPages }}</span>
        </div>
        <v-pagination
          v-if="localizedPostsPaginated.totalNumberOfPages"
          class="align-self-start py-2"
          v-model="pageNumber"
          :length="localizedPostsPaginated.totalNumberOfPages"
        />
      </v-row>

      <!-- SNACKBAR -->
      <v-snackbar v-model="snackbar">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
            class="custom-button"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </Fragment>
</template>

<script>
import { mapGetters } from "vuex";
import { LocalizedPost } from "../models/index";
import urlHelper from "../../../mixins/url-helper";
import draggable from "vuedraggable";
import { Fragment } from "vue-fragment";
import settingsMixin from "../../settings/mixins/settings";

export default {
  name: "PostsListing",
  mixins: [urlHelper, settingsMixin],

  data: () => ({
    searchText: null,
    featureActiveStatus: false,
    orderActiveStatus: false,
    heroActiveStatus: false,
    searchActiveStatus: false,
    featured: false,
    contentCategorySelected: null,
    toBeDeletePost: null,
    findContentCategory: [],
    selectedCategory: null,
    contentCategoryNames: [],
    attrbDragable: false,
    confirmationDialog: false,
    confirmationDialogDelete: false,
    isManageActiveStatus: false,
    postCategories: [],
    contentCategoryIds: [],
    search: "",
    drag: false,
    loading: false,
    orderLoading: false,
    confirmationMessage: "",
    headers: [],
    startHeaders: [{ text: "", sortable: false }],
    endHeaders: [
      { text: "Created", value: "post.creationDate", sortable: false },
      {
        text: "Modified",
        value: "post.lastModificationDate",
        sortable: false,
      },
      {
        text: "Published",
        align: "center",
        value: "post.isActive",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedLocalizedPost: new LocalizedPost(),
    snackbar: false,
    snackbarText: ``,
    pageNumber: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    reRouteLoading: false,
    localizedPosts: [],
  }),

  computed: {
    activeContentTypeId() {
      return this.$route.name === "ContentTypePosts"
        ? this.rootContentTypeId
        : this.contentTypeId;
    },
    contentType() {
      return this.contentTypes.find((x) => x.id == this.activeContentTypeId);
    },
    isChildContentType(){
     return this.$route.name === "ChildContentTypePosts" ;
    },
    ...mapGetters("contentManagement", [
      "contentTypes",
      "contentCategories",
      "optionalFeatures",
    ]),
    ...mapGetters("post", [
      "localizedPostsPaginated",
      "selectedPost",
      "pageNumberForPost",
      "childrenPagesNumber",
    ]),
  },
  components: {
    draggable,
    Fragment,
  },

  async mounted() {
    await this.setDataToUI();
  },

  methods: {
    // RESET UI
    async setDataToUI() {
      await this.setTableHeaders();
      await this.resetUI();
      await this.reRouteToPostForm();
      await this.loadPosts();
      this.getContentType();
      this.optionalFeatureStatus(this.contentType);
    },
    resetUI() {
      this.featured = false;
      this.$store.commit("post/setLocalizedPostsPaginated", { result: [] });
      if (this.$route.name === "ChildContentTypePosts" && this.rootPostId) {
        let child = this.childrenPagesNumber?.find(
          (a) => a.contentTypeId == this.$route.params.childTypeId
        );
        this.pageNumber = child ? child.pageNumber : 1;
      } else {
        this.pageNumber = this.pageNumberForPost
          ? parseInt(this.pageNumberForPost)
          : 1;
      }
      this.pageSize = 10;
    },
    clearSelectedPost() {
      this.$store.commit("post/setSelectedPost", null);
    },

    // GETTERS & LOAD POSTS
    async getContentType() {
      this.loading = true;
      const contentTypeIdFromRoute = this.$route.params.id;
      let contentTypeObject = this.contentTypes.find(
        (item) => item.id == contentTypeIdFromRoute
      );
      let contentTypeCategoryId = [];
      contentTypeObject.contentTypeCategories.map((item) =>
        contentTypeCategoryId.push(item.contentCategoryId)
      );
     
      this.getContentCategoryChildren(contentTypeCategoryId);

      this.loading = false;

    },
    getContentCategoryChildren(contentCategoriesIds){
      this.findContentCategory = [];
      for(let item in contentCategoriesIds){
        this.findContentCategory.push(this.contentCategories.filter(
          a => a.parentId == contentCategoriesIds[item] 
          && a.isActive == true 
          && a.localizations[this.getLocale()]?.categoryName != undefined
          && a.markedAsDeleted == false));
      }
      this.getContentCategoryNames(this.findContentCategory.flat())


    },
    getContentCategoryNames(contentCategories) {
      this.contentCategoryNames = contentCategories.length >0 ? ["All"]:[];
    for(let item in contentCategories )
    {
      this.contentCategoryNames.push(contentCategories[item].localizations[this.getLocale()]?.categoryName)
    }
    
    },
    async loadPosts() {
      this.contentCategorySelected = null;
      // this.contentCategoryNames = [];
      this.loading = true;

      if (this.$route.name === "ContentTypePosts") {
        await this.$store.dispatch(
          "post/loadLocalizedPostsByContentTypeIdAndLanguage",
          {
            id: this.rootContentTypeId,
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
          }
        );
      } else if (
        this.$route.name === "ChildContentTypePosts" &&
        this.rootPostId
      ) {
        let payLoad = {
          rootPostId: this.rootPostId,
          contentTypeId: this.contentTypeId,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
        };
        await this.$store.dispatch(
          "post/loadLocalizedPostsByRootPostAndContentTypeIdAndLanguage",

          payLoad
        );
      }
      this.loading = false;
    },

    //Search
    async SearchPosts() {
      if (this.searchText) {
        this.$store.dispatch(
          "post/loadLocalizedPostsByContentTypeIdAndLanguageAndSearch",
          {
            id: this.isChildContentType? this.$route.params.childTypeId: this.rootContentTypeId,
            searchText: this.searchText,
          }
        );
      } else this.setDataToUI();
    },

    // FILTER DATA
    async onIsFeaturedFilterChange() {
      this.contentCategorySelected = null;
      let criteria = {
        isFeatured: this.featured,
      };
      await this.$store.dispatch(
        "post/loadLocalizedPostsByContentTypeIdAndLanguage",
        {
          id: this.rootContentTypeId,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          criteria: this.featured == true ? criteria : null,
        }
      );
    },
    async onCategoryFilterChange(item) {
      this.featured = false;
      if (item === "All") {
        await this.setDataToUI();
        return;
      }
      const contentCategory = this.findContentCategory.flat().find(
        (a) =>
          a.localizations[this.getLocale()]?.categoryName ==
          this.contentCategorySelected
      );
      await this.filterPostsByContentCategory(contentCategory);
    },
    async filterPostsByContentCategory(contentCategory) {
      this.loading = true;
      let contentTypeId = this.$route.params.id;
      let contentCategoryId = contentCategory.id;

      await this.$store.dispatch("post/filterPosts", {
        contentTypeId: contentTypeId,
        contentCategoryId: contentCategoryId,
        pageSize: this.pageSize,
        pageNumber: 1,
      });
      this.loading = false;
    },

    // UPDATE ORDERING
    async endOrderingPost(event) {
      try {
        if (this.orderLoading) return;
        this.orderLoading = true;
        const oldIndex = event?.oldIndex;
        const newIndex = event?.newIndex;
        if (oldIndex !== newIndex) {
          const payload = {
            postId: this.localizedPosts[newIndex]?.postId,
            orderNumber: this.localizedPosts[
              newIndex > oldIndex ? newIndex - 1 : newIndex + 1
            ].post.orderNumber,
          };
          await this.$store.dispatch("post/updateOrder", payload);
          this.snackbarText = "Posts order updated successfully";
        }
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.orderLoading = false;
        this.snackbar = true;
      }
    },
    optionalFeatureStatus(contentType) {
      this.featureActiveStatus = this.searchActiveStatus = this.heroActiveStatus = this.orderActiveStatus = false;
      if (
        contentType.optionalFeatureContentTypes.find(
          (a) => a.optionalFeature.name == "isFeatured"
        )
      ) {
        this.featureActiveStatus = true;
      }
      if (
        contentType.optionalFeatureContentTypes.find(
          (a) => a.optionalFeature.name == "isHero"
        )
      ) {
        this.heroActiveStatus = true;
      }
      if (
        contentType.optionalFeatureContentTypes.find(
          (a) => a.optionalFeature.name == "Order"
        )
      ) {
        this.orderActiveStatus = true;
      }
      if (
        contentType.optionalFeatureContentTypes.find(
          (a) => a.optionalFeature.name == "hasSearch"
        )
      ) {
        this.searchActiveStatus = true;
      }
    },

    // UPDATE POST STATUS
    updatePostStatus(item, isRemove) {
      this.confirmationMessage = isRemove
        ? "Are you sure you want to unpublished this post?"
        : "Are you sure you want to publish this post";
      this.editedLocalizedPost = Object.assign({}, item);
      this.confirmationDialog = true;
    },
    async updatePostStatusConfirmed() {
      try {
        this.isManageActiveStatus = true;
        const payLoad = {
          id: this.editedLocalizedPost.post.id,
          isActive: !this.editedLocalizedPost.post.isActive,
        };
        await this.$store.dispatch("post/updatePostActiveStatus", payLoad);
        this.snackbarText = this.editedLocalizedPost.post.isActive
          ? "Post unpublished successfully"
          : "Post published successfully";
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.closeConfirmationDialog();
        this.isManageActiveStatus = false;
        this.snackbar = true;
      }
    },
    closeConfirmationDialog() {
      this.confirmationDialog = false;
      this.confirmationDialogDelete = false;
      this.$nextTick(() => {
        this.editedLocalizedPost = Object.assign({}, new LocalizedPost());
        this.toBeDeletePost = null;
      });
    },

    // DELETE POST
    deletePost(item) {
      this.confirmationMessage = "Are you sure you want to delete this post?";
      this.toBeDeletePost = item;
      this.confirmationDialogDelete = true;
    },
    async deletePostConfirmed() {
      try {
        this.snackbar = true;
        await this.$store.dispatch("post/deletedPost", this.toBeDeletePost);
        this.closeConfirmationDialog();
        this.snackbarText = "Post Deleted successfully";
      } catch (error) {
        this.snackbarText = error;
      }
    },

    // RE-ROUTING
    async reRouteToPostForm() {
      try {
        this.reRouteLoading = true;
        this.loading = true;

        if (
          this.$route.name === "ChildContentTypePosts" &&
          this.contentType.noOfAllowedPosts === 1
        ) {
          await this.$store.dispatch(
            "post/findPostByRootParentIdAndContentTypeId",
            {
              rootParentId: this.rootPostId,
              contentTypeId: this.activeContentTypeId,
            }
          );
          this.$router.push(
            this.getPostFormRoute(
              this.rootPostId,
              this.contentTypeId,
              this.selectedPost?.id
            )
          );
        }
      } finally {
        this.reRouteLoading = false;
        this.loading = false;
      }
    },
    editPost(localizedPost) {
      this.$router.push(
        this.getPostFormRoute(
          localizedPost.post.rootParentId || localizedPost.post.id,
          this.activeContentTypeId,
          localizedPost.post.id
        )
      );
    },

    setTableHeaders() {
      if (this.contentType) {
        let headers = [];
        const initialHeader = {
          align: "start",
          cellClass: "text-cell",
          sortable: false,
        };
        if (this.contentType?.titleConfig)
          headers.push({
            ...initialHeader,
            text: this.contentType.titleConfig,
            value: "title",
          });
        if (this.contentType?.customTitleConfig1)
          headers.push({
            ...initialHeader,
            text: this.contentType.customTitleConfig1,
            value: "customTitle1",
          });

        if (this.contentType?.customTitleConfig2)
          headers.push({
            ...initialHeader,
            text: this.contentType.customTitleConfig2,
            value: "customTitle2",
          });

        if (this.contentType?.shortDescriptionConfig)
          headers.push({
            ...initialHeader,
            text: this.contentType.shortDescriptionConfig,
            value: "shortDescription",
          });

        if (this.contentType?.fullDescriptionConfig)
          headers.push({
            ...initialHeader,
            text: this.contentType.fullDescriptionConfig,
            value: "fullDescription",
          });
        if (this.contentType?.customDescriptionConfig)
          headers.push({
            ...initialHeader,
            text: this.contentType.customDescriptionConfig,
            value: "customDescription",
          });
        this.headers = [...this.startHeaders, ...headers, ...this.endHeaders];
      }
    },
  },

  watch: {
    confirmationDialog(val) {
      val || this.closeConfirmationDialog();
    },
    async rootContentTypeId() {
      await this.setDataToUI();
    },
    async contentTypeId() {
      await this.setDataToUI();
      await this.getContentType();

    },
    async pageNumber() {
      await this.loadPosts();
      await this.getContentType();
    },
    async pageSize() {
      if (this.pageSize < 1 || this.pageSize > 20) {
        this.pageSize = 10;
      }
      await this.loadPosts();
      await this.getContentType();
    },
    async contentCategories() {
      await this.setDataToUI();
      this.getContentType();
    },
    localizedPostsPaginated() {
      this.localizedPosts = this.localizedPostsPaginated.result
        ? [...this.localizedPostsPaginated.result]
        : [...this.localizedPostsPaginated];
    },
  },
};
</script>

<style lang="scss">
.capitalize {
  text-transform: capitalize;
}
// .ordered {
// 	pointer-events: none;
// }
.not-ordered {
  pointer-events: visible;
}
.text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.center-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-size-selector {
  width: 75px;
}
.order-btn-position {
  display: flex;
  margin-right: 2rem;
}
.actions-wrapper {
  align-items: center;
  min-width: 100px;
}
.custom-button {
  border-radius: 3.125rem;
}
.v-data-table {
  font-size: large;
  color: black;
}
</style>
