import NetworkManager from "../../../network/network-manager";
import Parser from "../network/parser.js";

export default {
  async loadSubscribes(context) {
    await NetworkManager.get(
      context,
      `subscribe`,
      "Subscribe",
      "setSubscribes",
      Parser
    );
  },
  async deleteItem(context, payload) {
    await NetworkManager.put(
      context,
      `subscribe/marked-as-deleted/${payload.id}`,
      payload,
      null,
      "deleteItem",
      Parser
    );
  },
}
