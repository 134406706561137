// import { Post } from "../models";

export default {
  setTenant(state, payload) {
    state.tenants = payload;
  },
  setCurrentTenant(state,payload){
    state.tenant = payload;
  },

  setTenantCodeSelected(state, payload) {
    console.log("payloaad" , payload)
    state.tenantCodeSelected = payload;
  },
  setWebsiteS3(state, payload) {
    state.websiteS3 = payload;
  },
  editWebsiteS3(state, payload) {
    state.websiteS3 = payload;
    state.selectedWebsiteS3 = payload;
  },
  setMailSender(state, payload) {
    state.mailSender = payload;
    state.selectedmailSender = payload;
  }


};
