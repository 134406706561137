<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="imageTypeHeader"
          :items="imageTypes"
          sort-by="name"
          class="capitalize "
        >
          <!--eslint-disable -->
          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="imageTypeDialog" max-width="850px">
                <template v-slot:activator="{ on, attrs }">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2 custom-button"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>mdi-plus</v-icon>

                    New Image Type
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Add Image Type</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form v-model="valid" ref="contentTypeForm">
                      <v-container>
                        <v-row>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedImageType.name"
                              label="name"
                              :rules="required"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="red darken-1"
                      class="custom-button"
                      text
                      @click="closeaddImageTypeDialog"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="blue darken-1 white--text"
                      class="custom-button"
                      @click="submitData"
                      :disabled="!valid || addImageTypeLoading"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="editImageType(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import PageCategory from "../../../page/models/page-category";
import { ImageType } from "../../../posts/models";

export default {
  data() {
    return {
      editedImageType: new ImageType(),
      imageTypeDialog: false,
      addImageTypeLoading: false,
      confirmationMessage: "",
      confirmationDialogDelete: false,
      toBeDeletePageCategory: null,
      pageCategoryDialog: false,
      selectedPages: [],
      selectPagesObjects: [],
      pageCategoryId: null,
      loading: false,
      valid: false,
      contentTypeDialog: false,
      editedPageCategory: new PageCategory(),
      defaultPageCategory: new PageCategory(),
      required: [(v) => !!v || "this field is required"],
      addPageCategoryLoading: false,
      imageTypeHeader: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Creation Date",
          align: "start",
          sortable: false,
          value: "creationDate",
        },

        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters("post", ["imageTypes"]),
    getPageName() {
      return this.pages.map((item) => item.name);
    },
  },

  methods: {
    closeaddImageTypeDialog() {
      this.imageTypeDialog = false;
      this.editedImageType = Object.assign({}, new ImageType());
      this.editedIndex = -1;
    },
    async submitData() {
      if (this.editedImageType?.id) {
        await this.$store.dispatch(
          "post/updateImageType",
          this.editedImageType
        );
      } else {
        let payload = {
          name: this.editedImageType.name,
        };
        await this.$store.dispatch("post/addImageType", payload);
      }
      this.closeaddImageTypeDialog();
    },

    closeConfirmationDialog() {
      this.confirmationDialogDelete = false;
    },
    editImageType(item) {
      this.imageTypeDialog = true;
      this.editedImageType = item;
    },
  },
};
</script>
<style>
.custom-button {
  border-radius: 3.125rem;
}
.v-data-table {
  font-size: large;
  color: black;
}
</style>
