<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" sm="6">
        <v-select
          label="Select Parent"
          persistent-hint
          return-object
          single-line
          dense
          outlined
          item-text="type"
          :items="contentTypeRoots"
          v-model="contentTypeSelected"
          @change="children"
        />
      </v-col>
      <v-data-table
        :headers="contetTypesHeaders"
        hide-default-footer        
        class="elevation-1 capitalize "
      >
        <!--eslint-disable -->

        <template v-slot:body>
          <draggable :list="contentTypeChildren" @end="endOrderingContentType" tag="tbody" pointer-events: none;>
            <tr v-for="(item, index) in contentTypeChildren" :key="index">
              <td>
                <v-icon small class="page__grab-icon">
                  mdi-arrow-all
                </v-icon>
              </td>
              <td>
                {{ item.type }}
              </td>

              <td v-if="item.contentTypeCategories.length">
                <template v-slote:item.contentTypeCategories="{ item }">
                  <span
                    v-for="(contentTypeCategory,
                    index) in item.contentTypeCategories"
                    :key="index"
                  >
                    -
                    {{
                      getContentCategoryNameById(
                        contentTypeCategory.contentCategoryId
                      )
                    }}
                    <br />
                  </span>
                </template>
              </td>
              <td v-else></td>
              <td>
                <span v-if="item.titleConfig">{{ item.titleConfig }}</span>
              </td>
              <td>
                <span v-if="item.customTitleConfig1">{{
                  item.customTitleConfig1
                }}</span>
              </td>
              <td>
                <span v-if="item.customTitleConfig2">{{
                  item.customTitleConfig2
                }}</span>
              </td>
              <td>
                <span v-if="item.shortDescriptionConfig">{{
                  item.shortDescriptionConfig
                }}</span>
              </td>
              <td>
                <span v-if="item.fullDescriptionConfig">{{
                  item.fullDescriptionConfig
                }}</span>
              </td>
              <td>
                <span v-if="item.customDescriptionConfig">{{
                  item.customDescriptionConfig
                }}</span>
              </td>
              <td>
                <span v-if="item.htmlDescriptionConfig">{{
                  item.htmlDescriptionConfig
                }}</span>
              </td>
              <td>
                <span v-if="item.postCodeConfig">{{
                  item.postCodeConfig
                }}</span>
              </td>
              <td>
                <span v-if="item.noOfAllowedPosts">{{
                  item.noOfAllowedPosts
                }}</span>
              </td>
              <td>
                <span v-if="item.maxUploadFiles">{{
                  item.maxUploadFiles
                }}</span>
              </td>
              <td>
                <span v-if="item.maxUploadImages">{{
                  item.maxUploadImages
                }}</span>
              </td>
              <td>
                <span v-if="item.postCodeConfig">{{
                  item.postCodeConfig
                }}</span>
              </td>
              <td>
                <template v-slote:item.isActive="{ item }">
                  <v-icon color="green" v-if="item.isActive">
                    mdi-checkbox-marked-circle
                  </v-icon>
                  <v-icon color="red" v-else> mdi-close-circle </v-icon>
                  
                </template>
              </td>
              <td>
                
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import settingsMixin from "../../mixins/settings";
import draggable from "vuedraggable";

export default {
  mixins: [settingsMixin],
  data() {
    return {
      contentTypeSelected: null,
      contentTypeChildren:[],
       orderLoading :false,
      contetTypesHeaders: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "selected",
          width: "150px",
        },
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "menuTitle",
          width: "150px",
        },
        {
          text: "Categories",
          align: "start",
          sortable: false,
          value: "contentTypeCategories",
          width: "150px",
        },
        {
          text: "Title Config",
          align: "start",
          sortable: false,
          value: "titleConfig",
        },
        {
          text: "Custom Title Config1",
          align: "start",
          sortable: false,
          value: "customTitleConfig1",
        },
        {
          text: "Custom Title Config2",
          align: "start",
          sortable: false,
          value: "customTitleConfig2",
        },
        {
          text: "Short desc. Config",
          align: "start",
          sortable: false,
          value: "shortDescriptionConfig",
        },
        {
          text: "Full desc. config",
          align: "start",
          sortable: false,
          value: "fullDescriptionConfig",
        },
        {
          text: "Custom desc. Config",
          align: "start",
          sortable: false,
          value: "customDescriptionConfig",
        },
        {
          text: "HTML desc. Config",
          align: "start",
          sortable: false,
          value: "htmlDescriptionConfig",
        },
        {
          text: "Post date. config",
          align: "start",
          sortable: false,
          value: "postDateConfig",
        },
        {
          text: "Allowed Posts No.",
          align: "center",
          sortable: false,
          value: "noOfAllowedPosts",
        },
        {
          text: "Max Upload Files",
          align: "center",
          sortable: false,
          value: "maxUploadFiles",
        },
        {
          text: "Max Upload Images",
          align: "center",
          sortable: false,
          value: "maxUploadImages",
        },
        {
          text: "Post Code",
          align: "center",
          sortable: false,
          value: "postCodeConfig",
        },

        {
          text: "Active",
          align: "center",
          sortable: false,
          value: "isActive",
        },
       
      ],
    };
  },
  computed: {
    ...mapGetters("contentManagement", ["contentTypes"]),
    contentTypeRoots() {
      return this.contentTypes.filter((a) => !a.parentId);
    },
  
  },
  methods:{
  async  endOrderingContentType(event){
        try {
        if (this.orderLoading) return;
        this.orderLoading = true;
        const oldIndex = event?.oldIndex;
        const newIndex = event?.newIndex;
        if (oldIndex !== newIndex) {
          const payload = {
            contentTypeId: this.contentTypeChildren[newIndex]?.id,
            orderNumber: newIndex +1
          }
          
          await this.$store.dispatch("contentManagement/updateOrder", payload);
          this.snackbarText = "content type order updated successfully";
        }
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.orderLoading = false;
        this.snackbar = true;
      }
    },
    async loadContentTypes() {
      await this.$store.dispatch("contentManagement/loadContentTypes");
    },
   async children() {
     this.contentTypeChildren = this.contentTypeSelected? this.contentTypeSelected.children : null;
     let childrenWithNullOrder= this.contentTypeChildren.filter(a => !a.orderNumber);
     if(childrenWithNullOrder.length)
     {
    await  this.$store.dispatch("contentManagement/updateOrderFirstTime" , childrenWithNullOrder);
      await this.loadContentTypes()
      this.contentTypeChildren = this.contentTypes.find(a=> a.id ==this.contentTypeSelected.id).children;
     }
      return this.contentTypeChildren;
    },
  },
  components: { draggable },
};
</script>
