<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="contentCategoryHeaders"
          :items="contentCategories"
          hide-default-footer
          sort-by="name"
          group-by="parentId"
          :items-per-page="-1"
          :search="search"
          class="capitalize "
        >
          <!--eslint-disable -->
          <template v-slot:group.header="{ groupBy, group, isOpen, toggle }">
            <td :colspan="contentCategoryHeaders.length">
              <v-icon @click="toggle">
                {{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              <span v-if="group">
                {{ getContentCategoryNameById(group) }}
              </span>
              <span v-else><b>Root</b></span>
            </td>
          </template>

          <template v-slot:item.localizations="{ item }">
            {{ getContentCategoryNameById(item.id) }}
          </template>

          <template v-slot:item.isActive="{ item }">
            <v-icon color="green" v-if="item.isActive">
              mdi-checkbox-marked-circle
            </v-icon>
            <v-icon color="red" v-else> mdi-close-circle </v-icon>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="contentCategoryDialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  <v-spacer></v-spacer>

                  <v-btn
                    color="primary"
                    dark
                    class="mb-2 custom-button"
                    v-bind="attrs"
                    v-on="on"
                    @click="addNewContentCategory(null, null)"
                  >
                    <v-icon dark>mdi-plus</v-icon>

                    New Category
                  </v-btn>
                  <v-card-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                </template>

                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-tabs v-model="tab" align-with-title>
                    <v-tabs-slider color="black"></v-tabs-slider>

                    <v-tab v-for="(tab, index) in tabs" :key="tab + index">
                      {{ tab }}
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab" align-with-title>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-form v-model="valid" ref="form">
                            <v-checkbox
                              label="hasRelation"
                              v-model="hasRelation"
                              v-if="!hasRelationHidden"
                            />
                            <v-select
                              v-if="hasRelation"
                              :items="getRootNames"
                              v-model="relatedTo"
                              label="Content Category"
                              persistent-hint
                              return-object
                              single-line
                              dense
                              outlined
                            ></v-select>
                            <v-select
                              v-if="
                                hasRelationHidden &&
                                  relatedToChildren.length > 0
                              "
                              :items="getRelatedToChildren"
                              v-model="relatedTo"
                              label="Content Category"
                              persistent-hint
                              return-object
                              single-line
                              dense
                              outlined
                            ></v-select>
                            <v-text-field
                              v-if="editedIndex === -1"
                              label="Category Name"
                              v-model="categoryName"
                              :rules="required"
                            />

                            <!-- <div class="row">
                              <div class="col-9">
                                <v-file-input
                                  v-model="contentCategoryImg"
                                  :rules="maxSizeRule"
                                  accept="image/*"
                                  prepend-icon="mdi-camera"
                                  label="upload img"
                                />
                              </div>
                              <div class="col-3 img-category">
                                <v-img
                                  height="70"
                                  max-width="70"
                                  :src="imgUrl"
                                />
                              </div>
                            </div> -->
                            <v-tabs
                              class="zero-padding"
                              v-model="contentCategoryTab"
                              background-color="grey darken-3"
                              dark
                            >
                              <v-tabs-slider color="blue darken-6" />
                              <v-tab
                                v-for="(item, i) in formLocalizedCategories"
                                :key="i"
                              >
                                <v-icon x-small>mdi-file-document-box</v-icon>
                                {{
                                  getLanguageByLocale(item.locale).description
                                }}
                              </v-tab>
                            </v-tabs>
                            <v-tabs-items
                              class="zero-padding"
                              v-model="contentCategoryTab"
                            >
                              <v-tab-item
                                v-for="(item, i) in formLocalizedCategories"
                                :key="i"
                              >
                                <v-card flat>
                                  <v-card-text>
                                    <v-form v-model="valid" ref="form">
                                      <v-row>
                                        <v-col cols="6" sm="6">
                                          <v-text-field
                                            label="Category Name"
                                            v-model="item.categoryName"
                                            :rules="required"
                                          />
                                        </v-col>
                                        <v-col cols="6" sm="6">
                                          <v-text-field
                                            label="Description"
                                            v-model="item.description"
                                          />
                                        </v-col>
                                      </v-row>
                                    </v-form>
                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                            </v-tabs-items>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item class="images-style">
                      <div class="col-9">
                        <v-col cols="col-12">
                          <v-select
                            v-model="types"
                            :items="imageTypes"
                            item-text="name"
                            class="list"
                            label="Select Image Type"
                            multiple
                            return-object
                            chips
                          />
                          <span v-if="types.length == 0" class="red--text">
                        You must select at least one
                      </span>
                        </v-col>

                        <v-file-input
                          v-model="contentCategoryImage"
                          :rules="maxSizeRule"
                          accept="image/*"
                          prepend-icon="mdi-camera"
                          label="upload img"
                        />
                      </div>

                      <v-row v-if="editedCategory">
                        <v-col
                          class="content-category-images"
                          cols="3"
                          v-for="(img, index) in getContentCategoriesFiles(
                            editedCategory
                      )"
                          :key="img + index"
                        >
                          <v-img height="70" max-width="70" :src="img.url" />
                          <span v-if="img.types"
                            >{{ getImageTypesNames(img.types)}}
                          </span>
                          <div>
                            <v-icon small @click="editTypes(img)" class="mr-2">
                          mdi-pencil
                        </v-icon>
                            <v-btn
                              color="danger"
                              dark
                              class="mb-6 custom-button mt-4"
                              @click="deleteImage(img.id)"
                            >
                              Delete
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="red darken-1"
                      class="custom-button"
                      text
                      @click="closeContentCategoryDialog"
                      :disabled="addLoading"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1 white--text"
                      class="custom-button"
                      @click="submitData"
                      :disabled="
                        !valid ||
                          addLoading ||
                          (hasRelation && !relatedTo) ||
                          (relatedToChildren.length > 0 && !relatedTo) ||
                          (contentCategoryImage && types.length==0)
                      "
                      :loading="addLoading"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>

            <v-dialog
              v-model="changeContentCategoryStatusDialog"
              max-width="500px"
            >
              <v-card>
                <v-card-title class="text-h5">
                  Are you sure you want to change status of this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red darken-1"
                    class="custom-button"
                    text
                    @click="closeChangeStatusDialog"
                    :disabled="changeStatusLoading"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="changeStatusLoading"
                    :loading="changeStatusLoading"
                    color="blue darken-1 white--text"
                    class="custom-button"
                    @click="changeStatusConfirmed"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip
              bottom
              v-if="item.parentId === null || item.parentId === undefined"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="addNewContentCategory(item.id, item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-plus-network
                </v-icon>
              </template>
              <span>Add child</span>
            </v-tooltip>
            <v-icon small @click="editContentCategory(item)" class="ml-1">
              mdi-pencil
            </v-icon>
            <v-icon
              small
              v-if="
                !item.isActive &&
                  (!item.parentId ||
                    getContentCategoryById(item.parentId).isActive)
              "
              @click="changeActiveStatus(item)"
              class="ml-1"
            >
              mdi-check
            </v-icon>
            <v-icon
              small
              v-if="
                item.isActive &&
                  (!item.parentId ||
                    getContentCategoryById(item.parentId).isActive)
              "
              @click="changeActiveStatus(item)"
              class="ml-1"
            >
              mdi-close
            </v-icon>
          </template>

          <template v-slot:item.relatedTo="{ item }">
            {{ getRelatedToName(item) }}
          </template>

          <template v-slot:item.isFeatured="{ item }">
            <div class="featured">
              <v-checkbox
                v-model="item.isFeatured"
                v-if="item.parentId || item.parentId != undefined"
                @change="changeIsFeatured(item)"
              />
            </div>
          </template>
        </v-data-table>

        <v-snackbar v-model="showSnackbar">
          {{ snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="showSnackbar = false"
              class="custom-button"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmationDialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">
          {{ confirmationMessage }}
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            class="custom-button"
            text
            :disabled="loading"
            @click="closeImageDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="loading"
            color="blue darken-1 white--text"
            class="custom-button"
            @click="deleteImageConfirmed"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editImageTypesDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"> edit image types </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-select
            v-model="imageTypesEdited"
            :items="imageTypes"
            item-text="name"
            class="list"
            label="Select Image Type"
            multiple
            return-object
            chips
          />
          <v-btn
            color="red darken-1"
            class="custom-button"
            text
            :disabled="loading"
            @click="clearImageDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="loading || imageTypesEdited.length==0"
            color="blue darken-1 white--text"
            class="custom-button"
            @click="editTypesConfirmed"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import settingsMixin from "../../mixins/settings";
import { mapGetters } from "vuex";
import {
  LocalizedContentCategory,
  Language,
  ContentCategory,
} from "../../models";

export default {
  name: "ContentCategoriesListing",
  data() {
    return {
      tab: null,
      editImageTypesDialog: null,
      imageTypesEdited:[],
      editedFile: null,
      loading:false,
      confirmationDialogDelete:false,
      fileId:null,
      confirmationMessage: "",
      contentCategoryImage: null,
      contentCategoryTab: null,
      types: [],
      contentCategoriesFiles: [],
      contentCategoryTypes: [],
      tabs: ["Data", "Images"],
      contentCategoryImg: null,
      hasRelationHidden: false,
      hasRelation: false,
      search: "",
      relatedTo: null,
      parentId: null,
      contentCategoryHeaders: [
        {
          text: "Name",
          value: "localizations",
        },

        {
          text: "code",
          align: "center",
          sortable: false,
          value: "code",
        },

        {
          text: "Active",
          align: "center",
          sortable: false,
          value: "isActive",
        },
        {
          text: "Relaed to",
          align: "center",
          sortable: false,
          value: "relatedTo",
        },
        {
          text: "Is Featured",
          align: "center",
          sortable: false,
          value: "isFeatured",
        },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      maxSizeRule: [
        (value) =>
          value === null ||
          (value && value.size <= 31457280) ||
          "Image size should be less than 30 MB!",
      ],
      categoryName: null,
      imgUrl: null,
      description: null,
      editedIndex: -1,
      formLocalizedCategories: [],
      formItem: new ContentCategory(),
      editedContentCategory: new LocalizedContentCategory(),
      defaultContentCategory: new LocalizedContentCategory(),
      contentCategoryDialog: false,
      changeContentCategoryStatusDialog: false,
      changeStatusLoading: false,
      addLoading: false,
      showSnackbar: false,
      editedCategory: null,
      snackbarText: ``,
      valid: false,
      required: [(v) => !!v || "this field is required"],
      relatedToChildren: [],
    };
  },
  async mounted() {
    await this.$store.dispatch("post/loadImageTypes");
  },
  mixins: [settingsMixin],
  computed: {
    ...mapGetters("post", ["imageTypes"]),
    ...mapGetters("contentManagement", ["contentCategoryFile"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    getRootContentCategories() {
      return this.contentCategories.filter(
        (x) =>
          (x.parentId === null || x.parentId === undefined) &&
          (x.relatedToId === null || x.relatedToId === undefined)
      );
    },

    getRootNames() {
      return this.getRootContentCategories
        .filter((element) => this.editedCategory.code != element.code)
        .map((item) => {
          return {
            ...item,
            text: item?.localizations[this.getLocale()]?.categoryName,
          };
        });
    },
    getRelatedToChildren() {
      return this.relatedToChildren
        ? this.relatedToChildren.map((item) => {
            return {
              ...item,
              text: item.localizations[this.getLocale()]?.categoryName,
            };
          })
        : null;
    },
  },
  methods: {
    editTypes(img) {
      this.editImageTypesDialog = true;
      this.imageTypesEdited = img.types;
      this.editedFile = img;
    },
    async editTypesConfirmed() {
      await this.addImageType(this.editedFile, "updateImageType");
      this.clearImageDialog();
    },
    addNewContentCategory(parentId, item) {
      if (parentId) {
        this.hasRelationHidden = true;
      }
      this.categoryName = null;
      this.parentId = parentId;
      let parentContentCategory = item
        ? this.contentCategories.find((a) => a.id == item?.relatedTo?.id)
        : null;
      this.relatedToChildren =
        item?.relatedTo?.id && item.hasRelation
          ? this.contentCategories.filter(
              (a) => a.parentId == parentContentCategory.id
            )
          : [];
      this.formLocalizedCategories = [];
      this.constructLocalizedCategoryWithLanguages(this.languages);
      this.contentCategoryDialog = true;
    },
    getContentCategoriesFiles(item) {
      return item.contentCategoryFiles?.map((image) => {
        return {
          id: image.id,
          url: image.fileUrl,
          types: image?.contentCategoryFileImageTypes?.map(
            (a) => a.imageType
          ),
        };
      });
    },
    async editContentCategory(item) {
      this.editedCategory = item;
      // this.imgUrl = item.imgUrl;
      this.imageName = item.imgUrl ? item.imgUrl.split("/") : null;
      this.contentCategoryImg = this.imageName
        ? new File([""], this.imageName[this.imageName.length - 1])
        : null;
      this.relatedToChildren = item.relatedTo?.id
        ? this.contentCategories.filter(
            (a) =>
              a.parentId ==
              (item.relatedTo.parentId
                ? item.relatedTo.parentId
                : item.relatedToParentId)
          )
        : [];
      item.parentId
        ? (this.hasRelationHidden = true)
        : (this.hasRelationHidden = false);
      this.editedIndex = this.contentCategories.indexOf(item);
      this.formItem = item;
      this.hasRelation = item.hasRelation;
      this.relatedTo = item.relatedTo
        ? this.contentCategories.find((a) => a.id == item.relatedTo.id)
            .localizations[this.getLocale()]?.categoryName
        : null;
      this.contentCategoryDialog = true;
      await this.loadLocalizedContentCategoriesByParentId(item.id);
    },

    async addCategory() {
      const parentContentCategory = this.parentId
        ? { id: this.parentId }
        : null;
      const parentRelatedTo = this.relatedTo
        ? { id: this.relatedTo.parentId }
        : null;
      this.$store.commit(
        "contentManagement/setNewlyAddedContentCategoryParent",
        parentContentCategory
      );
      this.$store.commit(
        "contentManagement/setNewlyAddedRelatedToParent",
        parentRelatedTo
      );
      return await this.$store.dispatch(
        "contentManagement/addContentCategory",
        {
          code: this.categoryName,
          isActive: true,
          hasRelation: this.hasRelation,
          relatedTo: this.relatedTo,
          contentCategory: this.parentId ? { id: this.parentId } : null,
        }
      );
    },
    async updateImgUrl(contentCategory) {
      const formData = new FormData();
      formData.append("contentCategoryImg", this.contentCategoryImg);
      let payloadUpdateImage = {
        contentCategoryId: contentCategory.id,
        formData: formData,
      };
      await this.$store.dispatch(
        "contentManagement/updateContentCategoryImage",
        payloadUpdateImage
      );
    },
    async updateCategory() {
      this.formItem.hasRelation = this.hasRelation;
      this.formItem.relatedTo = this.relatedTo?.id ? this.relatedTo : null;
      this.$store.dispatch(
        "contentManagement/updateContentCategory",
        this.formItem
      );
    },
    async addUpdateLocalizedCategories(contentCategory) {
      this.formLocalizedCategories = this.formLocalizedCategories.filter(
        (x) => x.categoryName !== ""
      );
      for (let i = 0; i < this.formLocalizedCategories.length; i++) {
        this.formLocalizedCategories[i].contentCategory = contentCategory;
        this.formLocalizedCategories[i].contentCategoryId = contentCategory.id;
        if (
          this.formLocalizedCategories[i].id === 0 ||
          this.formLocalizedCategories[i].id === null
        ) {
          await this.$store.dispatch(
            "contentManagement/addLocalizedContentCategory",
            this.formLocalizedCategories[i]
          );
        } else {
          await this.$store.dispatch(
            "contentManagement/updateLocalizedContentCategory",
            this.formLocalizedCategories[i]
          );
        }
      }
    },
    deleteImage(id) {
      this.fileId = id;
      this.confirmationDialogDelete = true;
      this.confirmationMessage =
        "Are you sure you want to delete this picture?";
    },
    async deleteImageConfirmed() {
      await this.$store.dispatch(
        "contentManagement/removeContentCategoryFile",
        this.fileId
      );
      this.snackbar = true;
      this.loadingImage = false;
      this.closeImageDialog();
    },
    closeImageDialog() {
      this.confirmationDialogDelete = false;
    },
    getRelatedToName(item) {
      return item.relatedTo
        ? this.contentCategories.find((a) => a.id == item.relatedTo.id)
            ?.localizations[this.getLocale()]?.categoryName
        : null;
    },
    changeIsFeatured(item) {
      this.$store.dispatch("contentManagement/updateContentCategory", item);
    },
    async submitData() {
      try {
        this.addLoading = true;
        let addedCategory = this.formItem?.id
          ? await this.updateCategory()
          : await this.addCategory();
        await this.addUpdateLocalizedCategories(
          addedCategory ? addedCategory : this.formItem
        );
        if (
          (this.contentCategoryImg &&
            this.imageName &&
            this.imageName[4] != this.contentCategoryImg.name) ||
          (this.contentCategoryImg && !this.imageName)
        ) {
          await this.updateImgUrl(
            addedCategory ? addedCategory : this.formItem
          );
        }
        if (this.contentCategoryImage) {
          await this.addContentCategoryImage(
            this.contentCategoryImage,
            addedCategory ? addedCategory.id : this.formItem.id
          );
          await this.addImageType(this.contentCategoryFile , "addContentCategoryImagesTypes");
        }
        this.snackbarText = "Data saved successfully";
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.addLoading = false;
        if (!this.contentCategoryImage || (this.formTitle == 'New Item')) this.closeContentCategoryDialog();
        this.clearImageDialog();
        this.showSnackbar = true;
      }
    },
    async addContentCategoryImage(contentCategoryImage, id) {
      const formData = new FormData();
      formData.append("file", contentCategoryImage);
      formData.append("contentCategoryId", id);
      await this.$store.dispatch(
        "contentManagement/addContentCategoryImage",
        formData
      );
    },
    async addImageType(contentCategoryFile, mutationType) {
     await this.$store.dispatch(
        "contentManagement/updateContentCategoryImagesTypes",
        {
          contentCategoryFileId: contentCategoryFile.id,
          imagesTypes: this.imageTypesEdited.length>0
            ? this.imageTypesEdited
            : this.types,
          mutation: mutationType,
        }
      );
    },
    getImageTypesNames(types) {
      return types.map((a) => a.name).join('-'); 
       },

    closeContentCategoryDialog() {
      this.contentCategoryDialog = false;
      this.clearData();
      this.clearImageDialog();
   
    },
    clearImageDialog() {
      this.contentCategoryImage = null;
      this.types = [];
      this.imageTypesEdited = [];
      this.editImageTypesDialog = false;
    },
    clearData(){
      this.imgUrl = null;
      this.addedCategory=null;
      this.imageName = null;
      this.fileId = null;
      this.addedCategory = null;
      this.contentCategoryImage = null;
      this.contentCategoryImg = null;
      this.contentCategoriesFiles = null;
      this.$nextTick(() => {
        this.editedContentCategory = Object.assign(
          {},
          this.defaultContentCategory
        );
        this.tab = null;
        this.contentCategoryTab = null;
        this.types = [];
        this.valid = false;
        this.formItem = Object.assign({}, new ContentCategory());
        this.editedIndex = -1;
        this.hasRelationHidden = false;
        this.hasRelation = false;
        this.relatedTo = null;
        this.relatedToChildren = [];
        this.editedCategory = null;
      });
    },

    closeChangeStatusDialog() {
      this.changeContentCategoryStatusDialog = false;
      this.$nextTick(() => {
        this.editedContentCategory = Object.assign(
          {},
          this.defaultContentCategory
        );
        this.editedIndex = -1;
      });
    },

    changeActiveStatus(item) {
      this.editedIndex = this.contentCategories.indexOf(item);
      this.editedContentCategory = Object.assign({}, item);
      this.changeContentCategoryStatusDialog = true;
    },

    async changeStatusConfirmed() {
      try {
        this.changeStatusLoading = true;
        await this.$store.dispatch(
          "contentManagement/updateContentCategoryStatus",
          {
            id: this.editedContentCategory.id,
            isActive: !this.editedContentCategory.isActive,
          }
        );
        this.snakbarText = "Category updated successfully";
      } catch (error) {
        this.snackbar = error;
      } finally {
        this.changeStatusLoading = false;
        this.closeChangeStatusDialog();
        this.snackbar = true;
      }
    },

    async loadLocalizedContentCategoriesByParentId(parentId) {
      this.formLocalizedCategories = await this.$store.dispatch(
        "contentManagement/findCategoryById",
        parentId
      );
      const addedLanguages = [
        ...new Map(
          this.formLocalizedCategories.map((item) => [
            item["locale"],
            item.locale,
          ])
        ).values(),
      ];
      const missedLanguages = this.languages.filter(
        (item) => !addedLanguages.find((element) => element === item.locale)
      );
      if (missedLanguages?.length)
        this.constructLocalizedCategoryWithLanguages(missedLanguages);
    },

    constructLocalizedCategoryWithLanguages(languages) {
      for (let i = 0; i < languages?.length; i++) {
        let newLocalizedCategory = new LocalizedContentCategory({
          id: null,
          language: new Language(languages[i]),
          locale: languages[i].locale,
          categoryName: "",
        });
        this.formLocalizedCategories.push(newLocalizedCategory);
      }
    },
  },
  watch: {
    contentCategoryDialog(val) {
      val || this.closeContentCategoryDialog();
    },
    changeContentCategoryStatusDialog(val) {
      val || this.closeChangeStatusDialog();
    },
  },
};
</script>
<style scoped>
.content-category-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.add-parent {
  padding-right: 1rem;
  width: 70%;
  margin: 1rem;
}
.images-style {
  margin: 1rem;
}
.custom-button {
  border-radius: 3.125rem;
}

.featured {
  display: flex;
  justify-content: center;
}
.v-data-table {
  font-size: large;
  color: black;
}
.img-category {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
