import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      user: {
        username: "",
        clientName: "",
        idToken: "",
        accessToken: "",
        roles: [],
        group: "",
      },
      users: [],
      roles: [],
    };
  },
  mutations,
  actions,
  getters,
};
