<template>
	<v-container fluid>
		<v-row>
			<v-col>
				<v-data-table :headers="cachingHeaders" :items="cacheConfigs" hide-default-footer>
					<!--eslint-disable -->
					<template v-slot:top>
						<v-toolbar flat>
							<v-dialog v-model="purgeCacheDialog" max-width="500px">
								<template v-slot:activator="{ on, attrs }">
									<v-spacer></v-spacer>
									<v-btn color="primary" dark class="mb-2 custom-button" v-bind="attrs" v-on="on">
										<v-icon dark>mdi-delete</v-icon>
										Purge Cache
									</v-btn>
								</template>
								<PurgeCacheForm @close="closePurgeCacheDialog" />
							</v-dialog>
							<v-dialog v-model="editCacheConfigDialog" max-width="600px">
								<CoreConfigForm @reloadCacheConfigs="loadCacheConfigs" @close="closeEditConfigDialog"
									:editCacheConfigIndex="1" :editCacheConfig="selectedCacheConfig" />
							</v-dialog>
						</v-toolbar>
					</template>

					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom v-if="cacheConfigs.length > 1">
							<template v-slot:activator="{ on, attrs }">
								<v-icon small @click="openEditConfigDialog(item)" v-bind="attrs" v-on="on">
									mdi-pen
								</v-icon>
							</template>
							<span>edit</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";

import CoreConfig from "../../models/core-config";
import PurgeCacheForm from "../../components/PurgeCacheForm.vue";
import CoreConfigForm from "../../components/CoreConfigForm.vue";

export default {
	name: "Caching",
	components: { PurgeCacheForm, CoreConfigForm },
	data() {
		return {
			parentId: null,
			cachingHeaders: [
				{ text: "Config Key", align: "start", value: "configKey" },
				{ text: "Config Value", value: "configValue" },
				{ text: "Actions", value: "actions", sortable: false }
			],
			editedIndex: -1,
			purgeCacheDialog: false,
			editCacheConfigDialog: false,
			selectedCacheConfig: new CoreConfig(),
			panel: [0, 1],
			valid: false,
			required: [(v) => !!v || "this field is required"]
		};
	},

	computed: {
		...mapGetters("contentManagement", ["cacheConfigs"])
	},

	methods: {
		async loadCacheConfigs() {
			await this.$store.dispatch("contentManagement/loadCacheConfigs");
		},

		openEditConfigDialog(item) {
			this.editCacheConfigDialog = true;
			this.editedIndex = this.cacheConfigs.indexOf(item);
			this.selectedCacheConfig = Object.assign({}, item);
		},

		editCacheConfig() {
			this.editCacheConfigDialog = true;
		},

		closeEditConfigDialog() {
			this.editCacheConfigDialog = false;
		},

		openPurgeCacheDialog() {
			this.purgeCacheDialog = true;
		},

		closePurgeCacheDialog() {
			this.purgeCacheDialog = false;
		},
	},
	watch: {
		editCacheConfigDialog(val) {
			val || this.closeEditConfigDialog();
		}
	},
	mounted() {
		this.loadCacheConfigs();
	}
};
</script>
<style>
.custom-button {
	border-radius: 3.125rem;
}
</style>
