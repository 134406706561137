import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
	namespaced: true,
	state() {
		return {
			languages: [],
			defaultLanguage: null,
			contentTypes: [],
			contentTypesTrees: [],
			contentCategories: [],
			contentTypeById: {},
			contentCategoryFile:null,
			contentTypesCategories: [],
			localizedContentTypesCategories: null,
			newlyAddedContentType: null,
			newlyAddedContentCategoryParent: null,
			newlyAddedContentTypeParent: null,
			supportedLanguages: [],
			menuCategory: [],
			contentTypeWithMenuCategoryNull: [],
			addContentToMenuCategory: {},
			chnageCategory: null,
			optionalFeatures: [],
			editedContentTypeOptionalFeatures: [],
			currentlyAddedContentCategory : null,
			contentCategoryAdded:null,
			newlyAddedRelatedToParent: null,
			lookupData:[],
			lookupTypes:[],
			cacheConfigs:[],
			cachePurgeStatus: null
		};
	},
	mutations: mutations,
	actions: actions,
	getters: getters
};
