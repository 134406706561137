<template>
  <div class="container">
    <v-row>
      <v-col cols="8">
        <v-form v-model="SizeConfigurationFormValid" ref="tenantForm">
          <h2>Size Configuration</h2>

          <v-text-field
            v-model="sizeConfiguration.imageSize"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              Image Size
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="sizeConfiguration.fileSize"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              file size
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!isSaving" class="d-flex flex-row-reverse" cols="8">
        <v-btn
          color="primary"
          @click="editSizeConfiguration"
          :disabled="!SizeConfigurationFormValid"
        >
          edit
        </v-btn>
      </v-col>
      <v-col v-else class="d-flex flex-row-reverse" cols="8">
        <v-progress-circular
          indeterminate
          color="deep-orange lighten-2"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-snackbar v-model="showSnackbar" absolute>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay :value="isSaving"></v-overlay>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import sizeConfiguration from "../../../posts/models/size-configuration";
export default {
  title: "Add Tenant",
  data() {
    return {
      sizeConfiguration: new sizeConfiguration(),
      valid: false,
      isSaving: false,
      SizeConfigurationFormValid: true,
      required: [(v) => !!v || "This field is required"],
      showSnackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    ...mapGetters("post", ["sizeConfigurationSelected"]),
  },
  async mounted() {
    await this.loadSizeConfigurationById();
  },
  methods: {
    async loadSizeConfigurationById() {
      await this.$store.dispatch(
        "post/loadSizeConfigurationById",
        this.$route.params.id
      );
    },

    async editSizeConfiguration() {
      try {
        let payload = {
          id: this.$route.params.id,
          item: this.sizeConfiguration,
        };
        await this.$store.dispatch("post/updateSizeConfiguration", payload);
        this.showSnackbar = true;
        this.snackbarText = "updated data Successfully";
      } catch (err) {
        this.showSnackbar = true;
        this.snackbarText = err;
      }
    },
  },
  watch: {
    sizeConfigurationSelected() {
      this.sizeConfiguration = { ...this.sizeConfigurationSelected };
    },
  },
};
</script>
<style scoped>
.container {
  padding-left: 1rem;
}
</style>
