import axios from "axios";

export default class NetworkManager {
  static async get(
    context,
    apiName,
    modelName,
    commitMethod,
    parser,
    headers,
    axiosInstance = axios
  ) {
    return await axiosInstance.get(apiName, headers).then((response) => {
      const data = parser.parse(modelName, response.data.response);
      if (commitMethod) {
        context.commit(commitMethod, data);
      }
      return data;
    });
  }
  static async post(
		context,
		apiName,
		body,
		modelName,
		commitMethod,
		parser,
		headers,
    axiosInstance = axios
	) {
		return await axiosInstance.post(apiName, body, headers).then((response) => {
			const data = parser.parse(modelName, response.data.response);
			if (commitMethod) {
				context.commit(commitMethod, data);
			}
			return data;
		});
	}
  static async delete(
    context,
    apiName,
    payload,
    modelName,
    commitMethod,
    parser,
    axiosInstance = axios
  ) {
    return await axiosInstance.delete(apiName, payload).then((response) => {
      const data = parser.parse(modelName, response.data.response);
      if (commitMethod) {
        context.commit(commitMethod, data);
      }
      return data;
    });
  }
  static async put(
    context,
    apiName,
    payload,
    modelName,
    commitMethod,
    parser,
    headers,
    axiosInstance = axios
  ) {
    return await axiosInstance.put(apiName, payload, headers).then((response) => {
      const data = parser.parse(modelName, response.data.response);
      if (commitMethod) {
        context.commit(commitMethod, data);
      }
      return data;
    });
  }
  static async download(apiName, axiosInstance = axios) {
    return await axiosInstance
      .get(apiName, {
        responseType: "blob",
      })
      .then((response) => {
        return response.data;
      });
  }
}
