<template>
	<v-card>
		<v-select
			class="selectBox"
			v-model="tenantCodeSelected"
			:items="tenants"
			item-text="tenantName"
			:item-value="tenants.tenantCode"
			@change="MailSenderData"
			label="Select Tenant Name"
			persistent-hint
			return-object
			single-line
		></v-select>
				<v-btn v-if="tenantCodeSelected && mailSender.length == 0" color="primary" class="create-websiteS3" to="/create-mail-sender">
					Create Mail Sender
				</v-btn>
		<v-data-table
			:headers="headers"
			:items="mailSender"
			sort-by="name"
			class="elevation-1"
			v-if="mailSender.length > 0"
		>
			
			<!--eslint-disable -->
		
		</v-data-table>

		<v-snackbar>
			{{ snackbarText }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="pink"
					text
					v-bind="attrs"
					@click="snackbar = false"
					class="custom-button"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	title: "Tenants",
	data() {
		return {
			tenantCodeSelected: null,
			isLoading: true,
			snackbar: false,
			snackbarText: ``,
			confirmMSG: "",
			dialogDelete: false,
			isManageActiveStatus: false,
			headers: [
				{
					text: "host",
					align: "start",
					value: "host"
				},
				{ text: "port", value: "port" },
				{ text: "User Name", value: "username" },
				{ text: "Password", value: "password" },
				{ text: "From", value: "from" },
				{ text: "To", value: "to" },
			]
		};
	},
	computed: {
		...mapGetters("tenant", ["tenants", "tenantCode", "mailSender"])
	},
	async created() {
		await this.loadTenants();
	},
	methods: {
		async loadTenants() {
			await this.$store.dispatch("tenant/loadTenants");
		},
		async MailSenderData() {
			await this.loadMailSender();
		},
		async loadMailSender() {
			this.$store.commit(
				"tenant/setTenantCodeSelected",
				this.tenantCodeSelected.tenantCode
			);
			await this.$store.dispatch("tenant/loadMailSender");
		}
	}
};
</script>
<style scoped>
.selectBox {
	padding: 2rem;
}
.create-websiteS3 {
	color: white;
	margin: 2rem;
}
.custom-button {
	border-radius: 3.125rem;
}
</style>
