<template>
	<v-container fluid>
		<v-row class="mt-2">
			<v-spacer></v-spacer>
			<v-dialog v-model="pageCategoryDialog" max-width="850px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						color="primary dark white--text"
						dark
						class="mb-2  custom-button"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon>mdi-plus</v-icon>
						New Page Category
					</v-btn>
				</template>
				<v-card>
					<v-card-title>
						<span class="text-h5">Add Page Category</span>
					</v-card-title>
					<v-card-text>
						<v-form v-model="valid" ref="contentTypeForm">
							<v-container>
								<v-row>
									<v-col cols="6" sm="6">
										<v-text-field
											v-model="editedPageCategory.name"
											label="name"
											:rules="required"
										/>
									</v-col>
								</v-row>
							</v-container>
						</v-form>
					</v-card-text>

					<v-card-actions>
						<v-spacer />
						<v-btn
							color="red darken-1"
							text
							@click="closeAddPageCategoryDialog"
							class="custom-button"
						>
							Cancel
						</v-btn>

						<v-btn
							color="blue darken-1 white--text"
							class="custom-button"
							@click="addPageCategory"
							:disabled="!valid || addPageCategoryLoading"
						>
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:headers="pageCategoryHeaders"
					:items="pageCategory"
					sort-by="name"
				>
					<!--eslint-disable -->
					<template v-slot:top>
						<v-dialog v-model="contentTypeDialog" max-width="850px">
							<template>
								<v-card>
									<v-card-title>
										<span class="text-h5">Add Page</span>
									</v-card-title>
									<v-card-text>
										<v-form v-model="valid" ref="form">
											<v-container>
												<v-row>
													<v-col cols="6" sm="6">
														<v-select
															:items="getPageName"
															label="Page"
															:rules="required"
															persistent-hint
															return-object
															single-line
															dense
															outlined
															multiple
															v-model="selectedPages"
														/>
													</v-col>
												</v-row>
											</v-container>
										</v-form>
									</v-card-text>

									<v-card-actions>
										<v-spacer />
										<v-btn
											color="red darken-1"
											class="custom-button"
											text
											@click="closeAddPageCategoryDialog"
										>
											Cancel
										</v-btn>
										<v-btn
											color="blue darken-1"
											class="custom-button"
											text
											@click="addPage"
											:loading="loading"
											:disabled="!valid || loading"
										>
											Save
										</v-btn>
									</v-card-actions>
								</v-card>
							</template>
						</v-dialog>

						<v-dialog v-model="confirmationDialogDelete" max-width="500px">
							<v-card>
								<v-card-title class="text-h5">
									{{ confirmationMessage }}
								</v-card-title>
								<v-card-actions>
									<v-spacer />
									<v-btn
										color="red darken-1"
										text
										@click="closeConfirmationDialog"
										:disabled="loading"
										class="custom-button"
									>
										Cancel
									</v-btn>
									<v-btn
										:disabled="loading"
										class="custom-button"
										color="blue darken-1 white--text"
										@click="deletePageCategoryConfirmed"
									>
										OK
									</v-btn>
									<v-spacer />
								</v-card-actions>
							</v-card>
						</v-dialog>
					</template>

					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon small @click="addChild(item)" v-bind="attrs" v-on="on">
									mdi-plus-network
								</v-icon>
							</template>

							<span>Add Page </span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									color="red darken-4"
									small
									@click="deletePageCategory(item)"
								>
									mdi-delete
								</v-icon>
							</template>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapGetters } from "vuex";
import PageCategory from "../../../page/models/page-category";

export default {
	data() {
		return {
			confirmationMessage: "",
			confirmationDialogDelete: false,
			toBeDeletePageCategory: null,
			pageCategoryDialog: false,
			selectedPages: [],
			selectPagesObjects: [],
			pageCategoryId: null,
			loading: false,
			valid: false,
			contentTypeDialog: false,
			editedPageCategory: new PageCategory(),
			defaultPageCategory: new PageCategory(),
			required: [(v) => !!v || "this field is required"],
			addPageCategoryLoading: false,
			pageCategoryHeaders: [
				{
					text: "Name",
					align: "start",
					sortable: false,
					value: "name"
				},
				{
					text: "Creation Date",
					align: "start",
					sortable: false,
					value: "creationDate"
				},

				{ text: "Actions", value: "actions", sortable: false }
			]
		};
	},
	computed: {
		...mapGetters("page", ["pageCategory", "pages", "selectedPageCategory"]),
		getPageName() {
			return this.pages.map((item) => item.name);
		}
	},

	methods: {
		deletePageCategory(item) {
			this.toBeDeletePageCategory = item;
			this.confirmationDialogDelete = true;
			this.confirmationMessage =
				"Are you sure you want to delete this page category?";
		},
		deletePageCategoryConfirmed() {
			try {
				this.snackbar = true;
				this.$store.dispatch(
					"page/deletePageCategoryItem",
					this.toBeDeletePageCategory
				);
				this.closeConfirmationDialog();
				this.snackbarText = "Deleted contact us successfully";
			} catch (error) {
				this.snackbar = true;
				this.snackbarText = error;
			} finally {
				this.closeConfirmationDialog();
				this.snackbar = true;
				this.loading = true;
				this.toBeDeletePageCategory = null;
			}
		},
		closeConfirmationDialog() {
			this.confirmationDialogDelete = false;
		},
		close() {
			this.pageCategoryDialog = false;
			this.$refs.form.resetValidation();
		},
		closeAddPageCategoryDialog() {
			this.pageCategoryDialog = false;
			this.contentTypeDialog = false;
			this.$store.commit("page/setPageCategoryById", null);
			this.selectedPages = [];
			this.$nextTick(() => {
				this.editedPageCategory = Object.assign({}, this.defaultPageCategory);
				this.editedIndex = -1;
			});
		},
		async addPageCategory() {
			let payload = {
				name: this.editedPageCategory.name
			};
			await this.$store.dispatch("page/addPageCategory", payload);
			this.pageCategoryDialog = false;
		},
		async addChild(item) {
			await this.$store.dispatch("page/loadPageCategoryById", item);

			this.pageCategoryId = item.id;
			this.selectedPages = this.selectedPageCategory.pages?.map(
				(item) => item.name
			);
			this.contentTypeDialog = true;
		},
		async addPage() {
			for (let i in this.selectedPages) {
				let pageObject = this.pages.find(
					(item) => item.name == this.selectedPages[i]
				);
				this.selectPagesObjects.push(pageObject);
			}
			let payload = {
				pageCategoryId: this.pageCategoryId,
				selectedPages: this.selectPagesObjects
			};

			await this.$store.dispatch("page/addPageToPageCategory", payload);

			this.payload = {};
			this.selectPagesObjects = [];
			this.closeAddPageCategoryDialog();
		}
	}
};
</script>
<style scoped>
.v-data-table {
	font-size: large;
	color: black;
}
.custom-button {
	border-radius: 3.125rem;
}
</style>
