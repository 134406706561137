<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" v-if="!loading" class="select">
        <v-checkbox
          @change="selectAllColumns"
          v-model="selectAll"
          label="Select All"
          >Select all</v-checkbox
        >
      </v-col>
      <v-col cols="6" v-if="!loading" class="save-btn">
        <v-btn
          color="black darken-1 white--text "
          class="custom-button"
          @click="saveChangeStatus"
        >
          Save
        </v-btn>
        <v-btn
          color="black darken-1 white--text "
          class="custom-button ml-2"
          @click="addExtraColumns"
        >
          extra columns
        </v-btn>
      </v-col>

      <v-col>
        <v-data-table
          hide-default-footer
          item-key="id"
          :items-per-page="-1"
          :headers="contactUsColumnsHeaders"
          :loading="loading"
          :items="contactUsColumns"
          class="capitalize"
        >
          <template v-slot:[`item.select`]="{ item }">
            <v-checkbox v-model="selectItem" @change="select" :value="item" />
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="editContactUsColumn(item)">
              mdi-pencil
            </v-icon>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="editContactUsColumnDialog" max-width="850px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5"
                      >Edit Contact us Columns Description</span
                    >
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="contactUsColumnsForm">
                      <v-container>
                        <v-row>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContactUsColumn.displayName"
                              class="disabled-name"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedContactUsColumn.description"
                              label="Description"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="red darken-1"
                      class="custom-button"
                      text
                      @click="closeContactUsColumnDialog"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="blue darken-1 white--text"
                      class="custom-button"
                      @click="submitData"
                      :disabled="!editedContactUsColumn.description"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="editeExtraColumns" max-width="850px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Extra Columns</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="contactUsColumnsForm">
                      <v-container>
                        <v-row>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedExtraColumns.commentConfig1"
                              label="comment config1"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedExtraColumns.commentConfig2"
                              label="comment config2"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedExtraColumns.commentConfig3"
                              label="comment config3"
                            />
                          </v-col>
                          <v-col cols="6" sm="6">
                            <v-text-field
                              v-model="editedExtraColumns.commentConfig4"
                              label="comment config4"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="red darken-1"
                      class="custom-button"
                      text
                      @click="closeContactUsColumnDialog"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="blue darken-1 white--text"
                      class="custom-button"
                      @click="submitConfigData"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Pagination -->

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import ContactUsColumn from "../../models/contact-us-column";
import ContactUsColumnsConfig from "../../models/ContactUsConfig";

export default {
  data() {
    return {
      snackbar: false,
      snackbarText: ``,
      selectItem: [],
      selectAll: false,
      loading: false,
      editedContactUsColumn: new ContactUsColumn(),
      editedExtraColumns: new ContactUsColumnsConfig(),
      editContactUsColumnDialog: false,
      editeExtraColumns: false,
      contactUsColumnsHeaders: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "select",
        },
        {
          text: "name",
          align: "start",
          sortable: false,
          value: "displayName",
        },
        {
          text: "description",
          align: "start",
          sortable: false,
          value: "description",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  async mounted() {
    await this.loadContactUsColumns();
    await this.loadContactUsColumnsSelected();
  },

  computed: {
    ...mapGetters("contactUs", ["contactUsColumns", "cotactUsConfigObject"]),
  },
  methods: {
    async loadContactUsColumns() {
      await this.$store.dispatch("contactUs/loadContactUsColumns");
    },
    loadContactUsColumnsSelected() {
      let activeContactUsColumns = this.contactUsColumns.filter(
        (a) => a.isActive
      );
      this.selectItem = activeContactUsColumns;
      this.selectAll =
        this.contactUsColumns.length != activeContactUsColumns.length
          ? false
          : true;
    },
    async addExtraColumns() {
      await this.loadContactUsConfig();
      this.editedExtraColumns = this.cotactUsConfigObject
        ? this.cotactUsConfigObject
        : new ContactUsColumnsConfig();
      this.editeExtraColumns = true;
    },
    async loadContactUsConfig() {
      await this.$store.dispatch("contactUs/loadContactUsConfig");
    },
    select() {
      this.selectAll =
        this.selectItem.length != this.contactUsColumns.length ? false : true;
    },
    selectAllColumns() {
      this.selectItem = [];
      this.selectAll ? (this.selectItem = this.contactUsColumns) : false;
    },
    editContactUsColumn(item) {
      this.editContactUsColumnDialog = true;
      this.editedContactUsColumn = item;
    },
    async submitData() {
      this.snackbar = true;
      try {
        await this.$store.dispatch(
          "contactUs/editContactUsColumnName",
          this.editedContactUsColumn
        );
        this.snackbarText = "Data updated Successfully";
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.closeContactUsColumnDialog();
      }
    },
    async submitConfigData() {
      this.snackbar = true;
      try {
        this.cotactUsConfigObject
          ? await this.$store.dispatch(
              "contactUs/editContactUsColumnConfig",
              this.editedExtraColumns
            )
          : await this.$store.dispatch(
              "contactUs/createContactUsColumnConfig",
              this.editedExtraColumns
            );
        this.snackbarText = "Data updated Successfully";
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.closeContactUsColumnDialog();
      }
    },
    async saveChangeStatus() {
      this.snackbar = true;
      try {
        await this.$store.dispatch(
          "contactUs/contactUsColumnsChangeStatus",
          this.selectItem
        );
        this.snackbarText = "Data updated Successfully";
      } catch (error) {
        this.snackbarText = error;
      }
    },
    closeContactUsColumnDialog() {
      this.editContactUsColumnDialog = false;
      this.editeExtraColumns = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.save-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.page-size-selector {
  width: 75px;
}
.v-data-table {
  font-size: large;
  color: black;
}
.disabled-name {
  pointer-events: none;
  opacity: 0.8;
}
.v-input input {
  text-transform: capitalize;
}
</style>
