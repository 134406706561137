export default {
  posts: (state) => state.posts,
  localizedPosts: (state) => state.localizedPosts,
  selectedPost: (state) => state.selectedPost,
  postFiles: (state) => state.postFiles,
  localizedPostsPaginated: (state) => state.localizedPostsPaginated,
  currentlyAddedFile: (state) => state.currentlyAddedFile,
  imageTypes: (state) => state.imageTypes,
  editedPostFileImageTypes: (state) => state.editedPostFileImageTypes,
  parentPostContentCategory: (state) => state.parentPostContentCategory,
  parentPost: (state) => state.parentPost,
  pageNumberForPost: (state) => state.pageNumberForPost,
  childrenPagesNumber: (state) => state.childrenPagesNumber,
  localizedPostFileCaption: (state) => state.localizedPostFileCaption,
  currentlyAddedPostFileCaption: (state) => state.currentlyAddedPostFileCaption,
  sizeConfiguration : (state) => state.sizeConfiguration,
  sizeConfigurationSelected :(state) => state.sizeConfigurationSelected
};
