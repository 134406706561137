<template>
  <Fragment>
    <v-container>
      <v-row>
        <v-col cols="12"> <h2>Add User </h2></v-col>
        <!-- before tabs -->
        <v-form v-model="formValidation" ref="image-form" class="image-dialog">
          <v-col cols="12">
            <v-row>
              <v-col cols="6" sm="6">
                <v-text-field
                  v-model="editedUser.username"
                  label="Username"
                  :rules="required"
                />
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field
                  v-model="editedUser.email"
                  label="Email"
                  :rules="[...emailRules, ...required]"
                />
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field
                  type="password"
                  v-model="editedUser.password"
                  label="Password"
                  :rules="[ ...required ,...passwordContainCapitalLetter ,...passwordContainSpecialCharachter,...passwordContainNumber]"
                />
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field
                  type="password"
                  v-model="confirmPassword"
                  label="Confirm Password"
                  :rules="pwdConfirm"
                />
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field
                  v-model="editedUser.firstName"
                  label="First Name"
                />
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field v-model="editedUser.lastName" label="Last Name" />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6" sm="6">
                <v-text-field
                  v-model="editedUser.phoneNumber"
                  label="Phone Number"
                />
              </v-col>
          <v-col cols="6" sm="6" v-if="useFormRouteSetting">
            <v-select
              :items="filterRoles"
              label="select role"
              outlined
              dense
              hide-details
              v-model="selectRole"
              multiple
            />
          </v-col>
          <v-col cols="12" class="d-flex flex-row-reverse">
            <v-btn
              color="black darken-1 white--text "
              class="custom-button "
              @click="submitForm"
              :disabled="!formValidation"
              :loading="loading"
            >
              Save
            </v-btn>
          </v-col>
        </v-form>
      </v-row>
      <v-snackbar v-model="snackbar">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
            class="custom-button"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </Fragment>
</template>

<script>
import { mapGetters } from "vuex";
import User from "../../models/User";

export default {
  data() {
    return {
      editedUser: new User(),
      pwdConfirm: [
        v => !!v || "Confirm password",
        v => v === this.editedUser.password || "Passwords do not match"
      ],
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      passwordContainSpecialCharachter:[
        (v) =>
          !v ||
          /[`!@#$%^&*()_+\-=\\|,.<>?~]/.test(v) ||
          "password must contain special charachter",
      ],
      passwordContainCapitalLetter:[
        (v) =>
          !v ||
          /[A-Z]/.test(v) ||
          "password must contain capital letter",
      ],
       passwordContainNumber:[
        (v) =>
          !v ||
           /[0-9]/.test(v) ||
          "password must contain number",
      ],     
      addSetting: false,
      confirmPassword:null,
      selectRole: [],
      formValidation: false,
      required: [(v) => !!v || "This field is required"],
      snackbar: false,
      loading: false,
      snackbarText: ``,
    };
  },
  async mounted() {
    await this.loadRoles();
  },
  computed: {
    ...mapGetters("auth", ["username", "groups", "allRoles"]),
    filterRoles() {
      return this.allRoles.filter(
        (a) => a == "client-admin" || a == "client-user"
      );
    },
    useFormRouteSetting(){
    return this.$route.fullPath == "/setting/user-form";
    }
  },
  methods: {
    async loadRoles() {
      await this.$store.dispatch("auth/getAllRoles");
    },
    async submitForm() {
      try {
        let response = await this.$store.dispatch(
          "auth/registerUser",
          this.editedUser
        );
        let payload = {
          id: response.id,
          realmRoles: [],
          groupName: `${this.groups}`,
        };
        payload.realmRoles = !this.useFormRouteSetting? ['client-user'] : this.selectRole;
        if (payload.realmRoles.length > 0)
          await this.$store.dispatch("auth/addRolesToUser", payload);
        this.snackbar = true;
        this.snackbarText = "User Added Successfully";
        this.loading = true;

      } catch (error) {
        this.snackbar = true;
        this.snackbarText = error;
      } finally {
        this.snackbar = true;
        this.loading = false;
      }
    },
  },
};
</script>
