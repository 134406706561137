<template>
  <v-container fluid>
    <v-container fluid>
      <v-row>
        <v-spacer></v-spacer>
        <v-dialog v-model="lookupTypeDialog" max-width="850px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-6 custom-button mt-4"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>mdi-plus</v-icon>
              New Lookup Type
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">New Lookup Type</span>
            </v-card-title>
            <v-card-text>
              <v-form v-model="valid" ref="lookupType">
                <v-container>
                  <v-row>
                    <v-col cols="6" sm="6">
                      <v-text-field
                        v-model="editedLookupType.type"
                        label="type"
                        :rules="required"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red darken-1"
                class="custom-button"
                @click="closeLookupDialog"
                text
              >
                Cancel
              </v-btn>

              <v-btn
                color="blue darken-1 white--text"
                class="custom-button"
                :disabled="!valid"
                @click="submitLookupType"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="lookupDataDialog" max-width="850px">
          <v-card>
            <v-card-title>
              <span class="text-h5">New Lookup Data</span>
            </v-card-title>
            <v-card-text>
              <v-form v-model="valid" ref="lookupType">
                <v-container>
                  <v-row>
                    <v-col cols="6" sm="6">
                      <v-text-field
                        v-model="editedLookupData.name"
                        label="name"
                        :rules="required"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red darken-1"
                class="custom-button"
                @click="closeLookupDialog"
                text
              >
                Cancel
              </v-btn>

              <v-btn
                color="blue darken-1 white--text"
                class="custom-button"
                :disabled="!valid"
                @click="submitLookupData"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <v-data-table
      :headers="contactUsLookupType"
      :items="lookupTypes"
      hide-default-footer
      sort-by="name"
      class="capitalize "
    >
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom v-if="item">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small v-bind="attrs" v-on="on" @click="addLookupData(item)">
              mdi-plus-network
            </v-icon>
          </template>
          <span>Add Lookup data</span>
        </v-tooltip>
        <v-icon small class="ml-1" @click="editLookupType(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>

    <v-data-table
      :headers="lookupDataHeaders"
      group-by="lookupType.type"
      :items="lookupData"
      hide-default-footer
      :items-per-page="-1"
      class="capitalize "
    >
      <template v-slot:group.header="{ groupBy, group, isOpen, toggle }">
        <td :colspan="lookupDataHeaders.length">
          <v-icon @click="toggle">
            {{ isOpen ? "mdi-minus" : "mdi-plus" }}
          </v-icon>
          <span v-if="group">{{ group }}</span>
          <span v-else><b>Root</b></span>
        </td>
      </template>
      <template v-slot:item.type="{ item }">
        <span v-if="item">{{ item.name }} </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="ml-1" @click="editLookupData(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import LookupType from "../../models/Lookup-type";
import LookupData from "../../models/lookup-data";

export default {
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      lookupDataDialog: false,
      selectedLookupType: null,
      lookupTypeDialog: false,
      required: [(v) => !!v || "this field is required"],
      valid: false,
      contactUsLookupType: [
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "type",
        },

        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      lookupDataHeaders: [
        {
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      editedLookupData: new LookupData(),
      editedLookupType: new LookupType(),
    };
  },
  async mounted() {
    await this.loadLookupType();
    await this.loadLookupData();
  },
  computed: {
    ...mapGetters("contentManagement", ["lookupData", "lookupTypes"]),
  },
  methods: {
    async loadLookupData() {
      await this.$store.dispatch("contentManagement/loadLookupData");
    },
    async loadLookupType() {
      await this.$store.dispatch("contentManagement/loadLookupType");
    },

    async addLookupData(lookupType) {
      this.lookupDataDialog = true;
      this.selectedLookupType = lookupType;
    },
    async editLookupData(item) {
      this.editedLookupData = item;
      this.lookupDataDialog = true;
    },
    async submitLookupData() {
		this.snackbar = true;
      if (this.editedLookupData?.id) {
        try {
          await this.$store.dispatch(
            "contentManagement/updateLookupData",
            this.editedLookupData
          );
          this.snackbarText = "Lookup data updated successfully";
        } catch (error) {
          this.snackbarText = error;
        }
      } else {
        try {
          this.editedLookupData.lookupType = this.selectedLookupType;
          await this.$store.dispatch(
            "contentManagement/createLookupData",
            this.editedLookupData
          );
          this.snackbarText = "Lookup data added successfully";
        } catch (error) {
          this.snackbarText = error;
        }
      }
      this.closeLookupDialog();
      await this.loadLookupData();
    },

    editLookupType(item) {
      this.lookupTypeDialog = true;
      this.editedLookupType = item;
    },
    async submitLookupType() {
      if (this.editedLookupType?.id) {
        this.snackbar = true;
        try {
          await this.$store.dispatch(
            "contentManagement/updateLookupType",
            this.editedLookupType
          );
          this.snackbarText = "Lookup type updated successfully";
          await this.loadLookupData();
        } catch (error) {
          this.snackbarText = error;
        }
      } else {
        this.snackbar = true;
        try {
          await this.$store.dispatch(
            "contentManagement/createLookupType",
            this.editedLookupType
          );
          this.snackbarText = "Lookup type added successfully";
        } catch (error) {
          this.snackbarText = error;
        }
      }
      this.closeLookupDialog();
    },

    closeLookupDialog() {
      this.lookupTypeDialog = false;
      this.editedLookupType = Object.assign({}, new LookupType());
      this.editedLookupData = Object.assign({}, new LookupData());
      this.lookupDataDialog = false;
    },
  },
};
</script>
<style>
.custom-button {
  border-radius: 3.125rem;
}
</style>
