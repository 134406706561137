<template>
	<v-container fluid>
		<v-row>
			<v-col>
				<v-data-table
					:headers="languagesHeaders"
					:items="languages"
					hide-default-footer
				>
					<!--eslint-disable -->
					<template v-slot:top>
						<v-toolbar flat>
							<v-dialog v-model="languageDialog" max-width="500px">
								<template v-slot:activator="{ on, attrs }">
									<v-spacer></v-spacer>
									<v-btn
										color="primary"
										dark
										class="mb-2 custom-button"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon dark>mdi-plus</v-icon>
										New Language
									</v-btn>
								</template>
								<LanguageForm @close="close" />
							</v-dialog>
							<v-dialog v-model="languageDialogDelete" max-width="600px">
								<v-card>
									<v-card-title class="text-h5">
										Are you sure you want to deactivate this language?
									</v-card-title>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn
											color="red darken-1"
											class="custom-button"
											text
											@click="closeDelete"
											:disabled="isDeleteLanguage"
										>
											Cancel
										</v-btn>
										<v-btn
											:disabled="isDeleteLanguage"
											color="blue darken-1"
											class="custom-button"
											text
											@click="deleteItemConfirm"
										>
											OK
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>
						</v-toolbar>
					</template>

					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom v-if="languages.length > 1">
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									small
									@click="deleteItem(item)"
									v-bind="attrs"
									v-on="on"
								>
									mdi-delete
								</v-icon>
							</template>
							<span>deactivate</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									small
									@click="updateDefaultLanguage(item)"
									v-bind="attrs"
									v-on="on"
									:disabled="item.isDefault == true"
								>
									mdi-update
								</v-icon>
							</template>
							<span>set default</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import Language from "../../models/language";
import LanguageForm from "../../components/LanguageForm.vue";
import { mapGetters } from "vuex";

export default {
	name: "LanguageListing",
	components: { LanguageForm },
	data() {
		return {
			parentId: null,
			languagesHeaders: [
				{
					text: "Name",
					align: "start",
					sortable: false,
					value: "locale"
				},
				{ text: "Description", value: "description" },
				{ text: "Default", value: "isDefault" },
				{ text: "Actions", value: "actions", sortable: false }
			],
			editedIndex: -1,
			editedLanguage: new Language(),
			defaultLanguage: new Language(),
			languageDialog: false,
			languageDialogDelete: false,
			isDeleteLanguage: false,
			snackbar: false,
			snakbarText: ``,
			panel: [0, 1],
			valid: false,
			required: [(v) => !!v || "this field is required"]
		};
	},

	computed: {
		...mapGetters("contentManagement", ["languages"])
	},

	methods: {
		close() {
			this.snackbar = true;
			this.languageDialog = false;
			this.$nextTick(() => {
				this.editedLanguage = Object.assign({}, this.defaultLanguage);
				this.editedIndex = -1;
			});
		},

		async updateDefaultLanguage(item) {
			await this.$store.dispatch(
				"contentManagement/updateDefaultLanguage",
				item.id
			);
			await this.loadLanguages();
		},

		closeDelete() {
			this.languageDialogDelete = false;
			this.$nextTick(() => {
				this.editedLanguage = Object.assign({}, this.defaultLanguage);
				this.editedIndex = -1;
			});
		},

		deleteItem(item) {
			this.editedIndex = this.languages.indexOf(item);
			this.editedLanguage = Object.assign({}, item);
			this.languageDialogDelete = true;
		},

		async deleteItemConfirm() {
			await this.deleteLanguage();
			await this.loadLanguages();
			this.closeDelete();
		},

		async deleteLanguage() {
			this.isDeleteLanguage = true;
			await this.$store.dispatch("contentManagement/deactivateLanguage", {
				id: this.editedLanguage.id
			});
			this.isDeleteLanguage = false;
			this.snakbarText = "Language Deleted Successfully";
			this.snackbar = true;
		},

		async loadLanguages() {
			await this.$store.dispatch("contentManagement/loadLanguages");
		}
	},
	watch: {
		languageDialog(val) {
			val || this.close();
		},
		languageDialogDelete(val) {
			val || this.closeDelete();
		}
	}
};
</script>
<style>
.custom-button {
	border-radius: 3.125rem;
}
</style>
