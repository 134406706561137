import Application from "./application";

export default class Client {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.name = rawData.name;
    this.creationDate = rawData.creationDate;
    this.lastModificationDate = rawData.lastModificationDate;
    this.markedAsDeleted = rawData.markedAsDeleted;

    this.clientApplication = new Application(rawData.clientApplication);
  }
}