<template>
	<v-card>
		<v-card-title class="text-h5">
			Update Cache Config
		</v-card-title>

		<v-card-text>
			<v-form v-model="valid" ref="form">
				<v-container>
					<v-row>
						<v-col cols="4" sm="4" class="mt-3 ml-5">
							<v-label>{{ editCacheConfig.configKey }}</v-label>
						</v-col>

						<v-col cols="5" sm="5">
							<v-select v-if="editCacheConfig.configType.toUpperCase() === 'BOOLEAN'"
								:items="['True', 'False']" :label="editCacheConfig.configValue" :rules="required"
								persistent-hint return-object single-line dense outlined
								:value="editCacheConfig.configValue" v-model="editCacheConfig.configValue" />

							<v-text-field v-else-if="editCacheConfig.configType.toUpperCase() === 'INTEGER'"
								v-model="editCacheConfig.configValue" :label="editCacheConfig.configValue" type="number"
								:rules="required" outlined dense single-line persistent-hint />

							<v-text-field v-else-if="editCacheConfig.configType.toUpperCase() === 'STRING'"
								v-model="editCacheConfig.configValue" :label="editCacheConfig.configValue"
								:rules="required" outlined dense single-line persistent-hint />
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-spacer />
			<v-btn color="red darken-1" text @click="close" :disabled="loading" class="custom-button">
				Cancel
			</v-btn>
			<v-btn color="blue darken-1" class="custom-button" text @click="updateCacheConfig" :loading="loading"
				:disabled="!valid || loading || !isConfigValueUpdated">
				Save
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "CoreConfigForm",
	props: {
		editCacheConfig: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			loading: false,
			valid: false,
			required: [(v) => !!v || "this field is required"]
		};
	},

	computed: {
		...mapGetters("contentManagement", ["cacheConfigs"]),
		isConfigValueUpdated() {
			return !(this.cacheConfigs.find((config) => config.configKey === this.editCacheConfig.configKey).configValue
				.toLowerCase() == this.editCacheConfig.configValue.toLowerCase());
		}
	},

	methods: {
		close() {
			this.error = this.editCacheConfig = null;
			this.$refs.form.resetValidation();
			this.$emit("close");
		},
		async updateCacheConfig() {
			this.loading = true;
			await this.$store.dispatch("contentManagement/updateCacheConfig", this.editCacheConfig);
			this.loading = false;
			this.$emit("reloadCacheConfigs");
			this.close();
		}
	}
};
</script>

<style scoped>
.custom-button {
	border-radius: 3.125rem;
}
</style>
