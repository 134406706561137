import { SEGMENTS } from "../modules/posts/routes";
export default {
  computed: {
    urlSegments() {
      return this.$route.fullPath.split("/");
    },
    rootPostId() {
      return this.getIdParamByPreviousSegment(SEGMENTS.ROOT_POST);
    },
    postId() {
      return this.getIdParamByPreviousSegment(SEGMENTS.POST);
    },
    rootContentTypeId() {
      return this.getIdParamByPreviousSegment(SEGMENTS.ROOT_TYPE);
    },
    contentTypeId() {
      return this.getIdParamByPreviousSegment(SEGMENTS.TYPE);
    },
  },
  methods: {
    getIdParamByPreviousSegment(previousSegment) {
      const index = this.urlSegments.indexOf(previousSegment);
      return isNaN(parseInt(this.urlSegments[index + 1]))
        ? null
        : parseInt(this.urlSegments[index + 1]);
    },
    getPostFormRoute(rootPostId, contentTypeId, postId) {
      return {
        name: "SubPostEntry",
        params: {
          postId: rootPostId,
          childTypeId: contentTypeId,
          childPostId: postId,
        },
      };
    },
  },
};
