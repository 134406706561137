<template>
  <v-container fluid>
    <v-toolbar flat>
      <template>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          class="mb-2 custom-button"
          @click="downloadExcelsheet"
        >
          export
        </v-btn>
      </template>
    </v-toolbar>
    <!-- DATATABLE -->
    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          :headers="careersHeaders"
          :items-per-page="pageSize"
          :items="careers"
          class="capitalize large"
        >
          <template #item.careerCv="{item}">
            <v-btn class="ma-2" :href="item.careerCv" download>
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <router-link
              :to="{ name: 'CareersDetails', params: { id: item.id } }"
            >
              <v-icon class="mr-4" medium>
                mdi-page-next
              </v-icon>
            </router-link>
            <v-icon
              medium
              color="gray darken-4 mr-6"
              @click="deleteCareers(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:top>
            <v-dialog v-model="confirmationDialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">
                  {{ confirmationMessage }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red darken-1"
                    class="custom-button"
                    text
                    @click="closeConfirmationDialog"
                    :disabled="loading"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="loading"
                    color="blue darken-1 white--text"
                    class="custom-button"
                    @click="deleteCareersConfirmed"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Pagination -->

    <v-row
      class="justify-end mt-6 align-center"
      v-if="careersPaginated.totalNumberOfPages > 1"
    >
      <div class="mr-2">
        <v-select
          class="page-size-selector"
          label="page size"
          outlined
          dense
          hide-details
          :items="pageSizeOptions"
          :value="10"
          @change="pageSize = parseInt($event, 10)"
        />
      </div>
      <div class="mr-2">
        <span>{{ pageNumber }}</span>
        <span class="mx-1">From</span>
        <span>{{ careersPaginated.totalNumberOfPages }}</span>
      </div>
      <v-pagination
        class="align-self-start py-2"
        v-model="pageNumber"
        :length="careersPaginated.totalNumberOfPages"
      />
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      toBeDeleteCareer: null,
      snackbarText: ``,
      confirmationDialogDelete: false,
      confirmationMessage: "",
      snackbar: false,
      loading: false,
      pageSize: 10,
      pageNumber: 1,
      pageCategorySelected: null,
      pageSizeOptions: [5, 10, 15, 20],
      careersHeaders: [
        {
          text: "name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "position",
          align: "start",
          sortable: false,
          value: "position",
        },
        {
          text: "mobile Number",
          align: "start",
          sortable: false,
          value: "phone",
        },

        {
          text: "cv",
          align: "center",
          sortable: false,
          value: "careerCv",
        },
        {
          text: "Actions",

          value: "actions",
        },
      ],
    };
  },
  async mounted() {
    await this.loadCareers();
  },

  watch: {
    async pageNumber() {
      await this.loadCareers();
    },
    async pageSize() {
      if (this.pageSize < 1 || this.pageSize > 20) {
        this.pageSize = 10;
      }
      await this.loadCareers();
    },
  },

  computed: {
    ...mapGetters("careers", ["careersPaginated"]),
    careers: {
      get() {
        return this.careersPaginated.result;
      },
    },
  },
  methods: {
    deleteCareers(item) {
      this.toBeDeleteCareer = item;
      this.confirmationDialogDelete = true;
      this.confirmationMessage = "Are you sure you want to delete this career?";
    },
    deleteCareersConfirmed() {
      try {
        this.snackbar = true;
        this.$store.dispatch("careers/deleteCareers", this.toBeDeleteCareer);
        this.closeConfirmationDialog();
        this.snackbarText = "Deleted career us successfully";
      } catch (error) {
        this.snackbar = true;
        this.snackbarText = error;
      } finally {
        this.closeConfirmationDialog();
        this.snackbar = true;
        this.loading = false;
        this.toBeDeleteCareers = null;
      }
    },
    closeConfirmationDialog() {
      this.confirmationDialogDelete = false;
      this.toBeDeleteCareer = null;
    },
    async loadCareers() {
      let payload = {
        sortingByList: [{ fieldName: "id", direction: "DESC" }],
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      await this.$store.dispatch("careers/loadCareersPaginated", payload);
    },
    openCareersDetails() {
      this.$router.push("/career-datails");
    },
    async downloadExcelsheet() {
      this.export = true;
      const res = await this.$store.dispatch("careers/downloadCareersExcel");
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "careers.xlsx");
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss">
.text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.page-size-selector {
  width: 75px;
}
.table-titles {
  font-size: 16px;
}
.custom-button {
  border-radius: 3.125rem;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: large;
  color: black;
}
</style>
