export default {
  username(state) {
    return state.user.username;
  },
  roles(state) {
    return state.user.roles;
  },
  accessToken(state) {
    return state.user.accessToken;
  },
  idToken(state) {
    return state.user.idToken;
  },
  isAuthenticated(state) {
    return !!state.user.token;
  },
  groups(state) {
    return state.user.group;
  },
  clientName(state) {
    return state.user.clientName;
  },
  users(state) {
    return state.users;
  },
  allRoles(state) {
    return state.roles;
  },
};
