import NetworkManager from "../../../network/network-manager";
import Parser from "../network/parser";
import Vue from "vue";

export default {
  // /setting/Applications

  async loadTicket(context, payload) {
    // let headers= {    headers: {
    //   "Authorization": `Bearer ${store.getters["auth/accessToken"]}`
    //   }};
    await NetworkManager.post(
      context,
      `ticket/paginated`,
      payload,
      "ticket",
      "setTicket",
      Parser,
      null,
      Vue.prototype.$ticketAxios
    );
  },
  async createTicket(context,payload){
   return await NetworkManager.post(
      context,
      "/ticket",
      payload,
      "ticket",
      "addTicket",
      Parser,
      null,
      Vue.prototype.$ticketAxios,
    )
  },
  async loadCommentsByTicketId(context, payload) {
    await NetworkManager.get(
      context,
      `/comment/ticket/${payload}`,
      "comment",
      "setComment",
      Parser,
      null,
      Vue.prototype.$ticketAxios
    );
  },
  async addComment(context, payload) {
    // let headers= {    headers: {
    //   "Authorization": `Bearer ${store.getters["auth/accessToken"]}`
    //   }};
    return await NetworkManager.post(
      context,
      "/comment",
      payload,
      "comment",
      "addComment",
      Parser,
      null,
      Vue.prototype.$ticketAxios
    );
  },
  async uploadImage(context, payload) {
    return await NetworkManager.post(
      context,
      `/comment-file`,
      payload,
      "comment",
      "setCommentImage",
      Parser,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      Vue.prototype.$ticketAxios
    );
  },
  async uploadTicketImage(context,payload){
    return await NetworkManager.post(
      context,
      `/ticket-file`,
      payload,
      "ticket",
      null,
      Parser,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      Vue.prototype.$ticketAxios
    );

  },
  async loadTicketById(context , payload){
    await NetworkManager.get(
      context,
      `/ticket/${payload}`,
      "ticket",
      "setTicketById",
      Parser,
      null,
      Vue.prototype.$ticketAxios
    );
  }
};
