import ContentTypeListing from "./views/settings/ContentTypeListing.vue";
import LanguageListing from "./views/settings/LanguageListing.vue";
import ContentCategoryListing from "./views/settings/ContentCategoryListing.vue";
import MenuCategory from "./views/settings/MenuCategory.vue"
import PageCategory from "./views/settings/PageCategory.vue"
import ImageType from "./views/settings/ImageType.vue"
import OrderContentType from "./views/settings/OrderContentType.vue"
import ContentCategory from "./views/settings/ContentCategory"
import ContactUsLookup from "./views/settings/ContactUsLookup.vue"
import Users from "../auth/views/users/usersListing.vue"
import Caching from "./views/settings/Caching.vue"

export const settingRouters = [
  {
    path: "/settings/language",
    name: "LanguageListingListing",
    component: LanguageListing,
  },
  {
    path: "/settings/content-type",
    name: "ContentTypeListing",
    component: ContentTypeListing,
  },
  {
    path: "/settings/order/content-type",
    name: "ContentTypeOrder",
    component: OrderContentType,
  },
  {
    path: "/settings/contact-us",
    name: "ContactUsLookup",
    component: ContactUsLookup,
  },
  {
    path: "/content-category",
    name: "ContentCategory",
    component: ContentCategory,
  },
  {
    path: "/settings/content-category",
    name: "ContentCategoryListing",
    component: ContentCategoryListing,
  },
  {
    path: "/settings/menu-category",
    name: "MenuCategory",
    component: MenuCategory,
  },
  {
    path: "/settings/page-category",
    name: "PageCategory",
    component: PageCategory,
  },
  {
    path: "/settings/image-type",
    name: "ImageType",
    component: ImageType,
  },
  {
    path:"/users",
    name:"Users",
    component:Users
  },
  {
    path: "/settings/caching",
    name: "Caching",
    component: Caching,
  }
];
