import { Language, Post } from "./index";
export default class LocalizedPost {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.language = rawData.language ? new Language(rawData.language) : null;
    this.locale = rawData.locale;
    this.post = rawData.post ? new Post(rawData.post) : null;
    this.postId = rawData.postId;
    this.title = rawData.title;
    this.shortDescription = rawData.shortDescription;
    this.htmlDescription = rawData.htmlDescription;
    this.fullDescription = rawData.fullDescription;
    this.isActive = rawData.isActive;
    this.createdDate = rawData.createdDate;
    this.customTitle1 = rawData.customTitle1;
    this.customTitle2 = rawData.customTitle2;
    this.customDescription = rawData.customDescription;
  }
}
