<template>
  <v-card>
    <v-btn color="primary" class="create-tenant" to="/create-tenant">
      Create Tenant
    </v-btn>
    <v-data-table
      :headers="headers"
      :items="tenants"
      sort-by="name"
      class="elevation-1"
      v-if="tenants.length > 0"
    >
      <!--eslint-disable -->
      <template v-slot:item.cpanel="{ item }">
        <v-btn color="primary" @click="loginTenant(item)">CMS</v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <router-link :to="/post/ + item.id">
              <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </router-link>
          </template>
          <span>Edit</span>
        </v-tooltip> -->
        <v-tooltip v-if="item.isActive" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              @click="manageStatus(item, true)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Remove</span>
        </v-tooltip>
        <!-- <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              @click="manageStatus(item, false)"
              v-bind="attrs"
              v-on="on"
            >
              mdi-launch
            </v-icon>
          </template>
          <span>Activate</span>
        </v-tooltip> -->
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">{{ confirmMSG }}</v-card-title>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-snackbar>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import router from "../../../../router";

export default {
  title: "Tenants",
  data() {
    return {
      isLoading: true,
      snackbar: false,
      snackbarText: ``,
      confirmMSG: "",
      dialogDelete: false,
      isManageActiveStatus: false,

      headers: [
        {
          text: "Tenant Code",
          align: "start",
          value: "tenantCode",
        },
        // { text: "User Name", value: "userName" },
        { text: "Tenant Name", value: "tenantName" },
        // { text: "Database URL", value: "dbUrl" },
        // { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "cpanel", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters("tenant", ["tenants", "tenantCode"]),
  },
  mounted() {
    this.setTenantCode(localStorage.getItem("tenantCode"));
  },
  async created() {
    await this.loadTenants();
  },
  methods: {
    async loadTenants() {
      await this.$store.dispatch("tenant/loadTenants");
    },
    async loginTenant(tenant) {
      localStorage.setItem("tenantCode", tenant.tenantCode);
      this.setTenantCode(tenant.tenantCode);
      router.push("/");
      window.location.reload();
    },
    setTenantCode(tenantCode) {
      this.$store.commit("tenant/setTenantCodeSelected", tenantCode);
    },
  },
};
</script>
<style scoped>
.create-tenant {
  margin: 3rem 0 0 1rem;
}
.custom-button {
  border-radius: 3.125rem;
}
</style>
