import { render, staticRenderFns } from "./ViewS3.vue?vue&type=template&id=f6cf5bb6&scoped=true"
import script from "./ViewS3.vue?vue&type=script&lang=js"
export * from "./ViewS3.vue?vue&type=script&lang=js"
import style0 from "./ViewS3.vue?vue&type=style&index=0&id=f6cf5bb6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6cf5bb6",
  null
  
)

export default component.exports