import { ContentType, ContentCategory } from "./index";

export default class ContentTypeCategory {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.contentType = rawData.contentType
      ? new ContentType(rawData.contentType)
      : null;
    this.contentTypeId = rawData.contentTypeId;
    this.contentCategory = rawData.contentCategory
      ? new ContentCategory(rawData.contentCategory)
      : null;
    this.contentCategoryId = rawData.contentCategoryId;
    this.minAllowedNoOfCategories = rawData.minAllowedNoOfCategories;
    this.maxAllowedNoOfCategories = rawData.maxAllowedNoOfCategories;
    this.isFeatured = rawData.isFeatured;
  }
}
