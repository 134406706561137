export default class RegisterForm {
    constructor(rawData = {}) {
      this.id = rawData.id;
      this.firstName = rawData.firstName
      this.lastName = rawData.lastName
      this.email = rawData.email
      this.mobileNumber = rawData.mobileNumber
      this.address = rawData.address
      this.companyName = rawData.companyName
      this.jobTitle = rawData.jobTitle
      this.knowUs = rawData.knowUs
      this.city = rawData.city
      this.country = rawData.country
      this.function = rawData.function
      this.reason = rawData.reason
      this.corporateCustomerConference = rawData.corporateCustomerConference
      this.privateCustomerConference = rawData.privateCustomerConference
      this.interestedButNotFinally = rawData.interestedButNotFinally
      this.consent = rawData.consent
    }
  }