<template>
  <div>
    <v-expansion-panels v-model="panel" :readonly="false" multiple>
      <v-expansion-panel v-if="currentTicket && currentTicket.title != null">
        <v-expansion-panel-header class="font-weight: bold;"
          >Details</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="6"> Title : {{ currentTicket.title }} </v-col>
            <v-col cols="6"
              >Description : {{ currentTicket.description }}</v-col
            >
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel
        v-if="currentTicket && currentTicket.ticketFiles.length > 0"
      >
        <v-expansion-panel-header>Images</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col
              cols="3"
              v-for="(item, index) in currentTicket.ticketFiles"
              :key="index"
            >
              <v-img :src="item.fileUrl" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  async mounted() {
    await this.loadTicketById(this.$route.params.id);
  },
  data() {
    return {
      panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
  },
  computed: {
    ...mapGetters("ticket", ["currentTicket"]),
  },
  methods: {
    async loadTicketById(id) {
      await this.$store.dispatch("ticket/loadTicketById", id);
    },
  },
};
</script>
