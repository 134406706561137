export const settingNavBar = [
  {
    text: "Setting",
    icon: "mdi-nut",
    items: [
      {
        text: "Language",
        to: `/settings/language`,
      },
      {
        text: "Content Type",
        to: `/settings/content-type`,
      },
      {
        text: "Content Type Order",
        to: `/settings/order/content-type`,
      },
      {
        text: "Content Category",
        to: `/settings/content-category`,
      },
      {
        text: " Contact us Lookup",
        to: `/settings/contact-us`,
      },
      {
        text: " Contact us Columns ",
        to: `/settings/contact-us-columns`,
      },
      {
        text: " Register form Columns ",
        to: `/settings/register-form-columns-names`,
      },
      {
        text: "Menu Category",
        to: `/settings/menu-category`,
      },
    
      {
        text: "Page Category",
        to: `/settings/page-category`,
      },
      {
        text: "Image Types",
        to: `/settings/image-type`,
      },
      {
        text:"Users",
        to:"/setting/users"
      },
      {
        text:"Caching",
        to: "/settings/caching"
      }
    ],
  },
];
