<template>
  <v-container fluid>
    <!-- DATATABLE -->
    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          :headers="subscribeHeader"
          :loading="loading"
          :items="subscribes"
          class="capitalize"
        >
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="red darken-4" small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>


          <template v-slot:top>
            <v-dialog v-model="confirmationDialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">
                  {{ confirmationMessage }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red darken-1"
                    class="custom-button"
                    text
                    @click="closeConfirmationDialog"
                    :disabled="loading"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="loading"
                    color="blue darken-1 white--text"
                    class="custom-button"
                    @click="deletedConfirmed"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      snackbarText:"",
      snackbar:false,
      toBeDeleted:null,
      confirmationDialogDelete:false,
      confirmationMessage:"",
      subscribeHeader: [
        {
          text: "email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "creation Date",
          align: "start",
          sortable: false,
          value: "creationDate",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
        

      ],
    };
  },
  async mounted() {
    this.loading = true;
    await this.loadSubscribe();
    this.loading = false;
  },

  computed: {
    ...mapGetters("subscribe" , ["subscribes"])
  },
  methods: {
    async loadSubscribe() {
      await this.$store.dispatch("subscribe/loadSubscribes");
    },
    deleteItem(item) {
      this.toBeDeleted = item;
      this.confirmationDialogDelete = true;
      this.confirmationMessage =
        "Are you sure you want to delete this?";
    },

    deletedConfirmed() {
      try {
        this.snackbar = true;
        this.$store.dispatch(
          "subscribe/deleteItem",
          this.toBeDeleted
        );
        this.closeConfirmationDialog();
        this.snackbarText = "Deleted successfully";
      } catch (error) {
        this.snackbar = true;
        this.snackbarText = error;
      } finally {
        this.closeConfirmationDialog();
        this.snackbar = true;
        this.loading = false;
        this.toBeDeleted = null;
      }
    },
    closeConfirmationDialog() {
      this.confirmationDialogDelete = false;
    },
 
  },
};
</script>
<style lang="scss">
.contact-us-file {
  text-decoration: underline;
}
.text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.page-size-selector {
  width: 75px;
}
.v-data-table {
  font-size: large;
  color: black;
}
</style>
