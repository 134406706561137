// import { Post } from "../models";

export default {
  setPaginatedContactUs(state, payload) {
    state.contactUsPaginated = payload;
  },
  deleteContactUs(state, payload) {
    let index = state.contactUsPaginated.result.findIndex(x => x.id == payload.id);
    state.contactUsPaginated.result.splice(index, 1);
  },
  setContactUsColumn(state,payload){
    state.contactUsColumns = payload;
  },
  setContactUsConfig(state,payload){
    state.cotactUsConfig = payload;
    state.cotactUsConfigObject = payload?.[0]
  }
}