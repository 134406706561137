import { Language, Page } from "./index";

export default class LocalizedPage {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.language = rawData.language ? new Language(rawData.language) : null;
    this.page = rawData.page ? new Page(rawData.page) : null;
    this.name = rawData.name;
    this.title = rawData.title;
    this.description = rawData.description;
    this.keyWord = rawData.keyWord;
    this.indexing = rawData.indexing;
    this.locale = rawData.locale;
    this.pageId = rawData.pagetId;
  }
}
