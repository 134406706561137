<template>
  <div class="container">
    <v-row>
      <v-col class="d-flex justify-center">
        <h1 class="secondary--text">Add Tenant</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-form v-model="tenantFormValid" ref="tenantForm">
          <h2>Tenant</h2>

          <v-text-field
            v-model="tenant.tenantName"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              Tenant Name
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field v-model="tenant.userName" :rules="required" type="text">
            <template v-slot:label>
              Database User Name
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="tenant.password"
            :rules="required"
            type="password"
          >
            <template v-slot:label>
              Database Password
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field v-model="tenant.dbUrl" :rules="required" type="text">
            <template v-slot:label>
              Database URL
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>
          <v-text-field
            v-model="tenant.databaseName"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              DataBase Name
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-row>
            <v-col cols="6">
              <v-checkbox
                v-model="tenant.enabled"
                label="Enabled"
                color="primary"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!isSaving" class="d-flex flex-row-reverse" cols="8">
        <v-btn
          color="black darken-1 white--text"
          class="custom-button"
          @click="createTenant"
          :disabled="!tenantFormValid"
        >
          Save
        </v-btn>
      </v-col>
      <v-col v-else class="d-flex flex-row-reverse" cols="8">
        <v-progress-circular
          indeterminate
          color="deep-orange lighten-2"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-snackbar v-model="showSnackbar" absolute>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay :value="isSaving"></v-overlay>
  </div>
</template>
<script>
import Tenant from "../../models/Tenant";
export default {
  title: "Add Tenant",
  data() {
    return {
      valid: false,
      tenant: new Tenant(),
      isSaving: false,
      tenantFormValid: true,
      required: [(v) => !!v || "This field is required"],
      showSnackbar: false,
      snackbarText: "",
    };
  },
  methods: {
    async createTenant() {
      this.errors = {};
      try {
        await this.$store.dispatch("tenant/addTenant", this.tenant);
        this.showSnackbar = true;
        this.snackbarText = "Data Added Successfully";
      } catch (err) {
        this.showSnackbar = true;
        this.snackbarText = err;
      }
    },
  },
};
</script>
<style scoped>
.container {
  padding-left: 1rem;
}
.custom-button {
  border-radius: 3.125rem;
}
</style>
