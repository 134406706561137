import { Language, ContentCategory } from "./index";

export default class LocalizedContentCategory {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.categoryName = rawData.categoryName;
    this.description = rawData.description;
    this.isActive = rawData.isActive;
    this.contentCategoryId = rawData.contentCategoryId;
    this.language = rawData.language ? new Language(rawData.language) : null;
    this.locale = rawData.locale;
    this.contentCategory = rawData.ContentCategory
      ? new ContentCategory(rawData.contentCategory)
      : null;
    this.isActive = rawData.isActive;
  }
}
