export default class User {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.username = rawData.username;
    this.firstName = rawData.firstName;
    this.lastName = rawData.lastName;
    this.phoneNumber = rawData.phoneNumber;
    this.clientName = rawData.clientName;
    this.idToken = rawData.idToken;
    this.roles = rawData.roles;
    this.group = rawData.group;
    this.password = rawData.password;
    this.enabled = rawData.enabled;
  }
}
