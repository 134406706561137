import { Tenant } from "../models/index";

export default class Parser {
  static parse(type, data) {
    switch (type) {
      case "Tenant":
        if(data == null){
          return "success"
        }
        return this.fromJson(Tenant, data);
      default:
        return data;
    }
  }
  static fromJson(type, data) {
    if(!data){
      return "success"
    }
    if (data.constructor === Array) {
      const result = [];
      data.forEach((element) => {
        result.push(new type(element));
      });
      return result;
    } else return new type(data);
  }
}
