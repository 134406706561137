import Vue from "vue";
import NetworkManager from "../../../network/network-manager";
import Parser from "../network/parser.js";

export default {
  async loadContactUsPaginated(context, payload) {
    await NetworkManager.post(
      context,
      `contact-us/find-all-paginated-and-filtered`,
      payload,
      "PaginationResponse",
      "setPaginatedContactUs",
      Parser
    );
  },
  async loadContactUsColumns(context){
    await NetworkManager.get(context, `contact-us-column-name`, "ContactUsColumn", "setContactUsColumn", Parser);
  },
  async loadContactUsConfig(context){
    await NetworkManager.get(context, `contact-us-config`, "ContactUsConfig", "setContactUsConfig", Parser);

  },
 async editContactUsColumnConfig(context , payload){
  await NetworkManager.put(
    context,
    `contact-us-config/${payload.id}`,
    payload,
    "ContactUsConfig",
    null,
    Parser
  );
 },
 async downloadContactUsExcel() {
  return await NetworkManager.download(`/contact-us/export`);
},
  async createContactUsColumnConfig(context , payload){
    await NetworkManager.post(
      context,
      `contact-us-config`,
      payload,
      "ContactUsConfig",
      "setContactUsConfig",
      Parser
    );
 },
  async deleteContactUs(context, payload) {
    await NetworkManager.put(
      context,
      `contact-us/marked-as-deleted/${payload.id}`,
      payload,
      null,
      "deleteContactUs",
      Parser
    );
  },
  async editContactUsColumnName(context,payload){
    await NetworkManager.put(
      context,
      `contact-us-column-name/${payload.id}`,
      payload,
      "ContactUsColumn",
      null,
      Parser
    );
  },
  async contactUsColumnsChangeStatus(context , payload){
    await NetworkManager.put(
      context,
      `contact-us-column-name/active-status`,
      payload,
      "ContactUsColumn",
      null,
      Parser
    );
  },
  async loadTickets(context) {
    await NetworkManager.get(
      context,
      `ticket`,
      null,
      null,
      Parser,
      null,
      Vue.prototype.$ticketAxios
    );
  },
};
