import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      tenants: [],
      tenant:null,
      tenantCodeSelected: null,
      websiteS3: [],
      selectedWebsiteS3: null,
      mailSender: [],
      selectedmailSender: null
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
