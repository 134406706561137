
export default class WebsiteS3 {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.accessKey = rawData.accessKey;
    this.secretKey = rawData.secretKey;
    this.s3URL = rawData.s3URL;
    this.cdnURL = rawData.cdnURL;
    this.bucketName = rawData.bucketName;
    this.region = rawData.region;

  }
}
