<template>
  <Fragment>
    <Fragment :key="i" v-for="(item, i) in contentTypes">
      <!-- IF THERE ARE NO MORE CHILDS -->
      <v-list-item
        v-if="item.children.length === 0"
        :key="item.id"
        :to="{
          name: 'ChildContentTypePosts',
          params: { postId: postId, childTypeId: item.id },
        }"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-checkbox-blank-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-title v-text="item.type"></v-list-item-title>
      </v-list-item>

      <!-- CREATE SUB GROUP FOR THE TREE -->
      <v-list-group sub-group link v-if="item.children.length > 0">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ item.type }}</v-list-item-title>
          </v-list-item-content>
          <ContentMenuGroup :contentTypes="item.children" />
        </template>
      </v-list-group>
    </Fragment>
  </Fragment>
</template>

<script>
import { Fragment } from "vue-fragment";

export default {
  name: "ContentMenuGroup",
  components: {
    Fragment,
  },
  props: {
    contentTypes: {
      type: Array,
      required: true,
    },
    postId: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item__title {
  font-size: 0.85rem;
}
.v-icon {
  font-size: 6px;
  margin: auto;
  padding-top: 5px;
}
.v-list-item__icon {
  margin-right: 12px !important;
}
</style>
