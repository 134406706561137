import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const theme = {
    primary: '#000000',
    secondary: '#FFFFFF',
    accent: '#9C27b0',
    info: '#9E9E9E',
    headerText:'#FFFFFF',
    background: '#00a86b',
    warning: '#e91e63',

  }
  
  export default new Vuetify({
    // rtl: true
    theme: {
      themes: {
        dark: theme,
        light: theme,
      },
    },
  })

