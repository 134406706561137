import {
  LocalizedContentCategory,
  Language,
  ContentType,
  ContentTypeCategory,
} from "../models/index";

export default class Parser {
  static parse(type, data) {
    switch (type) {
      case "Language":
        return this.fromJson(Language, data);
      case "ContentType":
        return this.fromJson(ContentType, data);
      case "ContentTypeCategory":
        return this.fromJson(ContentTypeCategory, data);
      case "LocalizedContentCategory":
        return this.fromJson(LocalizedContentCategory, data);
      default:
        return data;
    }
  }
  static fromJson(type, data) {
    if (data.constructor === Array) {
      const result = [];
      data.forEach((element) => {
        result.push(new type(element));
      });
      return result;
    } else return new type(data);
  }
}
