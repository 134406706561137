import { APIs_URL, TICKET_API_URLS } from "../constants";
import axios from "axios";
import updateToken from "@/middlewares/update-token";
import store from "../store/index";
import Vue from "vue";

const requestInterceptor = async (config) => {
  let token = null;
  if (await store.getters["auth/accessToken"]) {
    token = await updateToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  if (
    store.getters["auth/groups"] != null ||
    localStorage.getItem("tenantCode") != null
  ) {
    config.headers.common["X-TENANT-ID"] = localStorage.getItem("tenantCode")
      ? `${localStorage.getItem("tenantCode")}`
      : `${store.getters["auth/groups"]}`;
  }
  return config;
};

const responseInterceptorOnSuccuess = (response) => {
  return response;
};

const responseInterceptorOnFailure = (error) => {
  throw new Error(
    (error.response && error.response.data && error.response.data.message) ||
      "something wrong happened"
  );
};

// DEFAULT AXIOS SETTINGS
axios.defaults.baseURL =
  process.env.NODE_ENV === "production"
    ? APIs_URL.PRODUCTION
    : APIs_URL.STAGING;

axios.interceptors.request.use(requestInterceptor);

axios.interceptors.response.use(
  responseInterceptorOnSuccuess,
  responseInterceptorOnFailure
);


// TICKET AXIOS SETTINGS
const TicketAxiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? TICKET_API_URLS.PRODUCTION
      : TICKET_API_URLS.STAGING,
});

TicketAxiosInstance.interceptors.response.use(
  responseInterceptorOnSuccuess,
  responseInterceptorOnFailure
);

Vue.prototype.$ticketAxios = TicketAxiosInstance;
