import NetworkManager from "../../../network/network-manager";
import Parser from "../network/parser.js";



export default {
  async addTenant(context, payload) {
    await NetworkManager.post(context, `tenants`, payload, "Tenant", null, Parser);
  },
  async loadTenants(context) {
    await NetworkManager.get(context, `tenants`, "Tenant", "setTenant", Parser);
  },
  async loadTenant(context,payload){
    await NetworkManager.get(context, `tenants/${payload}`, "Tenant", "setCurrentTenant", Parser);

  },
  async addWebsiteS3(context, payload) {
    let tenantCode = context.getters["tenantCode"]
    context.rootState.auth.user.group = tenantCode;
    await NetworkManager.post(context, `website-s3`, payload, "WebsiteS3", null, Parser);
  },
  async loadMailSender(context) {
    let tenantCode = context.getters["tenantCode"]
    console.log("mail" , tenantCode)
    context.rootState.auth.user.group = tenantCode;
    await NetworkManager.get(context, `mail-sender`, "MailSender", "setMailSender", Parser);
  },
  async loadWebsiteS3(context) {
    let tenantCode = context.getters["tenantCode"]
    context.rootState.auth.user.group = tenantCode;
    await NetworkManager.get(context, `website-s3`, "WebsiteS3", "setWebsiteS3", Parser);
  },
  async editWebsiteS3(context, payload) {
    await NetworkManager.put(context, `website-s3`, payload, "WebsiteS3", "editWebsiteS3", Parser);
  },
  async addMailSenderConfig(context, payload) {
    let tenantCode = context.getters["tenantCode"]
    context.rootState.auth.user.group = tenantCode;
    await NetworkManager.post(context, `mail-sender`, payload, "MailSender", "setMailSender", Parser);
  },







}