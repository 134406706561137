import "./styles/style.scss";
import '@mdi/font/css/materialdesignicons.css'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import updateToken from "@/middlewares/update-token";
import "@/plugins/axios";
import "@/plugins/keycloak";
import Fragment from "vue-fragment";

Vue.use(Fragment.Plugin);
Vue.config.productionTip = false;

Vue.$keycloak
  .init({ onLoad: "login-required" })
  .then((auth) => {
    if (!auth) {
      localStorage.removeItem("loggedIn");
      window.location.reload();
    } else {
      new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App, { props: { keycloak: Vue.$keycloak } }),
      }).$mount("#app");

			if (
				Vue.$keycloak.token &&
				Vue.$keycloak.idToken &&
				Vue.$keycloak.token != "" &&
				Vue.$keycloak.idToken != ""
			) {
				localStorage.setItem("loggedIn", true);				
				let payload = {
					username: Vue.$keycloak.subject,
					clientName: Vue.$keycloak.idTokenParsed.clientName,
					roles: Vue.$keycloak.tokenParsed.realm_access.roles,
					idToken: Vue.$keycloak.idToken,
					accessToken: Vue.$keycloak.token,
					group:
						Vue.$keycloak.tokenParsed.realm_access.roles[0] == "client-user" ||
						Vue.$keycloak.tokenParsed.realm_access.roles[0] == "client-admin"
							? Vue.$keycloak.tokenParsed?.group[0].substring(1)
							: null
				};
				store.dispatch("auth/login", payload);
				if(Vue.$keycloak.tokenParsed.group.length>0)
				{
				localStorage.setItem("tenantCode", Vue.$keycloak.tokenParsed?.group[0].substring(1));
				}
				
			}
		}

    window.onfocus = () => {
      updateToken();
    };
  })
  .catch(() => {
    Vue.$log.error("Authenticated Failed");
  });
