// import { Post } from "../models";

export default {
  setPages(state, payload) {
    state.pages = payload;
  },
  createPage(state, payload) {
    state.selectedPage = payload;
  },
  editPage(state, payload) {
    state.selectedPage = payload;
  },
  setPageById(state, payload) {
    state.selectedPage = payload;
  },
  setLocalizedPageById(state, payload) {
    state.selectLocalizedPage = payload;
  },
  setImageUrl(state, payload) {
    let pageSelected = state.pages.find((x) => x.id == payload.id);
    pageSelected.imgUrl = payload.imgUrl;
  },
  setselectedPageEmpty(state) {
    state.selectedPage = null;
    state.selectLocalizedPage = null;
  },
  setPaginatedSeo(state, payload) {
    state.seoPaginated = payload;
  },
  // /////////////////////////
  setLocalizedPages(state, payload) {
    state.localizedPages = payload;
  },
  setLocalizedPage(state, payload) {
    if (state.localizedPages?.length)
      state.localizedPages = [...state.localizedPages, payload];
    else state.localizedPages = [payload];
  },
  setselectedPage(state, payload) {
    state.selectedPage = payload;
  },

  setPageCategory(state, payload) {
    state.pageCategory = payload;
  },
  addPageCategory(state, payload) {
    state.pageCategory.push(payload);
  },
  setPagesToPageCategory(state, payload) {
    let pageCategory = state.pageCategory.find((item) => item.id == payload.id);
    pageCategory.pages = payload.pages;
  },
  setPageCategoryById(state, payload) {
    state.selectedPageCategory = payload;
  },
  deletePageCategory(state, payload) {
    let index = state.pageCategory.findIndex((x) => x.id == payload.id);
    state.pageCategory.splice(index, 1);
  },
};
