<template>
  <v-card>
    <v-select
      class="selectBox"
      v-model="tenantCodeSelected"
      :items="tenants"
      item-text="tenantName"
      :item-value="tenants.tenantCode"
      @change="getSizeConfigurationData"
      label="Select"
      persistent-hint
      return-object
      single-line
    ></v-select>

    <v-data-table
      :headers="headers"
      :items="sizeConfiguration"
      sort-by="name"
      class="elevation-1"
      v-if="sizeConfiguration && sizeConfiguration.length > 0"
    >
      <!--eslint-disable -->
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <router-link
              :to="{ name: 'editSizeConfiguration', params: { id: item.id } }"
            >
              <v-icon small class="mr-2" v-bind="attrs" v-on="on">
                mdi-pencil
              </v-icon>
            </router-link>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-snackbar>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  title: "Tenants",
  data() {
    return {
      tenantCodeSelected: null,
      snackbar: false,
      snackbarText: ``,
      headers: [
        {
          text: "Image Size",
          align: "start",
          value: "imageSize",
        },
        {
          text: "File Size",
          align: "start",
          value: "fileSize",
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters("tenant", ["tenants", "tenantCode"]),
    ...mapGetters("post", ["sizeConfiguration"]),
  },
  async mounted() {
    await this.setSizeConfigurationNull();
    await this.loadTenants();
  },
  methods: {
    async setSizeConfigurationNull() {
      await this.$store.commit("post/setSizeConfiguration", null);
    },
    async loadTenants() {
      await this.$store.dispatch("tenant/loadTenants");
    },
    async getSizeConfigurationData() {
      await this.loadSizeConfiguration();
    },
    async loadSizeConfiguration() {
      await this.$store.commit(
        "tenant/setTenantCodeSelected",
        this.tenantCodeSelected.tenantCode
      );
    //   console.log("tenantCodeSelected", this.tenantCodeSelected);
        await this.$store.dispatch("post/loadSizeConfiguration");
      console.log("sizeConfiguration", this.sizeConfiguration);
    },
  },
};
</script>
<style scoped>
.selectBox {
  padding: 2rem;
}
.create-websiteS3 {
  color: white;
  margin: 2rem;
}
.custom-button {
  border-radius: 3.125rem;
}
</style>
