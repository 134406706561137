import Vue from "vue";
import VueRouter from "vue-router";
import { settingRouters } from "../modules/settings/routes.js";
import { postRouters } from "../modules/posts/routes.js";
import { pageRouters } from "../modules/page/routes.js";
import { tenantRouters } from "../modules/tenant/routes.js";
import { contactUsRouters } from "../modules/contactUs/routes.js";
import { careersRouters } from "../modules/careers/routes.js";
import {usersRouters} from "../modules/auth/routes.js";
import { ticketsRouters} from "../modules/tickets/routes.js"
import {registerFormRouters} from "../modules/register-form/routes.js"
import {subscribeRouters} from "../modules/subscribe/routes.js"

Vue.use(VueRouter);

const routes = [
	...settingRouters,
	...postRouters,
	...tenantRouters,
	...pageRouters,
	...contactUsRouters,
	...careersRouters,
	...usersRouters,
	...careersRouters,
	...ticketsRouters,
	...registerFormRouters,
	...subscribeRouters
];

const router = new VueRouter({
	routes
});

export default router;
