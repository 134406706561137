<template>
    <v-card>
        <v-card-title class="text-h5">
            Purge Cache
        </v-card-title>

        <v-card-text>
            <v-form v-model="valid" ref="form">
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="purgePattern" label="Purge Pattern" type="text" :rules="required" />
                        </v-col>
                        <v-col cols="6" class="pl-8 pt-7">
                            <v-btn color="red darken-1" dark @click="purgeByPattern" class="custom-button ">
                                <v-icon dark>mdi-delete</v-icon>
                                Purge by Pattern
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-select :items="purgePatternSuggestions()" label="Pattern examples" :rules="required"
                        persistent-hint return-object single-line dense outlined v-model="purgePattern" />
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn color="darken-1" text @click="close" :disabled="loading" class="custom-button">
                Cancel
            </v-btn>

            <v-btn color="red darken-1" dark @click="purgeTenantCache" class="custom-button">
                <v-icon dark>mdi-delete</v-icon>
                Purge All Cache
            </v-btn>
        </v-card-actions>
        <v-snackbar v-model="snackbar">
            {{ snackbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false" class="custom-button">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "CoreConfigForm",
    data() {
        return {
            loading: false,
            configValue: null,
            selectedLanguage: null,
            valid: false,
            required: [(v) => !!v || "this field is required"],
            purgePattern: null,
            snackbar: false,
            snackbarText: ""
        };
    },

    computed: {
        ...mapGetters("contentManagement", ["cachePurgeStatus"]),
        ...mapGetters("auth", ["roles"])
    },

    methods: {
        close() {
            this.error = this.configValue = null;
            this.$refs.form.resetValidation();
            this.purgePattern = null;
            this.$emit("close");
        },
        async purgeByPattern() {
            if (this.purgePattern === null || this.purgePattern === "") {
                this.snackbarText = "Please enter a valid pattern to purge cache.";
                return;
            }
            this.loading = true;
            await this.$store.dispatch("contentManagement/purgeCacheByPattern", this.purgePattern);
            this.loading = false;
            this.showSnackbar();
        },
        async purgeTenantCache() {
            this.loading = true;
            await this.$store.dispatch("contentManagement/purgeTenantCache");
            this.loading = false;
            this.showSnackbar();
        },
        // TODO: snackbar not showing!!
        showSnackbar() {
            if (this.cachePurgeStatus.success === true) {
                console.log("Cache purged successfully. Deleted ", this.cachePurgeStatus.deletedKeysCount, " keys.");

                this.snackbar = true;

                this.snackbarText = "Cache purged successfully. Deleted " + this.cachePurgeStatus.deletedKeysCount + " keys.";
            } else {
                this.snackbar = true;
                this.snackbarText = "Failed to purge cache. See Logs.";
                console.log("Failed to purge cache. Error Message: ", this.cachePurgeStatus.errorMessage);
            }
        },
        purgePatternSuggestions() {
            let tenantId = localStorage.getItem("tenantCode");
            return [
                tenantId + "*",
                "*PostDto*",
                "*PostBasicInfoDto*",
                "*PageDto*",
            ];
        }
    }
};
</script>
<style scoped>
.custom-button {
    border-radius: 3.125rem;
}
</style>
