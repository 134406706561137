import UsersForm from "../auth/views/users/usersForm.vue";
import UsersLising from "../auth/views/users/usersListing.vue";
export const usersRouters = [
  {
    path: "/setting/users",
    name: "UserListing",
    component: UsersLising,
  },
  {
    path: "/setting/user-form",
    name: "UsersForm",
    component: UsersForm,
  },
  {
    path: "/user-form",
    name: "UsersForm",
    component: UsersForm,
  },
];
