import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      contactUsPaginated: {
        result: [],
        totalNumberOfElements: 1,
        totalNumberOfPages: 1,
      },
      contactUsColumns:[],
      cotactUsConfig:[],
      cotactUsConfigObject:null

    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
