import NetworkManager from "../../../network/network-manager";
import Parser from "../network/parser.js";
export default {
  async loadCareersPaginated(context, payload) {
    await NetworkManager.post(
      context,
      `career/find-all-paginated`,
      payload,
      "PaginationResponse",
      "setPaginatedCareers",
      Parser
    );
  },
  async downloadCareersExcel() {
    return await NetworkManager.download(`/career/export`);
  },
  async deleteCareers(context, payload) {
    await NetworkManager.put(
      context,
      `career/marked-as-deleted/${payload.id}`,
      payload,
      null,
      "deleteCareers",
      Parser
    );
  },
  async getCareerById(context, payload) {
    await NetworkManager.get(
      context,
      `career/${payload}`,
      null,
      "setCareerItem",
      Parser
    );
  },
};
