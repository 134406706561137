
import ViewTenant from "../tenant/views/tenants/ViewTenant.vue";
import CreateTenant from "../tenant/views/tenants/CreateTenant.vue";
import CreateS3 from "../tenant/views/tenants/CreateS3.vue";
import ViewWebsiteS3 from "../tenant/views/tenants/ViewS3.vue";
import MailSender from "../tenant/views/tenants/MailSender.vue"
import CreateMailSender from "../tenant/views/tenants/CreateMailSender.vue"
import  SizeConfiguration  from "../tenant/views/tenants/SizeConfiguration.vue";
import EditSizeConfiguration from "../tenant/views/tenants/EditSizeConfiguration.vue"


export const tenantRouters = [
  {
    path: "/create-tenant",
    name: "CreateTenant",
    component: CreateTenant,
  },
  {
    path: "/view-tenant",
    name: "ViewTenant",
    component: ViewTenant,
  },

  {
    path: "/create-S3",
    name: "CreateS3",
    component: CreateS3,
  },
  {
    path: "/view-S3",
    name: "ViewS3",
    component: ViewWebsiteS3,
  },
  {
    path: "/mail-sender",
    name: "MailSender",
    component: MailSender,
  },
  {
    path: "/create-mail-sender",
    name: "CreateMailSender",
    component: CreateMailSender,
  },
  {
    path: "/websiteS3/:id",
    name: "editWebsiteS3",
    component: CreateS3,
  },
  {
    path: "/size-configuration",
    name: "SizeConfiguration",
    component: SizeConfiguration,
  },
  {
    path: "/size-configuration/:id",
    name: "editSizeConfiguration",
    component: EditSizeConfiguration,
  },
];
