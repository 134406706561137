<template>
  <Fragment>
    <nav>
      <div>
        <router-link
          class="text-decoration"
          :to="/content-type/ + contentType.id"
        >
          <span class="title">{{ contentType.menuTitle }} </span>
        </router-link>
        <v-divider></v-divider>
      </div>
      <v-list class="list">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-list-item
              v-on="on"
              :key="contentType.id"
              :to="{
                name: 'SubPostEntry',
                params: {
                  postId: rootPostId,
                  childTypeId: contentType.id,
                  childPostId: rootPostId,
                },
              }"
              link
            >
              <v-list-item-title v-text="contentType.type"></v-list-item-title>
            </v-list-item>
          </template>
          <span>{{ contentType.type }}</span>
        </v-tooltip>
        <div :class="{ disabled: rootPostId === null }">
          <Fragment v-for="(item, i) in contentType.children" :key="i">
            <!-- IF THERE ARE NO MORE CHILDS -->
            <v-list-item
              v-if="item.isActive === true && item.children.length === 0"
              :key="item.id"
              :to="{
                name: 'ChildContentTypePosts',
                params: { postId: rootPostId, childTypeId: item.id },
              }"
              link
            >
              <v-list-item-title v-text="item.type" />
            </v-list-item>
            <!-- CREATE MAIN GROUP WITH SUBGROUPS FOR THE TREE -->
            <v-list-group
              v-if="item.isActive === true && item.children.length > 0"
            >
              <template v-slot:activator>
                <v-list-item
                  class="v-list-group-activator"
                  :key="item.id"
                  :to="{
                    name: 'ChildContentTypePosts',
                    params: { postId: rootPostId, childTypeId: item.id },
                  }"
                  link
                >
                  <v-list-item-title>{{ item.type }}</v-list-item-title>
                </v-list-item>
              </template>
              <ContentMenuGroup
                :contentTypes="item.children"
                :postId="rootPostId"
              />
            </v-list-group>
          </Fragment>
        </div>
      </v-list>
    </nav>
  </Fragment>
</template>

<script>
import ContentMenuGroup from "./ContentMenuGroup.vue";
import { Fragment } from "vue-fragment";
import urlHelper from "../../../mixins/url-helper";

export default {
  components: { ContentMenuGroup, Fragment },
  name: "ContentMenu",
  mixins: [urlHelper],
  props: {
    contentType: {
      type: Object,
      required: true,
    },
  },
  watch: {
    rootPostId() {},
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 500;
  font-size: 0.675rem !important;
  line-height: 3.45;
  text-transform: uppercase;
}
.text-decoration {
  text-decoration: none;
}
.divider {
  width: 2.5rem;
}
.list {
  background-color: inherit;

  .v-list-group-activator {
    padding: 0 8px 0 0;
  }
  .v-list-item__title {
    font-size: 0.8rem;
    text-transform: capitalize;
  }
  .v-icon {
    margin: auto;
    padding-top: 6px;
    font-size: 6px;
  }
  .v-list-item__icon {
    margin-right: 12px !important;
  }
  .disabled {
    color: #f7f7f7;
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
