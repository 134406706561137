<template>
  <v-form v-model="valid">
    <v-container fluid>
      <v-row
        v-for="(item, index) in contentCategoryArray"
        :key="`contentCategoryItem${index}`"
      >
        <v-col cols="5" class="selectedContentCategory">
          <v-select
            v-model="item.contentCategory"
            :items="getRootNames"
            label="Content Category"
            item-value="id"
            persistent-hint
            return-object
            single-line
            dense
            outlined
            :value="getRootContentCategories"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="item.minAllowedNoOfCategories"
            label="Min Number Of Category"
            type="number"
            :rules="required"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="item.maxAllowedNoOfCategories"
            label="Max Number Of Category"
            type="number"
            :rules="required"
          />
        </v-col>
        <v-col cols="1" class="minus">
          <v-btn
            class="ma-2"
            color="red darken-1"
            text
            @click="deleteItem(item)"
          >
            <v-icon dark> mdi-minus-circle </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import settingsMixin from "../mixins/settings";

export default {
  data() {
    return {
      required: [(v) => !!v || "this field is required"],
      valid: true,
      categorySelected: [],
    };
  },

  props: {
    contentCategoryArray: {
      type: Array,
      required: true,
    },
  },
  mixins: [settingsMixin],
  computed: {
    getRootContentCategories() {
      return this.contentCategories.filter(
        (x) => (x.parentId === null || x.parentId === undefined) && (x.isActive )
      );
    },
    getRootNames() {
      return this.getRootContentCategories.map((item) => {
        return {
          ...item,
          text: item.localizations[this.getLocale()].categoryName,
        };
      });
    },
  },
  mounted() {
    if (this.contentCategoryArray.length > 0) {
      this.categorySelected.push(...this.contentCategoryArray);
    }
  },
  methods: {
    deleteItem(item) {
      let indexOfItem = this.contentCategoryArray.indexOf(item);
      this.contentCategoryArray.splice(indexOfItem, 1);
    },
  },

  watch: {
    valid(newVal) {
      this.$emit("validationContentCategoryForm", newVal);
    },
  },
};
</script>

<style scoped>
.selectedContentCategory {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.minus {
  margin-top: 12px;
}
</style>
