import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      pages: [],
      localizedPages: [],
      selectedPage: null,
      selectLocalizedPage: [],
      selectedPageCategory: null,
      seoPaginated: {
        result: [],
        totalNumberOfElements: 1,
        totalNumberOfPages: 1,
      },
      pageCategory: [],
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
