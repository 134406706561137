<template>
  <v-container>
    <!-- IMAGES SECTION -->
    <v-row v-if="contentType.maxUploadImages">
      <v-col cols="12" sm="12">
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="sortedImages"
          v-if="postId"
          class="capitalize  elevation-1"
        >
          <!--eslint-disable -->
          <template v-slot:body>
            <draggable :list="sortedImages" @end="saveSortedImages" tag="tbody">
              <tr v-for="(item, index) in sortedImages" :key="index">
                <td>
                  <v-icon
                    small
                    class="page__grab-icon"
                    v-if="sortedImages.length > 1"
                  >
                    mdi-arrow-all
                  </v-icon>
                </td>
                <td class="text-cell" v-if="item.fileName">
                  {{ item.fileName }}
                </td>
                <td>
                  <v-img
                    v-if="item.fileUrl"
                    :src="item.fileUrl"
                    aspect-ratio="2.3"
                  />
                </td>
                <td>
                  <div
                    v-for="(item, index) in item.postFileImageType"
                    :key="index"
                  >
                    <span>{{ item.imageType.name }}</span>
                  </div>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="onViewImageClicked(item)"
                        v-on="on"
                        v-bind="attrs"
                      >
                        mdi-eye
                      </v-icon>
                    </template>
                    <span>Show</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="onEditPostImageClicked(item)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        small
                        v-on="on"
                        v-bind="attrs"
                        @click="onDeleteFile(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-checkbox
                    @change="onFileActiveStatusChange(item)"
                    v-model="item.isActive"
                    class="mr-2"
                    label="Active"
                    color="blue darken-2"
                    hide-details
                  />
                </td>
              </tr>
            </draggable>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Images</v-toolbar-title>
              <v-divider class="mx-4" inset vertical />
              <v-spacer />
              <!-- <v-col lg="3" sm="3" class="order-btn-position">
                <v-btn
                  class="mb-2 custom-button"
                  color="black darken-1 white--text"
                  @click="saveSortedImages"
                >
                  Save order
                </v-btn>
              </v-col> -->
                  <v-btn
                  v-if="postFiles.length <contentType.maxUploadImages"
                    color="primary"
                    dark
                    class="mb-2 custom-button"
                    @click="openImageDialog"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                    Add Image
                  </v-btn>

              <!-- IMAGE DIALOG -->
                <v-dialog v-model="imageDialog" max-width="900px">
                <v-card>
                  <v-tabs v-model="tab" align-with-title>
                    <v-tabs-slider color="black"></v-tabs-slider>

                    <v-tab v-for="tab in tabs" :key="tab">
                      {{ tab }}
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-form
                            v-model="imageFormValid"
                            ref="image-form"
                            class="image-dialog"
                          >
                            <v-card-text>
                              <v-container>
                                <v-row v-if="!viewImageOnly">
                                  <v-col cols="12">
                                    <v-select
                                      v-model="types"
                                      :items="imageTypes"
                                      item-text="name"
                                      class="list"
                                      label="Select Image Type"
                                      multiple
                                      return-object
                                      chips
                                    />
                                    <span
                                      v-if="types.length == 0"
                                      class="red--text"
                                    >
                                      You must select at least one
                                    </span>
                                  </v-col>
                                  <v-col cols="4" sm="4">
                                    <b class="image-description">
                                      {{
                                        contentType.desktopImageDescriptionConfig
                                      }}
                                    </b>
                                    <v-file-input
                                      v-model="desktopImage"
                                      :rules="maxSizeRule"
                                      accept="image/*,video/mp4"
                                      placeholder="Desktop"
                                      prepend-icon="mdi-camera"
                                      label="Desktop"
                                    />
                                  </v-col>
                                  <v-col cols="4" sm="4">
                                    <b class="image-description">{{
                                      contentType.tabletImageDescriptionConfig
                                    }}</b>
                                    <v-file-input
                                      v-model="tabletImage"
                                      :rules="maxSizeRule"
                                      accept="image/*,video/mp4"
                                      placeholder="Table"
                                      prepend-icon="mdi-camera"
                                      label="Tablet"
                                    />
                                  </v-col>

                                  <v-col cols="4" sm="4">
                                    <b class="image-description">{{
                                      contentType.mobileImageDescriptionConfig
                                    }}</b>
                                    <v-file-input
                                      v-model="mobileImage"
                                      :rules="maxSizeRule"
                                      accept="image/*,video/mp4"
                                      placeholder="Mobile"
                                      prepend-icon="mdi-camera"
                                      label="Mobile"
                                    />
                                  </v-col>
                                </v-row>

                                <v-row v-if="editedFile && editedFile.id">
                                  <v-col cols="4" sm="4">
                                    <v-btn
                                      class="ma-2"
                                      outlined
                                      :href="
                                        findImageUrlByDeviceSize(
                                          DEVICE_SIZE.DESKTOP
                                        )
                                      "
                                      download
                                    >
                                      {{ DEVICE_SIZE.DESKTOP }}
                                      <v-icon small>mdi-download</v-icon>
                                    </v-btn>

                                    <v-img
                                      :src="editedFile.fileUrl"
                                      aspect-ratio="2.3"
                                    />
                                  </v-col>

                                  <v-col cols="4" sm="4">
                                    <v-btn
                                      class="ma-2"
                                      outlined
                                      :href="
                                        findImageUrlByDeviceSize(
                                          DEVICE_SIZE.TABLET
                                        )
                                      "
                                      download
                                    >
                                      {{ DEVICE_SIZE.TABLET }}
                                      <v-icon small>mdi-download</v-icon>
                                    </v-btn>
                                    <v-img
                                      :src="
                                        findImageUrlByDeviceSize(
                                          DEVICE_SIZE.TABLET
                                        )
                                      "
                                      aspect-ratio="1.6"
                                    />
                                  </v-col>
                                  <v-col cols="4" sm="4">
                                    <v-btn
                                      class="ma-2"
                                      outlined
                                      :href="
                                        findImageUrlByDeviceSize(
                                          DEVICE_SIZE.MOBILE
                                        )
                                      "
                                      download
                                    >
                                      {{ DEVICE_SIZE.MOBILE }}
                                      <v-icon small>mdi-download</v-icon>
                                    </v-btn>
                                    <v-img
                                      :src="
                                        findImageUrlByDeviceSize(
                                          DEVICE_SIZE.MOBILE
                                        )
                                      "
                                      aspect-ratio="1.8"
                                    />
                                  </v-col>
                                </v-row>
                                <v-row v-if="!editedFile.id">
                                  <v-col cols="4" sm="4">
                                    <v-text-field
                                      v-model="desktopImageUrl"
                                      placeholder="Desktop"
                                      label="Desktop Image URL"
                                    />
                                  </v-col>
                                  <v-col cols="4" sm="4">
                                    <v-text-field
                                      v-model="tabletImageUrl"
                                      placeholder="Table"
                                      label="Tablet Image URL"
                                    />
                                  </v-col>
                                  <v-col cols="4" sm="4">
                                    <v-text-field
                                      v-model="mobileImageUrl"
                                      placeholder="Mobile"
                                      label="Mobile Image URL"
                                    />
                                  </v-col>
                                </v-row>

                                <!-- <v-row v-if="compressedImage.progress">
                          <v-progress-linear
                            v-model="compressedImage.progress"
                            color="black"
                          />
                        </v-row>
                        <v-row v-if="customError">
                          <v-col>
                            <span class="red--text">{{ customError }}</span>
                          </v-col>
                        </v-row> -->
                              </v-container>
                            </v-card-text>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <div class="image-dialog">
                        <div>
                          <v-tabs
                            class="zero-padding main-tabs"
                            v-model="PostFileCaptionTab"
                            background-color="black"
                            dark
                            cols="6"
                            color="white"
                          >
                            <v-tabs-slider color="white darken-6" />

                            <v-tab
                              v-for="(caption, index) in postFileCaptions"
                              :key="index"
                            >
                              {{
                                getLanguageByLocale(caption.locale).description
                              }}
                            </v-tab>
                          </v-tabs>
                          <v-tabs-items
                            class="zero-padding"
                            v-model="PostFileCaptionTab"
                          >
                            <v-tab-item
                              v-for="(localizedCaption,
                              localizedCaptionIndex) in postFileCaptions"
                              :key="localizedCaptionIndex"
                            >
                              <v-card flat>
                                <v-card-text class="forms-boxes">
                                  <v-textarea
                                    outlined
                                    name="caption"
                                    label="Caption"
                                    v-model="localizedCaption.caption"
                                  />
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                          </v-tabs-items>
                        </div>
                      </div>
                    </v-tab-item>
                  </v-tabs-items>

                  <v-card-actions v-if="!viewImageOnly" class="actions">
                    <v-spacer />
                    <v-btn
                      :disabled="loadingImage"
                      color="red darken-1"
                      text
                      @click="closeImageDialog"
                      type="button"
                      class="custom-button"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :disabled="
                        loadingImage ||
                          !imageFormValid ||
                          types.length == 0 ||
                          (!desktopImage && !desktopImageUrl)
                      "
                      :loading="loadingImage"
                      color="blue darken-1 white--text "
                      type="button"
                      class="custom-button"
                      @click="saveImageDialog"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <Fragment v-if="contentType.maxUploadFiles">
      <v-divider />

      <!-- FILES SECTION -->
      <v-row>
        <v-col cols="12" sm="12">
          <v-data-table
            :headers="headers"
            :items="sortedFiles"
            sort-by="fileOrder"
            class="elevation-1 capitalize subtitle"
            :items-per-page="-1"
            v-if="postId"
          >
            <template v-slot:body>
              <draggable v-model="sortedFiles" tag="tbody">
                <tr v-for="(item, index) in sortedFiles" :key="index">
                  <td>
                    <v-icon
                      small
                      class="page__grab-icon"
                      v-if="sortedFiles.length > 1"
                    >
                      mdi-arrow-all
                    </v-icon>
                  </td>
                  <td v-if="item.fileName" class="text-cell">
                    {{ item.fileName }}
                  </td>
                  <td class="text-cell">
                    <v-btn
                      v-if="item.fileUrl"
                      class="ma-2"
                      outlined
                      :href="item.fileUrl"
                      download
                    >
                      <v-icon small>mdi-download</v-icon>
                    </v-btn>
                  </td>
                  <td v-if="item.fileType" class="text-cell">
                    {{ item.fileType }}
                  </td>
                  <td>
                   
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          small
                          v-on="on"
                          v-bind="attrs"
                          @click="onDeleteFile(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-checkbox
                      @change="onFileActiveStatusChange(item)"
                      v-model="item.isActive"
                      class="logo-check ml-4"
                      label="Active"
                      color="blue darken-2"
                      hide-details
                    />
                  </td>
                </tr>
              </draggable>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Files</v-toolbar-title>
                <v-divider class="mx-4" inset vertical />
                <v-spacer />
                <v-col lg="3" sm="3" class="order-btn-position">
                  <v-btn
                    class="mb-2 custom-button"
                    color="black darken-1 white--text"
                    @click="saveSortedFiles"
                  >
                    Save order
                  </v-btn>
                </v-col>

                <!-- FILE DIALOG -->
                <v-dialog v-model="fileDialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2 custom-button"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-plus</v-icon>

                      Add File
                    </v-btn>
                  </template>
                  <v-card>
                    <v-form v-model="fileFormValid" ref="file-form">
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-file-input
                              v-model="file"
                              :rules="requiredAndMaxSizeRules"
                              accept=".pdf, .excel"
                              placeholder="File"
                              prepend-icon="mdi-file"
                              label="File"
                            />
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          :disabled="loadingFile"
                          color="red darken-1"
                          text
                          class="custom-button"
                          @click="closeFileDialog"
                          type="button"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          :disabled="loadingFile || !fileFormValid"
                          color="blue darken-1 white--text "
                          class="custom-button"
                          :loading="loadingFile"
                          type="submit"
                          @click="saveFileDialog"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </Fragment>
    <!--type selection dialog-->

    <!-- Delete Dialog -->
    <v-dialog v-model="deleteFileDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="custom-button"
            :disabled="isDelete"
            @click="closeDeleteConfirmationDialog"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isDelete"
            :loading="isDelete"
            color="blue darken-1 white--text"
            @click="deleteFileConfirmed"
            class="custom-button"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ snakbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { Language } from "../models/index";
import LocalizedPostFileCaption from "../models/localized-post-file-caption";
import urlHelper from "../../../mixins/url-helper";
import settingsMixin from "../../settings/mixins/settings";
import draggable from "vuedraggable";
import { PostFile } from "../models";
import { listToTree } from "../../../plugins/build-tree";
import { DEVICE_SIZE } from "../../../constants/enums";
import { Fragment } from "vue-fragment";
import imageCompression from "browser-image-compression";

export default {
  name: "PostsFiles",
  props: {
    contentType: {
      type: Object,
      required: true,
    },
  },
  mixins: [urlHelper, settingsMixin],
  components: {
    Fragment,
    draggable,
  },
  computed: {
    ...mapGetters("post", [
      "postFiles",
      "selectedPost",
      "imageTypes",
      "localizedPostFileCaption",
      "currentlyAddedPostFileCaption",
      "currentlyAddedFile",
      "sizeConfiguration"
    ]),
    imageTypesName() {
      return this.imageTypes.map((item) => item.name);
    },
    DEVICE_SIZE() {
      return DEVICE_SIZE;
    },
  },

  data() {
    return {
      postFileCaptions: [],
      postFileId: null,
      tab: null,
      postFileCaptionFromEdit: null,
      PostFileCaptionTab: null,
      tabs: ["Images", "Caption"],
      types: [],
      required: [(v) => !!v || "this field is required"],
      editedPostFileId: null,
      dialog: false,
      snakbarText: "",

      snackbar: false,
      tabletImageUrl: null,
      desktopImageUrl: null,
      mobileImageUrl: null,
      editedFile: new PostFile(),
      mobileImageObject: null,
      tabletImageObject: null,
      viewImageOnly: false,
      maxSizeRule: [
        (value) =>
          value === null ||
          (value && value.size <= this.sizeConfiguration[0].imageSize) ||
          "Image size should be less than "+  this.sizeConfiguration[0].imageSize/1000000 +" MB!",
      ],
      requiredAndMaxSizeRules: [
        (value) => !!value || "This is required",
        (value) =>
          (value && value.size <= this.sizeConfiguration[0].fileSize) ||
          "File size should be less than " + this.sizeConfiguration[0].fileSize / 1000000 +"MB!",
      ],
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "fileName",
        },
        { text: "File", value: "fileUrl" },
        {
          text: "fileTypes",
          value: "fileTypes",
        },

        {
          text: "Actions",
          value: "actions",
        },
        {
          text: "Active",
          value: "Active",
        },
      ],
      file: null,
      desktopImage: null,
      tabletImage: null,
      mobileImage: null,
      isDelete: false,
      deleteFileDialog: false,
      imageDialog: false,
      loadingImage: false,
      imageFormValid: false,
      fileDialog: false,
      fileFormValid: false,
      loadingFile: false,
      addedDesktopImage: null,
      sortedImages: [],
      sortedFiles: [],
      compressionOptions: {
        maxSizeMB: 0.4,
        useWebWorker: true,
        alwaysKeepResolution: true,
        onProgress: (p) => this.onCompressionProgress(p),
      },
      compressedImage: {
        progress: null,
        outputSize: null,
        outputFile: null,
        outputUrl: null,
      },
      customError: null,
      orderLoading: false,
    };
  },

  async mounted() {
    await this.$store.dispatch("post/loadImageTypes");
    this.setSortedImages();
    this.setSortedFiles();
    await this.$store.dispatch("post/loadSizeConfiguration");
  },

  methods: {
    clearPostFileCaptionData() {
      this.$store.commit("post/setLocalizedPostFileCaption", []);
      this.$store.commit("post/setPostFileCaption", null);
      this.postFileCaptions = [];
    },
    async endOrderingFiles(event) {
      try {
        if (this.orderLoading) return;
        this.orderLoading = true;
        const oldIndex = event?.oldIndex;
        const newIndex = event?.newIndex;
        if (oldIndex !== newIndex) {
          const payload = {
            postFileId: this.sortedImages[newIndex]?.id,
            fileOrder: newIndex + 1,
          };
          await this.$store.dispatch("post/updateOrderFile", payload);
          this.snackbarText = "Posts order updated successfully";
        }
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.orderLoading = false;
        this.snackbar = true;
      }
    },
    setSortedImages() {
      const images = this.postFiles?.filter((x) => x.imageDeviceSize !== null);
      this.sortedImages = images?.length ? listToTree(images) : [];
    },
    setSortedFiles() {
      this.sortedFiles =
        this.postFiles?.filter((x) => x.imageDeviceSize === null) ?? [];
    },
    saveSortedFiles() {
      try {
        this.$store.dispatch(
          "post/updatePostFileOrder",
          this.sortedFiles.map((item, index) => {
            return {
              id: item.id,
              fileOrder: index + 1,
            };
          })
        );
        this.snackbarText = "files order updated successfully";
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.snackbar = true;
      }
    },
    async endOrderingPostFiles(event) {
      try {
        if (this.orderLoading) return;
        this.orderLoading = true;
        const oldIndex = event?.oldIndex;
        const newIndex = event?.newIndex;
        if (oldIndex !== newIndex) {
          const payload = {
            postFileId: this.sortedImages[newIndex]?.id,
            fileOrder: newIndex + 1,
          };
          await this.$store.dispatch("post/updateOrderFile", payload);
          this.snackbarText = "images order updated successfully";
        }
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.orderLoading = false;
        this.snackbar = true;
      }
    },

    // START GENERIC METHODS
    async createPostFile(file, imageDeviceSize, parentId) {
      const formData = new FormData();
      formData.append("postId", this.selectedPost.id);
      formData.append("file", file);
      if (imageDeviceSize) formData.append("imageDeviceSize", imageDeviceSize);
      if (
        imageDeviceSize == DEVICE_SIZE.DESKTOP &&
        this.currentlyAddedPostFileCaption
      )
        formData.append("captionId", this.currentlyAddedPostFileCaption.id);
      if (parentId) formData.append("parentId", parentId);
      const addedFile = await this.$store.dispatch("post/addFile", formData);
      this.postFileId = addedFile.id;
      if (addedFile.imageDeviceSize != null && !addedFile.parentId)
        await this.updatePostFileImageTypes(addedFile.id);
    },

    async onFileActiveStatusChange(item) {
      await this.$store.dispatch("post/updateFile", item);
    },
    // END GENERIC METHODS

    // START DELETE DIALOG
    onDeleteFile(item) {
      this.editedFile = Object.assign({}, item);
      this.deleteFileDialog = true;
    },
    async deleteFileConfirmed() {
      try {
        await this.$store.dispatch("post/deletePostFile", this.editedFile);
      } finally {
        this.closeDeleteConfirmationDialog();
      }
    },

    closeDeleteConfirmationDialog() {
      this.deleteFileDialog = false;
      this.clearEditedFile();
    },
    // END DELETE DIALOG

    // START FILE DIALOG
    async saveFileDialog() {
      try {
        this.loadingFile = true;
        await this.createPostFile(this.file, null, null);
      } catch (error) {
        this.snackbar = true;
        this.snakbarText = error;
      } finally {
        this.loadingFile = false;
        this.closeFileDialog();
      }
    },
    closeFileDialog() {
      this.fileDialog = false;

      this.clearEditedFile();
    },
    // END FILE DIALOG

    // START IMAGE DIALOG
    async openImageDialog() {
      this.clearPostFileCaptionData();
      this.constructPostFileCaptionsWithLanguages(this.languages);
      this.clearEditedFile();
      this.types = [];
      this.imageDialog = true;
    },
    async saveImageDialog() {
      this.loadingImage = true;
      try {
        // CREATE/UPDATE CAPTIONS
        if (
          this.localizedPostFileCaption?.length === 0 &&
          this.postFileCaptions?.some((a) => a.caption?.trim())
        ) {
          await this.$store.dispatch("post/createPostFileCaption", {});
          if (this.editedFile)
            this.editedFile.postFileCaption = {
              ...this.currentlyAddedPostFileCaption,
            };
        }
        if (
          this.currentlyAddedPostFileCaption ||
          this.editedFile?.postFileCaption
        ) {
          await this.addUpdateLocalizedPostCaption(
            this.currentlyAddedPostFileCaption ??
              this.editedFile.postFileCaption
          );
        }

        // CREATE/UPDATE/DELETE IMAGES
        if (this.desktopImageUrl != null) await this.createPostImageWithUrl();
        else if (!this.editedFile?.id) {
          // NEW IMAGE TO BE UPLOADED
          await this.createPostImage();
        } else {
          // UPDATE IMAGE
          await this.updatePostFileImageTypes(this.editedPostFileId);
          await this.updatePostImages(this.editedFile);
        }

        // RESET
        this.postFileId = null;
      } catch (error) {
        this.snackbar = true;
        this.snakbarText = error;
      } finally {
        this.loadingImage = false;
        this.closeImageDialog();
      }
    },

    async addUpdateLocalizedPostCaption(postFileCaption) {
      for (let i = 0; i < this.postFileCaptions.length; i++) {
        this.postFileCaptions[i].postFileCaption = {
          id: postFileCaption.id,
        };
        if (this.postFileCaptions[i].id === null) {
          if (this.postFileCaptions[i]?.caption?.trim())
            await this.$store.dispatch(
              "post/addLocalizedPostFileCaption",
              this.postFileCaptions[i]
            );
        } else {
          await this.$store.dispatch(
            "post/updateLocalizedPostFileCaption",
            this.postFileCaptions[i]
          );
        }
      }
    },
    async onEditPostImageClicked(postImage) {
      this.setToBeUpdatedImageDataToUI(postImage);
      await this.getCaption(postImage);

      // UI
      this.viewImageOnly = false;
      this.imageDialog = true;
    },
    onViewImageClicked(item) {
      this.editedFile = { ...item };
      this.imageDialog = this.viewImageOnly = true;
    },
    closeImageDialog() {
      this.imageDialog = false;
      this.clearEditedFile();
      this.clearPostFileCaptionData();
    },

    clearEditedFile() {
      this.tab = null;
      this.postFileCaptionFromEdit = null;
      this.PostFileCaptionTab = null;
      this.$nextTick(() => {
        this.editedFile = Object.assign({}, new PostFile());
        this.viewImageOnly = false;
        this.desktopImageUrl = this.addedDesktopImage = this.desktopImage = this.tabletImage = this.mobileImage = this.file = null;
      });
    },
    // END IMAGE DIALOG

    // START IMAGES METHODS
    async createPostImageWithUrl() {
      this.editedFile.postId = this.selectedPost.id;
      this.editedFile.fileUrl = this.desktopImageUrl;
      this.editedFile.imageDeviceSize = DEVICE_SIZE.DESKTOP;
      const addedFile = await this.$store.dispatch(
        "post/addFileUrl",
        this.editedFile
      );

      if (this.tabletImageUrl)
        await this.createPostFileChildWthUrl(
          this.selectedPost.id,
          this.tabletImageUrl,
          DEVICE_SIZE.TABLET,
          addedFile.id
        );
      if (this.mobileImageUrl)
        await this.createPostFileChildWthUrl(
          this.selectedPost.id,
          this.mobileImageUrl,
          DEVICE_SIZE.MOBILE,
          addedFile.id
        );
      await this.updatePostFileImageTypes(addedFile.id);
    },
    async createPostFileChildWthUrl(postId, fileUrl, imageSize, parentId) {
      this.editedFile.postId = postId;
      this.editedFile.fileUrl = fileUrl;
      this.editedFile.imageDeviceSize = imageSize;
      this.editedFile.parentId = parentId;
      await this.$store.dispatch("post/addFileUrl", this.editedFile);
    },
    async deleteImage(editedFile) {
      await this.$store.dispatch("post/deletePostFile", editedFile);
    },
    async createPostImage() {
      await this.createPostFile(this.desktopImage, DEVICE_SIZE.DESKTOP, null);
      this.addedDesktopImage = { ...this.currentlyAddedFile };
      if (this.tabletImage && this.addedDesktopImage)
        await this.createPostFile(
          this.tabletImage,
          DEVICE_SIZE.TABLET,
          this.addedDesktopImage.id
        );
      if (this.mobileImage && this.addedDesktopImage)
        await this.createPostFile(
          this.mobileImage,
          DEVICE_SIZE.MOBILE,
          this.addedDesktopImage.id
        );
    },
    async updatePostImages(editedFile) {
      // DESKTOP
      // if (
      //   !editedFile.parentId &&
      //   editedFile.fileName !== this.desktopImage.name
      // )
      await this.updatePostImage(
        editedFile,
        this.desktopImage ?? this.desktopImage.fileName,
        DEVICE_SIZE.DESKTOP
      );

      // TABLET
      if (!this.tabletImageObject && this.tabletImage) {
        await this.createPostFile(
          this.tabletImage,
          DEVICE_SIZE.TABLET,
          editedFile.id
        );
      } else if (this.tabletImageObject && !this.tabletImage) {
        await this.deleteImage(this.tabletImageObject);
      } else if (
        this.tabletImageObject &&
        this.tabletImageObject.fileName != this.tabletImage.name
      ) {
        await this.updatePostImage(
          this.tabletImageObject,
          this.tabletImage ?? this.tabletImageObject.fileName,
          DEVICE_SIZE.TABLET,
          editedFile.id
        );
      }

      // MOBILE
      if (!this.mobileImageObject && this.mobileImage) {
        await this.createPostFile(
          this.mobileImage,
          DEVICE_SIZE.MOBILE,
          editedFile.id
        );
      } else if (this.mobileImageObject && !this.mobileImage) {
        await this.deleteImage(this.mobileImageObject);
      } else if (
        this.mobileImageObject &&
        this.mobileImageObject.fileName != this.mobileImage.name
      ) {
        await this.updatePostImage(
          this.mobileImageObject,
          this.mobileImage ?? this.mobileImageObject.fileName,
          DEVICE_SIZE.MOBILE,
          editedFile.id
        );
      }
    },
    async updatePostImage(
      editedImage,
      imageFile,
      imageDeviceSize = null,
      parentId = null
    ) {
      const formData = new FormData();
      formData.append("id", editedImage.id);
      formData.append("fileOrder", editedImage.fileOrder);
      if (imageDeviceSize) formData.append("imageDeviceSize", imageDeviceSize);
      formData.append("file", imageFile);
      if (parentId) formData.append("parentId", editedImage.parentId);
      if (editedImage.postFileCaption)
        formData.append("captionId", editedImage.postFileCaption.id);
      formData.append("postId", editedImage.postId);
      await this.$store.dispatch("post/updatePostFile", formData);
    },
    async updatePostFileImageTypes(postFileId) {
      await this.$store.dispatch("post/addSelectImageType", {
        postFileId: postFileId,
        imageTypeArray: this.types,
      });
    },
    // TODO: CHANGE SORTING ALGO BE & FE ACCORDINGLY
    async saveSortedImages() {
      try {
        await this.$store.dispatch(
          "post/updatePostFileOrder",
          this.sortedImages.map((item, index) => {
            return {
              id: item.id,
              fileOrder: index + 1,
            };
          })
        );
        this.snakbarText = "files order updated successfully";
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.snackbar = true;
      }
    },
    setToBeUpdatedImageDataToUI(postImage) {
      this.editedFile = { ...postImage };
      this.desktopImage = new File([""], postImage.fileName);
      // TABLET
      this.tabletImageObject = this.editedFile.children?.find(
        (a) => a.imageDeviceSize == DEVICE_SIZE.TABLET
      );
      if (this.tabletImageObject)
        this.tabletImage = new File([""], this.tabletImageObject.fileName);

      // MOBILE
      this.mobileImageObject = this.editedFile.children?.find(
        (a) => a.imageDeviceSize == DEVICE_SIZE.MOBILE
      );
      if (this.mobileImageObject)
        this.mobileImage = new File([""], this.mobileImageObject.fileName);

      this.editedPostFileId = postImage.id;
      this.types =
        postImage?.postFileImageType?.map((item) => item.imageType) ?? [];
    },
    // END IMAGES METHODS

    // START IMAGES COMPRESSION
    onCompressionProgress(p) {
      this.compressedImage = {
        ...this.compressedImage,
        progress: p,
      };
    },
    async compressImage(file) {
      const output = await imageCompression(file, this.compressionOptions);
      this.compressedImage = {
        ...this.compressedImage,
        outputSize: (output.size / 1024 / 1024).toFixed(2),
        outputFile: output,
        outputUrl: URL.createObjectURL(output),
        progress: null,
      };
    },
    async onChangeImageHandler(file, imageModel) {
      if (file === null) return;

      this.customError = null;
      // console.log("onChangeImageHandler BEFORE", this.$data[imageModel]);
      await this.compressImage(file);
      // console.log(
      //   "onChangeImageHandler AFTER",
      //   this.compressedImage.outputFile,
      //   this.compressedImage.outputFile.name
      // );

      if (!this.validateCompression()) this.$data[imageModel] = null;
      else {
        this.$data[imageModel] = this.compressedImage.outputFile;
        this.$data[imageModel].name = file.name;
      }
    },
    validateCompression() {
      if (
        this.compressedImage.outputFile?.size &&
        parseFloat(this.compressedImage.outputSize) >
          this.compressionOptions.maxSizeMB
      ) {
        this.customError = "Image size is too large.";
        return false;
      }
      this.customError = null;
      return true;
    },
    // END IMAGE COMPRESSION

    // HELPERScategoryCode
    findImageUrlByDeviceSize(imageDeviceSize) {
      return imageDeviceSize == DEVICE_SIZE.DESKTOP
        ? this.editedFile.fileUrl
        : this.editedFile?.children?.find(
            (x) => x.imageDeviceSize === imageDeviceSize
          )?.fileUrl;
    },

    //caption
    async getCaption(postFile) {
      if (postFile?.postFileCaption?.id)
        await this.$store.dispatch(
          "post/getLocalizedPostFileCaption",
          postFile.postFileCaption?.id
            ? postFile.postFileCaption.id
            : postFile.postFileCaption.id
        );

      this.postFileCaptions = this.localizedPostFileCaption?.map((item) => {
        return { ...item, language: this.getLanguageByLocale(item.locale) };
      });
      this.postFileCaptions = this.postFileCaptions.filter(
        (a) => a.language != undefined
      );

      const addedLanguages = [
        ...new Map(
          this.postFileCaptions.map((item) => [item["locale"], item.locale])
        ).values(),
      ];
      var missedLanguages = this.languages.filter(
        (item) => !addedLanguages.find((element) => element === item.locale)
      );
      if (missedLanguages?.length)
        this.constructPostFileCaptionsWithLanguages(missedLanguages);
    },

    constructPostFileCaptionsWithLanguages(languages) {
      for (let i = 0; i < languages?.length; i++) {
        let newPostFileCaption = new LocalizedPostFileCaption({
          id: null,
          language: new Language(languages[i]),
          locale: languages[i].locale,
          caption: "",
        });
        this.postFileCaptions.push(newPostFileCaption);
      }
    },
  },
  watch: {
    postFiles() {
      this.$store.commit("post/setPostFiles", this.postFiles);
      this.setSortedImages();
      this.setSortedFiles();
    },

    async currentlyAddedPostFileCaption() {
      let post = await this.$store.dispatch("post/findPost", this.postId);
      this.$store.commit("post/setPostFiles", post.postFiles);
      this.setSortedImages();
      this.setSortedFiles();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
::v-deep .v-list-item__title {
  text-transform: capitalize !important;
}

.image-description {
  padding-left: 2rem;
}

.title {
  color: #757575;
  font-weight: 600;
  font-size: 0.7275rem !important;
  line-height: 1.45;
  text-transform: uppercase;
}
.logo-check {
  margin-top: 0;
  margin-right: 0.7rem;
}
.selection-title {
  padding-top: 2rem;
  font-size: 2rem;
}
.actions {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}
.custom-button {
  border-radius: 3.125rem;
}
.text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.image-dialog {
  min-height: 500px;
}
.custom-button {
  border-radius: 3.125rem;
}
.v-data-table {
  font-size: large;
  color: black;
}
</style>
