<template>
  <v-container fluid>
    <v-row v-if="contentType.contentTypeCategories.length">
      <v-col sm="12">
        <span class="title">Categories</span>
      </v-col>
      <v-col
        sm="12"
        v-for="(item, i) in contentType.contentTypeCategories"
        :key="i"
      >
        <v-row>
          <v-col sm="3" lg="3" class="post-category-code">
            {{ getContentCategoryById(item.contentCategoryId).code }}
          </v-col>
          <v-col sm="9" lg="9">
            <v-select
              ref="categorySelector"
              :items="getCategoriesByParentId(item.contentCategoryId)"
              label="Outlined style"
              item-value="id"
              persistent-hint
              return-object
              single-line
              dense
              outlined
              :multiple="
                item.maxAllowedNoOfCategories &&
                  item.maxAllowedNoOfCategories > 1
              "
              :value="getSelectedCategoriesByParentId(item)"
              @change="onChangeHandler"
              class="select-category"
            >
              <template v-slot:append-item>
                <v-divider />
                <v-divider />
                <option class="add-other" @click="addOther(item)">
                  add other
                  {{ getContentCategoryById(item.contentCategoryId).code }}
                </option>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>

      <template v-if="contentCategoryDialog">
        <v-dialog v-model="contentCategoryDialog" max-width="850px">
          <v-card>
            <v-card-title>
              <span class="text-h5">
                <v-card-title>
                  <span class="text-h5">Add Category </span>
                </v-card-title>
              </span>
            </v-card-title>
            <v-card-text>
              <v-form v-model="valid" ref="form">
                <v-text-field
                  label="Category Name"
                  v-model="categoryName"
                  :rules="required"
                />
                <div class="row">
                  <div class="col-9">
                    <v-file-input
                      v-model="contentCategoryImg"
                      :rules="maxSizeRule"
                      accept="image/*"
                      prepend-icon="mdi-camera"
                      label="upload img"
                    />
                  </div>
                  <div class="col-3 img-category">
                    <v-img height="70" max-width="70" :src="imgUrl" />
                  </div>
                </div>
                <v-tabs
                  class="zero-padding"
                  v-model="tab"
                  background-color="grey darken-3"
                  dark
                >
                  <v-tabs-slider color="blue darken-6" />
                  <v-tab v-for="(item, i) in formLocalizedCategories" :key="i">
                    <v-icon x-small>mdi-file-document-box</v-icon>
                    {{ getLanguageByLocale(item.locale).description }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items class="zero-padding" v-model="tab">
                  <v-tab-item
                    v-for="(item, i) in formLocalizedCategories"
                    :key="i"
                  >
                    <v-card flat>
                      <v-card-text>
                        <v-form v-model="valid" ref="form">
                          <v-row>
                            <v-col cols="6" sm="6">
                              <v-text-field
                                label="Category Name"
                                v-model="item.categoryName"
                                :rules="required"
                              />
                            </v-col>
                            <v-col cols="6" sm="6">
                              <v-text-field
                                label="Description"
                                v-model="item.description"
                              />
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red darken-1"
                class="custom-button"
                text
                @click="closeContentCategoryDialog"
                :disabled="addLoading"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1 white--text"
                @click="submitData"
                :disabled="!valid || addLoading"
                :loading="addLoading"
                class="custom-button"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-row>

    <v-divider />
    <v-row v-if="contentType.contentType && contentType.contentType.parentId">
      <v-col sm="12">
        <span class="title">Parent</span>
      </v-col>
      <v-col sm="12"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import settingsMixin from "../../settings/mixins/settings";
import {
  LocalizedContentCategory,
  Language,
  ContentCategory,
} from "../../settings/models";

export default {
  name: "PostRelationships",
  props: {
    post: {
      type: Object,
    },
    contentType: {
      type: Object,
      required: true,
    },
    parentContentCategory: {
      type: Array,
    },
  },
  mixins: [settingsMixin],
  data() {
    return {
      contentCategorySelected: [],
      contentCategoryImg: null,
      imgUrl: null,
      categorySelected: null,
      editedContentCategory: new LocalizedContentCategory(),
      defaultContentCategory: new LocalizedContentCategory(),
      formItem: new ContentCategory(),
      formLocalizedCategories: [],
      categoryName: null,
      description: null,
      tab: null,
      addLoading: false,
      contentCategoryDialog: false,
      postCategories: [],
      categories: [],
      showSnackbar: false,
      snackbarText: ``,
      valid: false,
      required: [(v) => !!v || "this field is required"],
      maxSizeRule: [
        (value) =>
          value === null ||
          (value && value.size <= 31457280) ||
          "Image size should be less than 30 MB!",
      ],
      relatedToId: null,
      hasRelation: false,
      childCategory: null,
    };
  },
  mounted() {
    this.setPostCategories();
    this.setRelatedToParnetId();
  },
  methods: {
    // START RENDER CATEGORIES HELPERS
    getCategoriesByParentId(parentId) {
      let parentContent = this.contentCategories.find((a) => a.id == parentId);
      let parentContentCategoryArray = this.contentCategories.filter(
        (x) => x.parentId === parentId && x.isActive
      );
      let relatedToArray = parentContent.hasRelation
        ? parentContentCategoryArray.filter(
            (x) =>
              this.parentContentCategory?.includes(x.relatedToId) && x.isActive
          )
        : [];
      if (
        parentContentCategoryArray[0]?.relatedToId &&
        parentContent.hasRelation &&
        relatedToArray.length == 0
      ) {
        return [];
      } else if (relatedToArray.length > 0) {
        return relatedToArray.map((item) => {
          return {
            ...item,
            text: item.localizations[this.getLocale()]?.categoryName,
          };
        });
      } else {
        return parentContentCategoryArray.map((item) => {
          return {
            ...item,
            text: item.localizations[this.getLocale()]?.categoryName,
          };
        });
      }
    },

    getSelectedCategoriesByParentId(contentTypeCategory) {
      if (contentTypeCategory?.id) {
        const selected = this.postCategories?.filter(
          (item) => item?.parentId === contentTypeCategory?.contentCategoryId
        );
        return contentTypeCategory.maxAllowedNoOfCategories === 1
          ? selected[0]
          : selected;
      }
    },
    setPostCategories() {
      this.postCategories =
        this.post?.postCategories.map((item) => {
          return this.getContentCategoryById(item.contentCategoryId);
        }) ?? [];
    },
    // END RENDER CATEGORIES HELPER

    // START POST CATEGORIES METHODS
    onChangeHandler(value) {
      const categories = value.constructor === Array ? [...value] : [value];
      this.addRemoveSelectedCategory(categories);
    },
    addRemoveSelectedCategory(categories) {
      if (categories.length > 0) {
        let newPostCategories = [];
        this.postCategories.forEach((pc) => {
          if (pc.parentId !== categories[0].parentId)
            return newPostCategories.push(pc);
        });
        categories.forEach((item) => newPostCategories.push(item));
        this.postCategories = newPostCategories;
      }
    },
    async updatePostCategories(post) {
      const categories = this.postCategories?.map((item) => item.id);
      const categoriesSet = new Set(categories);
      let postCategories = [];
      categoriesSet?.forEach((pc) => {
        const category = this.getContentCategoryById(pc);
        const parentCategoryExistsInContentType = this.contentType?.contentTypeCategories?.find(
          (x) => x.contentCategoryId === category.parentId
        );
        if (parentCategoryExistsInContentType) {
          postCategories.push({
            post: { id: post.id },
            contentCategory: { id: pc },
          });
        }
      });
      await this.$store.dispatch("post/updatePostCategories", {
        postId: post.id,
        postCategories: postCategories,
      });
    },
    // END POST CATEGORIES METHODS

    // START ADD ANOTHER CATEGORY
    addOther(item) {
      this.contentCategorySelected = item;
      this.categoryName = null;
      this.formLocalizedCategories = [];
      this.constructLocalizedCategoryWithLanguages(this.languages);
      this.contentCategoryDialog = true;
    },
    async addCategory(item) {
      const parentContentCategory = item
        ? { id: item.contentCategoryId }
        : null;

      this.$store.commit(
        "contentManagement/setNewlyAddedContentCategoryParent",
        parentContentCategory
      );
      return await this.$store.dispatch(
        "contentManagement/addContentCategory",
        {
          code: this.categoryName,
          isActive: true,
          contentCategory: item ? { id: item.contentCategory.id } : null,
          relatedTo: item.relatedToParentId
            ? { id: item.relatedToParentId }
            : null,
          hasRelation: item.contentCategory.hasRelation
            ? item.contentCategory.hasRelation
            : false,
        }
      );
    },
    async loadLocalizedContentCategoriesByParentId(parentId) {
      this.formLocalizedCategories = await this.$store.dispatch(
        "contentManagement/findCategoryById",
        parentId
      );
      const addedLanguages = [
        ...new Map(
          this.formLocalizedCategories.map((item) => [
            item["locale"],
            item.locale,
          ])
        ).values(),
      ];
      const missedLanguages = this.languages.filter(
        (item) => !addedLanguages.find((element) => element === item.locale)
      );
      if (missedLanguages?.length)
        this.constructLocalizedCategoryWithLanguages(missedLanguages);
    },
    constructLocalizedCategoryWithLanguages(languages) {
      for (let i = 0; i < languages?.length; i++) {
        let newLocalizedCategory = new LocalizedContentCategory({
          id: null,
          language: new Language(languages[i]),
          locale: languages[i].locale,
          categoryName: "",
        });
        this.formLocalizedCategories.push(newLocalizedCategory);
      }
    },
    closeContentCategoryDialog() {
      this.contentCategoryDialog = false;
      this.$nextTick(() => {
        this.editedContentCategory = Object.assign(
          {},
          this.defaultContentCategory
        );
        this.tab = null;
        this.valid = false;
        this.formItem = Object.assign({}, new ContentCategory());
        this.hasRelation = false;
        this.relatedToId = null;
        this.editedIndex = -1;
      });
    },
    async addLocalizedCategories(contentCategory) {
      this.formLocalizedCategories = this.formLocalizedCategories.filter(
        (x) => x.categoryName !== ""
      );
      for (let i = 0; i < this.formLocalizedCategories.length; i++) {
        this.formLocalizedCategories[i].contentCategory = contentCategory;
        this.formLocalizedCategories[i].contentCategoryId = contentCategory.id;
        if (
          this.formLocalizedCategories[i].id === 0 ||
          this.formLocalizedCategories[i].id === null
        ) {
          await this.$store.dispatch(
            "contentManagement/addLocalizedContentCategory",
            this.formLocalizedCategories[i]
          );
        } else {
          await this.$store.dispatch(
            "contentManagement/updateLocalizedContentCategory",
            this.formLocalizedCategories[i]
          );
        }
      }
    },
    setRelatedToParnetId() {
      for (let item in this.contentType.contentTypeCategories) {
        let contentCategoryIdParent = this.contentType.contentTypeCategories[
          item
        ].contentCategory.relatedToId
          ? this.contentType.contentTypeCategories[item].contentCategory
              .relatedToId
          : null;
        let contentCategoryParent = contentCategoryIdParent
          ? this.contentCategories.filter(
              (a) => a.parentId == contentCategoryIdParent
            )
          : null;
        this.childCategory = contentCategoryParent
          ? contentCategoryParent.find((element) =>
              this.parentContentCategory.includes(element.id)
            )
          : null;
        this.contentType.contentTypeCategories[
          item
        ].relatedToParentId = this.childCategory?.id;
      }
    },
    async submitData() {
      try {
        let addedCategory = await this.addCategory(
          this.contentCategorySelected
        );
        this.contentCategorySelected = addedCategory;
        this.onChangeHandler(addedCategory);
        await this.addLocalizedCategories(addedCategory);
        this.getSelectedCategoriesByParentId(addedCategory);
        this.contentCategoryImg ? this.updateImgUrl(addedCategory) : null;

        this.postCategories = addedCategory.map((item) => {
          return this.getContentCategoryById(item.contentCategoryId);
        });
        this.snackbarText = "Data saved successfully";
      } catch (error) {
        this.snackbarText = error;
      } finally {
        this.addLoading = false;
        this.closeContentCategoryDialog();
        this.showSnackbar = true;
      }
    },
    async updateImgUrl(contentCategory) {
      const formData = new FormData();
      formData.append("contentCategoryImg", this.contentCategoryImg);
      let payloadUpdateImage = {
        contentCategoryId: contentCategory.id,
        formData: formData,
      };
      await this.$store.dispatch(
        "contentManagement/updateContentCategoryImage",
        payloadUpdateImage
      );
    },
    // END ADD OTHER CATEGORY
  },
  watch: {
    post() {
      this.setPostCategories();
    },
  },
};
</script>

<style lang="scss" scoped>
.post-category-code {
  text-transform: capitalize;
}
.title {
  color: #757575;
  font-weight: 600;
  font-size: 0.7275rem !important;
  line-height: 1.45;
  text-transform: uppercase;
}
.add-other {
  padding-left: 1rem;
  cursor: pointer;
  font-weight: bold;
}
.custom-button {
  border-radius: 3.125rem;
}

.select-category {
  text-transform: capitalize !important;
}
</style>
