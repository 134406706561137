import TicketListing from "./views/tickets.vue";
import TicketDetails from "./views/ticketDetails.vue";

export const ticketsRouters = [
  {
    path: "/support",
    name: "support",
    component: TicketListing,
  },
  {
    path: "/ticket-details/:id",
    name: "ticketDetails",
    component: TicketDetails,
  },

];