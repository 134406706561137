<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          dark
          class="mb-6 custom-button mt-4"
          :to="userFormRoute"
        >
          <v-icon dark>mdi-plus</v-icon>
          add user
        </v-btn>
        <v-data-table
          hide-default-footer
          :headers="usersHeaders"
          :items="filterUsers"
          class="capitalize large"
          :loading="loading"
        >
          <template v-slot:item.actions="{ item }">
            <div class="d-flex">
              <v-icon
                small
                @click="edit(item)"
                v-if="useFormRouteSetting"
                class="mr-2"
              >
                mdi-pencil
              </v-icon>
              <v-switch
                v-model="item.enabled"
                @change="updateUserStatus(item.id, item.enabled)"
              ></v-switch>
            </div>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="confirmationDialogEdit" max-width="800px">
                <v-card class="dialog-class">
                  <v-select
                    :items="filterRoles"
                    label="select role"
                    outlined
                    dense
                    hide-details
                    v-model="selectRole"
                    multiple
                  />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="red darken-1"
                      class="custom-button"
                      text
                      @click="closeEditedDialog"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1 white--text"
                      class="custom-button"
                      @click="submitData"
                      :disabled="loading"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="contentTypeDialog"
                max-width="800px"
                height="5vh"
              >
                <v-card class="dialog-class">
                  <v-select
                    class="pb-2"
                    :items="menuCategory"
                    label="select menu category"
                    item-text="name"
                    outlined
                    dense
                    v-model="selectMenuCategory"
                    hide-details
                    return-object
                  />
                  <v-select
                    v-if="selectMenuCategory"
                    :items="selectContentTypes"
                    label="select content type"
                    outlined
                    dense
                    hide-details
                    item-text="menuTitle"
                    multiple
                    return-object
                  />

                  <v-card-actions class="actions">
                    <v-spacer />
                    <v-btn
                      color="red darken-1"
                      class="custom-button"
                      text
                      @click="closeEditedDialog"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1 white--text"
                      class="custom-button"
                      @click="submitData"
                      :disabled="loading"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
          class="custom-button"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      snackbarText: ``,
      snackbar: false,
      selectRole: [],
      user: null,
      confirmationDialogEdit: false,
      contentTypeDialog: false,
      selectMenuCategory: null,
      filteredUser: [],
      loading: false,
      usersHeaders: [
        {
          text: "username",
          align: "start",
          sortable: false,
          value: "username",
        },
        {
          text: "email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "firstName",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        {
          text: "lastName",
          align: "start",
          sortable: false,
          value: "lastName",
        },
        {
          text: "Roles",
          align: "start",
          sortable: false,
          value: "realmRoles",
        },
        {
          text: "actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  async mounted() {
    this.loading = true;
    await this.loadusers();
    await this.loadRoles();
    this.loading = false;
  },

  computed: {
    ...mapGetters("auth", ["users", "username", "allRoles", "groups"]),
    ...mapGetters("contentManagement", ["menuCategory"]),
    selectContentTypes() {
      return this.selectMenuCategory.contentTypeList;
    },
    useFormRouteSetting() {
      return this.$route.fullPath == "/setting/users";
    },
    userFormRoute() {
      let route = this.$route.fullPath;
      return route == "/users" ? "/user-form" : "/setting/user-form";
    },
    filterRoles() {
      return this.allRoles.filter(
        (a) => a == "client-admin" || a == "client-user"
      );
    },
    filterUsers() {
      let userRoute = this.$route.fullPath;
      if (userRoute == "/users") {
        let filteredUser = [];
        this.users.forEach(user => {
         if(!user.realmRoles.includes("client-admin"))
          filteredUser.push(user);
        });
        return filteredUser;
      } else return this.users;
    },
  },
  methods: {
    async loadRoles() {
      await this.$store.dispatch("auth/getAllRoles");
    },
    async loadusers() {
      await this.$store.dispatch(
        "auth/getAllUsersByGroupName",
        this.groups ? this.groups : localStorage.getItem("tenantCode")
      );
    },
    edit(item) {
      this.selectRole = item.realmRoles;
      this.confirmationDialogEdit = true;
      this.user = item;
    },
    async updateUserStatus(keycloakId, enabled) {
      try {
        this.loading = true;
        let payLoad = {
          keycloakId: keycloakId,
          enabled: enabled,
        };
        await this.$store.dispatch("auth/updateUserStatus", payLoad);
        await this.loadusers();
        this.snackbar = true;
        this.snackbarText = "Update User Status";
      } catch (error) {
        this.snackbar = true;
        this.snackbarText = error;
      } finally {
        this.snackbar = true;
        this.loading = false;
      }
    },

    closeEditedDialog() {
      this.confirmationDialogEdit = false;
      this.loading = false;
      this.selectRole = [];
    },
    async submitData() {
      this.loading = true;
      let payLoad = {
        id: this.user.id,
        realmRoles: [],
      };
      payLoad.realmRoles = this.selectRole;
      await this.$store.dispatch("auth/addRolesToUser", payLoad);
      await this.loadusers();
      this.closeEditedDialog();
    },
  },
};
</script>
<style lang="scss" scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: large;
  color: black;
}
.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.v-application text {
  color: #1867c0 !important;
}
.actions {
  margin-top: 20rem;
}
.dialog-class {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding: 1rem 1rem 1rem;
}
.v-list {
  max-height: 8rem;
  overflow-y: auto;
}
</style>
