export default {
  languages: (state) => state.languages,
  defaultLanguage: (state) => state.defaultLanguage,
  contentTypes: (state) => state.contentTypes,
  contentCategories: (state) => state.contentCategories,
  contentCategoryFile:(state) => state.contentCategoryFile,
  contentTypesCategories: (state) => state.contentTypesCategories,
  localizedContentTypesCategories: (state) =>
    state.localizedContentTypesCategories,
  contentTypeById: (state) => state.contentTypeById,
  newlyAddedContentType: (state) => state.newlyAddedContentType,
  newlyAddedContentTypeParent: (state) => state.newlyAddedContentTypeParent,
  newlyAddedContentCategoryParent: (state) =>
    state.newlyAddedContentCategoryParent,
  supportedLanguages: (state) => state.supportedLanguages,
  menuCategory: (state) => state.menuCategory,
  contentTypeWithMenuCategoryNull: (state) =>
    state.contentTypeWithMenuCategoryNull,
  contentTypeToManuCategory: (state) => state.addContentToMenuCategory,
  optionalFeatures: (state) => state.optionalFeatures,
  editedContentTypeOptionalFeatures: (state) =>
    state.editedContentTypeOptionalFeatures,
  contentCategoryAdded: (state) => state.contentCategoryAdded,
  lookupData:(state) => state.lookupData,
  lookupTypes:(state) => state.lookupTypes,
  cacheConfigs:(state) => state.cacheConfigs,
  cachePurgeStatus:(state) => state.cachePurgeStatus
};
