<template>
  <v-container fluid>
    <v-toolbar flat>
      <template>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          dark
          class="mb-2 custom-button"
          v-if="registerForm.length"
          @click="downloadExcelsheet"
        >
          export
        </v-btn>
      </template>
    </v-toolbar>
    <!-- DATATABLE -->
    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          :headers="registerFormHeader"
          :loading="loading"
          :items-per-page="pageSize"
          :items="registerForm"
          class="capitalize"
        >
          <template v-slot:item.action-invoice-data="{ item }">
            <v-btn
              v-if="item.invoiceData"
              color="primary"
              class="custom-button"
              @click="getInvoiceDetails(item)"
            >
              invoice details
            </v-btn>
          </template>

          <template v-slot:item.action-q-and-a="{ item }">
            <v-btn
              color="primary"
              class="custom-button"
              @click="getQuestionAndAnswerDetails(item)"
              v-if="item.questionAndAnswerList && item.questionAndAnswerList.length"

            >
              Question And Answer
            </v-btn>
          </template>

          <template v-slot:item.action-participating-people="{ item }">
            <v-btn
              v-if="item.participatingPeople && item.participatingPeople.length"
              color="primary"
              class="custom-button"
              @click="getParticipatingPeople(item)"
            >
              participating people
            </v-btn>
          </template>

          <template v-slot:top>
            <v-dialog
              v-model="invoiceData"
              max-width="1000px"
              v-if="invoiceData"
            >
              <v-card class="mx-auto">
                <v-data-table
                  :headers="headersInvoiceData"
                  :items="invoiceDataText"
                  hide-actions
                  class="elevation-1"
                  hide-default-footer
                ></v-data-table>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="questionAndAnswer"
              max-width="1000px"
              v-if="questionAndAnswer"
            >
              <v-card class="mx-auto">
                <v-data-table
                  :headers="headerQAndA"
                  :items="questionAndAnswerArray"
                  hide-actions
                  class="elevation-1"
                  hide-default-footer
                ></v-data-table>
              </v-card>
            </v-dialog>

            <v-dialog
              v-model="participatingPeople"
              max-width="1000px"
              v-if="participatingPeople"
            >
              <v-card class="mx-auto">
                <v-data-table
                  :headers="headersParticipatingPeople"
                  :items="participatingPeopleArray"
                  hide-actions
                  class="elevation-1"
                  hide-default-footer
                ></v-data-table>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Pagination -->
    <v-row
      class="justify-end mt-6 align-center"
      v-if="registerFormPaginated.totalNumberOfPages > 1"
    >
      <div class="mr-2">
        <v-select
          class="page-size-selector"
          label="page size"
          outlined
          dense
          hide-details
          :items="pageSizeOptions"
          :value="10"
          @change="pageSize = parseInt($event, 10)"
        />
      </div>
      <div class="mr-2">
        <span>{{ pageNumber }}</span>
        <span class="mx-1">From</span>
        <span>{{ registerFormPaginated.totalNumberOfPages }}</span>
      </div>
      <v-pagination
        class="align-self-start py-2"
        v-model="pageNumber"
        :length="registerFormPaginated.totalNumberOfPages"
      />
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      invoiceData: false,
      questionAndAnswer: false,
      participatingPeople: false,
      participatingPeopleArray: [],
      questionAndAnswerArray: [],
      snackbar: false,
      loading: false,
      pageSize: 10,
      pageNumber: 1,
      pageSizeOptions: [5, 10, 15, 20],
      headerQAndA: [
        {
          text: "question",
          align: "start",
          sortable: false,
          value: "question",
        },
        {
          text: "answer",
          align: "start",
          sortable: false,
          value: "answer",
        },
        {
          text: "reason",
          align: "start",
          sortable: false,
          value: "reason",
        },
      ],
      headersInvoiceData: [
        {
          text: "contact person",
          align: "start",
          sortable: false,
          value: "contactPerson",
        },
        {
          text: "mobile number",
          align: "start",
          sortable: false,
          value: "mobileNumber",
        },
        {
          text: "country",
          align: "start",
          sortable: false,
          value: "country",
        },
        {
          text: "zipCode",
          align: "start",
          sortable: false,
          value: "zipCode",
        },
        {
          text: "location",
          align: "start",
          sortable: false,
          value: "location",
        },
        {
          text: "street",
          align: "start",
          sortable: false,
          value: "street",
        },
        {
          text: "number",
          align: "start",
          sortable: false,
          value: "number",
        },
        {
          text: "company name",
          align: "start",
          sortable: false,
          value: "companyName",
        },
        {
          text: "address extension",
          align: "start",
          sortable: false,
          value: "addressExtension",
        },
      ],
      headersParticipatingPeople: [
        {
          text: "first name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        {
          text: "lastName",
          align: "start",
          sortable: false,
          value: "last name",
        },
        {
          text: "email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "job title",
          align: "start",
          sortable: false,
          value: "jobTitle",
        },
      ],
    
      invoiceDataText: [],
    };
  },
  async mounted() {
    this.loading = true;
    await this.loadRegisterFormColumns();
    await this.loadRegisterForm();
    this.loading = false;
  },

  watch: {
    async pageNumber() {
      await this.loadRegisterForm();
    },
    async pageSize() {
      if (this.pageSize < 1 || this.pageSize > 20) {
        this.pageSize = 10;
      }
      await this.loadRegisterForm();
    },
  },

  computed: {
    ...mapGetters("registerForm", ["registerFormPaginated" , "registerFormColumnsNames"]),
    registerForm: {
      get() {
        return this.registerFormPaginated.result;
      },
    },
    registerFormHeader() {
      let headers = this.registerFormColumnsNames
        .filter((registerFormColumn) => registerFormColumn.isActive)
        ?.map((a) => {
          return {
            value: a.name,
            text: a.displayName,
          };
        });
    
      headers.push({
        text: "Actions",
        value: "action-q-and-a",
      },
      {
        text: "",
        value: "action-invoice-data",
      },
      {
        text: "",
        value: "action-participating-people",
      }
    );
      return headers;
    },
  },
  methods: {
    async loadRegisterFormColumns() {
      await this.$store.dispatch("registerForm/loadRegistrFormColumnsNames");
    },
    getInvoiceDetails(item) {
      this.invoiceData = true;
      this.invoiceDataText = [item.invoiceData];
    },
    getQuestionAndAnswerDetails(item) {
      this.questionAndAnswer = true;
      this.questionAndAnswerArray = item.questionAndAnswerList;
    },
    getParticipatingPeople(item) {
      this.participatingPeople = true;
      this.participatingPeopleArray = item.participatingPeople;
    },
  
    async loadRegisterForm() {
      let payload = {
        sortingByList: [
          {
            fieldName: "id",
            direction: "DESC",
          },
        ],
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      await this.$store.dispatch(
        "registerForm/loadRegesterFormPaginated",
        payload
      );
    },
    async downloadExcelsheet() {
      this.export = true;
      const res = await this.$store.dispatch(
        "registerForm/downloadExcelSheet"
      );
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "registerForm.xlsx");
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss">
.contact-us-file {
  text-decoration: underline;
}
.text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.page-size-selector {
  width: 75px;
}
.v-data-table {
  font-size: large;
  color: black;
}
</style>
