<template>
  <div class="container">
    <v-row>
      <v-col class="d-flex justify-center">
        <h1 class="secondary--text">Add S3</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-form v-model="mailSenderFormValid" ref="tenantForm">
          <h2>Mail Sender Configration</h2>

          <v-text-field
            v-model="mailSenderConfig.host"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              Host
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="mailSenderConfig.port"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              Port
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field v-model="mailSenderConfig.username" :rules="required" type="text">
            <template v-slot:label>
              UserName
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="mailSenderConfig.password"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              Password
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="mailSenderConfig.from"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              From
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>

          <v-text-field
            v-model="mailSenderConfig.to"
            :rules="required"
            type="text"
          >
            <template v-slot:label>
              To
              <span class="mandatory-field">*</span>
            </template>
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!isSaving" class="d-flex flex-row-reverse" cols="8">
        <v-btn
          color="primary"
          @click="addMailSenderConfig"
          :disabled="!mailSenderFormValid || mailSender.length>0"
        >
          Save
        </v-btn>
      </v-col>
      <v-col v-else class="d-flex flex-row-reverse" cols="8">
        <v-progress-circular
          indeterminate
          color="deep-orange lighten-2"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-snackbar v-model="showSnackbar" absolute>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay :value="isSaving"></v-overlay>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import mailSender from "../../models/MailSender";
export default {
  title: "Add Tenant",
  data() {
    return {
      tenantCodeSelected: null,
      mailSenderConfig: new mailSender(),
      valid: false,
      isSaving: false,
      mailSenderFormValid: true,
      required: [(v) => !!v || "This field is required"],
      showSnackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    ...mapGetters("tenant", ["tenants", "tenantCode", "mailSender"]),
    ...mapGetters("auth", ["groups"]),
  },
  methods: {
    async addMailSenderConfig() {
      try {
        if (!this.mailSender.length) {
          this.$store.commit("tenant/setTenantCodeSelected", this.groups);
          await this.$store.dispatch("tenant/addMailSenderConfig", this.mailSenderConfig);
          this.showSnackbar = true;
          this.snackbarText = "Data Added Successfully";
        } else {
        //   await this.$store.dispatch("tenant/editWebsiteS3", this.mailSender);

          this.showSnackbar = true;
          this.snackbarText = "Data Updated Successfully";
        }
      } catch (error) {
        this.showSnackbar = true;
        this.snackbarText = error;
      }
    },
  },
  watch: {
    mailSender() {
      this.mailSenderConfig = { ...this.mailSender };
    },
  },
};
</script>
<style scoped>
.container {
  padding-left: 1rem;
}
</style>
