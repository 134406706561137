
export default class MailSender {
  constructor(rawData = {}) {
    this.id = rawData.id;
    this.host = rawData.host;
    this.port = rawData.port;
    this.username = rawData.username;
    this.password = rawData.password;
    this.from = rawData.from;
    this.to = rawData.to;

  }
}
