var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Fragment',[_c('nav',[_c('div',[_c('router-link',{staticClass:"text-decoration",attrs:{"to":/content-type/ + _vm.contentType.id}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.contentType.menuTitle)+" ")])]),_c('v-divider')],1),_c('v-list',{staticClass:"list"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item',_vm._g({key:_vm.contentType.id,attrs:{"to":{
              name: 'SubPostEntry',
              params: {
                postId: _vm.rootPostId,
                childTypeId: _vm.contentType.id,
                childPostId: _vm.rootPostId,
              },
            },"link":""}},on),[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.contentType.type)}})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.contentType.type))])]),_c('div',{class:{ disabled: _vm.rootPostId === null }},_vm._l((_vm.contentType.children),function(item,i){return _c('Fragment',{key:i},[(item.isActive === true && item.children.length === 0)?_c('v-list-item',{key:item.id,attrs:{"to":{
              name: 'ChildContentTypePosts',
              params: { postId: _vm.rootPostId, childTypeId: item.id },
            },"link":""}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.type)}})],1):_vm._e(),(item.isActive === true && item.children.length > 0)?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{key:item.id,staticClass:"v-list-group-activator",attrs:{"to":{
                  name: 'ChildContentTypePosts',
                  params: { postId: _vm.rootPostId, childTypeId: item.id },
                },"link":""}},[_c('v-list-item-title',[_vm._v(_vm._s(item.type))])],1)]},proxy:true}],null,true)},[_c('ContentMenuGroup',{attrs:{"contentTypes":item.children,"postId":_vm.rootPostId}})],1):_vm._e()],1)}),1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }