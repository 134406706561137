<template>
	<v-container fluid>
		<v-row>
			<v-spacer></v-spacer>
			<v-dialog v-model="menuCategoryDialog" max-width="850px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						color="primary"
						dark
						class="mb-6 custom-button mt-4"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon dark>mdi-plus</v-icon>
						New Menu Category
					</v-btn>
				</template>
				<v-card>
					<v-card-title>
						<span class="text-h5">Add Menu Category</span>
					</v-card-title>
					<v-card-text>
						<v-form v-model="valid" ref="contentTypeForm">
							<v-container>
								<v-row>
									<v-col cols="6" sm="6">
										<v-text-field
											v-model="editedMenuCategory.name"
											label="name"
											:rules="required"
										/>
									</v-col>
								</v-row>
							</v-container>
						</v-form>
					</v-card-text>

					<v-card-actions>
						<v-spacer />
						<v-btn
							color="red darken-1"
							class="custom-button"
							text
							@click="closeAddMenuCategoryDialog"
						>
							Cancel
						</v-btn>

						<v-btn
							color="blue darken-1 white--text"
							class="custom-button"
							@click="addMenuCategory"
							:disabled="!valid || addMenuCategoryLoading"
						>
							Save
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:headers="menuCategoryHeaders"
					:items="menuCategory"
					sort-by="name"
				>
					<!--eslint-disable -->

					<template v-slot:top>
						<v-dialog v-model="contentTypeDialog" max-width="850px">
							<template>
								<v-card>
									<v-card-title>
										<span class="text-h5">Add Content Type</span>
									</v-card-title>
									<v-card-text>
										<v-form v-model="valid" ref="form">
											<v-container>
												<v-row>
													<v-col cols="6" sm="6">
														<v-select
															:items="getContentTypesName"
															label="Content Type"
															:rules="required"
															persistent-hint
															return-object
															single-line
															dense
															outlined
															multiple
															v-model="selectedContentTypes"
														/>
													</v-col>
												</v-row>
												<v-row align="center">
													<v-col class="d-flex" cols="12" sm="6">
														<v-select
															multiple
															:items="menuCategoryListExpectedSelectedItem"
															item-text="name"
															return-object
															label="Add Children to Menu Category"
															outlined
															v-model="menuCategorychildren"
														></v-select>
													</v-col>
												</v-row>
											</v-container>
										</v-form>
									</v-card-text>

									<v-card-actions>
										<v-spacer />
										<v-btn
											color="red darken-1"
											class="custom-button"
											text
											@click="closeAddMenuCategoryDialog"
										>
											Cancel
										</v-btn>
										<v-btn
											color="blue darken-1"
											class="custom-button"
											text
											@click="submit"
											:loading="loading"
											:disabled="!valid || loading"
										>
											Save
										</v-btn>
									</v-card-actions>
								</v-card>
							</template>
						</v-dialog>
						<template>
							<v-row justify="center">
								<v-dialog
									persistent
									max-width="600px"
									v-model="editMenuCategoryNameDialog"
								>
									<v-card>
										<v-card-title>
											<span class="text-h5">Change Menu Category Name</span>
										</v-card-title>
										<v-card-text>
											<v-container>
												<v-row>
													<v-col cols="12" sm="6" md="4">
														<v-text-field
															v-model="editedMenuCategory.name"
															label="name"
															required
														></v-text-field>
													</v-col>
												</v-row>
											</v-container>
										</v-card-text>
										<v-card-actions>
											<v-spacer></v-spacer>
											<v-btn
												color="red darken-1"
												text
												@click="closeMenuCategoryDialog"
												class="custom-button"
											>
												Close
											</v-btn>
											<v-btn
												color="blue darken-1"
												class="custom-button"
												text
												@click="saveNewMenuCategoryName"
											>
												Save
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-dialog>
							</v-row>
						</template>
					</template>

					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon small @click="addChild(item)" v-bind="attrs" v-on="on">
									mdi-plus-network
								</v-icon>
							</template>

							<span>Add ContentType </span>
						</v-tooltip>

						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon
									small
									@click="edit(item)"
									v-bind="attrs"
									v-on="on"
									class="ml-2"
								>
									mdi-pencil
								</v-icon>
							</template>

							<span>Edit ContentType </span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapGetters } from "vuex";
import MenuCategory from "../../models/menu-category";

export default {
	data() {
		return {
			editedMenuCategory: new MenuCategory(),
			menuCategoryListExpectedSelectedItem: [],
			editMenuCategoryNameDialog: false,
			menuCategorychildren: [],
			menuCategoryDialog: false,
			selectedContentTypes: [],
			selectContentTypesObjects: [],
			parentId: null,
			loading: false,
			valid: false,
			includeFiles: true,
			contentTypeDialog: false,
			defaultMenuCategory: new MenuCategory(),
			required: [(v) => !!v || "this field is required"],
			addMenuCategoryLoading: false,
			menuCategoryHeaders: [
				{
					text: "Name",
					align: "start",
					sortable: false,
					value: "name"
				},
				{
					text: "Creation Date",
					align: "start",
					sortable: false,
					value: "creationDate"
				},

				{ text: "Actions", align: "center", value: "actions", sortable: false }
			]
		};
	},
	computed: {
		...mapGetters("contentManagement", [
			"contentTypes",
			"menuCategory",
			"contentTypeToManuCategory"
		]),
		getContentTypesName() {
			return this.contentTypes
				.filter((item) => item.parentId == null)
				.map((item) => item.type);
		}
	},
	watch: {
		menuCategory: {
			deep: true,
			handler(newVal) {
				this.$store.commit("contentManagement/setMenuCategory", newVal);
			}
		}
	},

	methods: {
		closeMenuCategoryDialog() {
			this.editMenuCategoryNameDialog = false;
			this.editedMenuCategory = new MenuCategory();
		},
		async saveNewMenuCategoryName() {
			this.editMenuCategoryNameDialog = false;
			await this.$store.dispatch(
				"contentManagement/updateMenuCategory",
				this.editedMenuCategory
			);
		},
		async submit() {
			await this.addContentType();
			await this.addMenuParentIdToMenuCategory();
			this.closeAddMenuCategoryDialog();
		},
		async addMenuParentIdToMenuCategory() {
			let payload = {
				parent: this.editedMenuCategory,
				children: this.menuCategorychildren? this.menuCategorychildren :[]
			};
			await this.$store.dispatch(
				"contentManagement/updateMenuCategoryChildren",
				payload
			);
			await this.loadMenuCategory();
		},
		async loadMenuCategory() {
			await this.$store.dispatch("contentManagement/loadMenuCategory");
		},

		async loadContentTypesWithMenuCategoryNull() {
			await this.$store.dispatch(
				"contentManagement/loadContentTypesWithMenuCategoryNull"
			);
		},
		close() {
			this.menuCategoryDialog = false;
			this.$refs.form.resetValidation();
		},
		closeAddMenuCategoryDialog() {
			this.contentTypeDialog = false;
			this.menuCategoryDialog = false;
			this.selectedContentTypes = [];
			this.selectedMenuCategories = [];
			this.payload = {};
			this.selectContentTypesObjects = [];
			this.$nextTick(() => {
				this.editedMenuCategory = Object.assign({}, this.defaultMenuCategory);
				this.editedIndex = -1;
			});
		},
		async addMenuCategory() {
			let payload = {
				name: this.editedMenuCategory.name
			};
			await this.$store.dispatch("contentManagement/addMenuCategory", payload);
			this.menuCategoryDialog = false;
		},
		addChild(item) {
			this.menuCategoryListExpectedSelectedItem = this.menuCategory.filter(
				(element) => element.id != item.id
			);
			this.editedMenuCategory = item;
			this.menuCategorychildren = this.editedMenuCategory.children;
			if (this.contentTypeToManuCategory.contentTypeList) {
				this.selectedContentTypes = this.contentTypeToManuCategory.contentTypeList?.map(
					(item) => item.type
				);
			} else {
				this.selectedContentTypes = item.contentTypeList?.map(
					(item) => item.type
				);
			}

			this.parentId = item.id;
			this.contentTypeDialog = true;
		},
		edit(item) {
			this.editMenuCategoryNameDialog = true;
			this.editedMenuCategory = item;
			// this.editedImageType= item
		},
		async addContentType() {
			for (let i in this.selectedContentTypes) {
				let contentTypeObject = this.contentTypes.find(
					(item) => item.type == this.selectedContentTypes[i]
				);
				this.selectContentTypesObjects.push(contentTypeObject);
			}
			let payload = {
				id: this.parentId,
				contentTypeArray: this.selectContentTypesObjects
			};

			await this.$store.dispatch(
				"contentManagement/addContentTypeToMenuCategory",
				payload
			);
			await this.loadContentTypesWithMenuCategoryNull();
			await this.loadMenuCategory();
		}
	}
};
</script>
<style>
.custom-button {
	border-radius: 3.125rem;
}
</style>
